import React, { FC, useEffect, useState } from "react";
import Modal from "../../components/ui/modal";
import Borrowers from "./Borrowers";
import GaadiOperators from "./GaadiOperators";
import AddTrader from "./AddTrader";
import AllCustomers from "./AllCustomers";
import Subscribers from "./Subscribers";
import useAllTraders from "../../hooks/useAllTraders";
import { useDispatch, useSelector } from "react-redux";
import { UpdateActiveTab } from "../../reducers/borrowerSlice";
import { TRootState } from "../../store";
import useCustomerDocs from "../../hooks/useCustomerDocs";
import Traders from "./Traders";
const Customer: FC<{}> = ({}) => {
  const [visible, setVisible] = useState(false);

  const { traders, getAllTraders, onRefresh, setOnRefresh, role } =
    useAllTraders();

  const dispatch = useDispatch();

  // const activeTab = useSelector(
  //   (state: TRootState) => state.borrowers.activeTab
  // );
  const { activeTab, loginType } = useSelector((state: TRootState) => ({
    activeTab: state.borrowers.activeTab,
    loginType: state.auth.userInfo?.type,
  }));
  useEffect(() => {
    getAllTraders();
    getStatusDetails();
  }, [activeTab, onRefresh]);

  const { getStatusDetails } = useCustomerDocs({});

  return (
    <div className="bg-gray">
      <div className="flex items-center px-2 border-b">
        <div className="flex space-x-4 text-base overflow-x-auto">
          <button
            className={`cursor-pointer px-4 py-2 rounded-t-lg bg-white-200  ${
              activeTab === "All" ? "border-green-600 border-b-4" : ""
            } text-base`}
            onClick={() => dispatch(UpdateActiveTab("All"))}
          >
            All
          </button>
          <button
            className={`cursor-pointer px-4 py-2 rounded-t-lg bg-white-200  ${
              activeTab === "Subscribers" ? "border-green-600 border-b-4" : ""
            } text-base`}
            onClick={() => dispatch(UpdateActiveTab("Subscribers"))}
          >
            Subscribers
          </button>
          <button
            className={`cursor-pointer px-4 py-2 rounded-t-lg bg-white-200  ${
              activeTab === "Traders" ? "border-green-600 border-b-4" : ""
            } text-base`}
            onClick={() => dispatch(UpdateActiveTab("Traders"))}
          >
            Traders
          </button>
          {loginType != "BAYER" && (
            <button
              className={`cursor-pointer px-4 py-2 rounded-t-lg bg-white-200  ${
                activeTab === "Borrowers" ? "border-green-600 border-b-4" : ""
              } text-base`}
              onClick={() => dispatch(UpdateActiveTab("Borrowers"))}
            >
              Borrowers
            </button>
          )}

          <button
            className={`cursor-pointer px-4 py-2 rounded-t-lg bg-white-200  ${
              activeTab === "GAADI Operators"
                ? "border-green-600 border-b-4"
                : ""
            } text-base whitespace-nowrap`}
            onClick={() => dispatch(UpdateActiveTab("GAADI Operators"))}
          >
            GAADI Operators
          </button>
        </div>
      </div>

      <div className="items-center">
        {activeTab === "All" && (
          <>
            <AllCustomers />
          </>
        )}
      </div>

      <div className="items-center">
        {activeTab === "Subscribers" && (
          <>
            <Subscribers />
          </>
        )}
      </div>

      <div>{activeTab === "Traders" && <Traders />}</div>

      <div className="items-center">
        {activeTab === "Borrowers" && (
          <>
            <Borrowers />
          </>
        )}
      </div>

      <div className="items-center">
        {activeTab === "GAADI Operators" && (
          <>
            <GaadiOperators />
          </>
        )}
      </div>
    </div>
  );
};
export default Customer;
