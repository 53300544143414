import { ChangeEvent, FC, useEffect, useState } from "react";
import { IoMdCloseCircleOutline, IoMdSearch } from "react-icons/io";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import PartnerInfo from "../../components/partner-info";
import SelectCrop from "../../components/select-crop";
import { Button, FileUploader, Input, RadioButton } from "../../components/ui";
import SelectDate from "../../components/ui/select-date";
import PartyDropDown from "../../components/ui/select-party";
import Select2, { OnoSelect } from "../../components/ui/select2";
import { updateCropData } from "../../reducers/tradeSlice";
import "./style.module.scss";
import { FaXmark } from "react-icons/fa6";
import DocumentPreview from "../../components/commons/document-preview";
import StyledRadioButton from "../../components/ui/styledRadioButton";
import useSupplyDetailsEdit from "../../hooks/useSupplyPostEdit";
import useSupplyDetailsById from "./useSupplyDetails";
import { getCurrencyNumberWithSymbol } from "../../helpers/get-currency-number";
import moment from "moment";
import $ from "jquery";
const calculate = ({
  qty,
  rate,
  qtyUnit,
}: {
  qty: number;
  rate: number | string;
  qtyUnit: string | number;
}) => {
  let total = 0;
  if (qtyUnit == "MT/TON") {
    total = Number(qty) * 1000 * Number(rate);
  } else if (qtyUnit == "QUINTAL") {
    total = Number(qty) * 100 * Number(rate);
  } else {
    total = Number(qty) * Number(rate);
  }
  return parseFloat(total.toFixed(2));
};
let calculateTimer: any = null;
const EditSupplyPost: FC<{
  onModalClose: any;
  type?: string;
  fromSupplyTab?: boolean;
  postID?: any;
}> = ({ onModalClose, type, fromSupplyTab, postID }) => {
  const dispatch = useDispatch();
  const params = useParams();
  // const { getSupplyDetail } = useSupplyDetailsById({
  //   id: fromSupplyTab ? postID || "0" : parseInt(params.postId || "0"),
  // });
  // useEffect(() => {
  //   getSupplyDetail();
  // }, [fromSupplyTab]);
  const {
    supplyDetails,
    handleDateSelection,
    setSupplyDetails,
    submit,
    // onRemoveReceiptImage,
    onUploadReceiptImage,
    kycDocs,
    selectedDate,
    cropImages,
    setCropImages,
    onRemoveReceiptImage,
    setLatitude,
    setLongitude,
  } = useSupplyDetailsEdit({
    id: fromSupplyTab ? postID || "0" : parseInt(params.postId || "0"),
    modalClose: onModalClose,
    fromSupplyTab: fromSupplyTab,
  });
  const [totalVal, setTotal] = useState(supplyDetails?.expTotal);
  const initCalculate = () => {
    if (calculateTimer) {
      clearTimeout(calculateTimer);
    }
    calculateTimer = setTimeout(() => {
      let total = calculate({
        qty: typeof supplyDetails?.qty === "number" ? supplyDetails?.qty : 0,
        rate: supplyDetails?.expRate ? supplyDetails?.expRate : 0,
        qtyUnit: supplyDetails?.unitType || "",
      });
      setTotal(total);
      setSupplyDetails({
        ...supplyDetails!,
        expTotal: total,
      });
    }, 300);
  };
  useEffect(() => {
    initCalculate();
  }, [supplyDetails?.qty, supplyDetails?.expRate, supplyDetails?.unitType]);
  const [locationName, setLocationName] = useState(supplyDetails?.location);

  const getPosition = () => {
    //    setStreetVillage("");
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition, posError);
    } else {
      alert("Sorry, Geolocation is not supported by this browser.");
      // Alert is browser does not support geolocation
    }
  };
  const posError = () => {
    if (navigator.permissions) {
      navigator.permissions.query({ name: "geolocation" }).then((res) => {
        if (res.state === "denied") {
          alert(
            "Enable location permissions for this website in your browser settings."
          );
        }
      });
    } else {
      alert(
        "Unable to access your location. You can continue by submitting location manually."
      ); // Obtaining Lat/long from address necessary
    }
  };
  const showPosition = async (position: any) => {
    let lat = position.coords.latitude; // You have obtained latitude coordinate!
    let long = position.coords.longitude; // You have obtained
    setLatitude(lat);
    setLongitude(long);
    await getAddress(lat, long, "AIzaSyBw-hcIThiKSrWzF5Y9EzUSkfyD8T1DT4A");
  };
  // Converting lat/long from browser geolocation into city, state, and zip code using Google Geocoding API
  const getAddress = (lat: any, long: any, googleKey: any) => {
    var hi = fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=${googleKey}`
    )
      .then((res) => res.json())
      .then((address) => setZip(address));
  };
  // Dispatching city, state, and zip code to store state
  const setZip = (address: any) => {
    var pincodeValue;
    // let pincode = address.results[0].formatted_address;
    for (var i = 0; i < address.results[0].address_components.length; i++) {
      if (address.results[0].address_components[i].types[0] == "postal_code") {
        pincodeValue = address.results[0].address_components[i].long_name;
      }
    }
    pincodeValue = pincodeValue.replace(/\D/g, "");
    let city = address.results[5].address_components[2].short_name;
    let state = address.results[5].address_components[3].short_name;
    $("#city").val(city);
    $("#state").val(state);
    $("#zip").val(pincodeValue);
    // setPincode(pincodeValue);
    setLocationName(city);
    // setStateVal(state);
  };
  const handleAddressChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const address = e.target.value;
    setLocationName(e.target.value);
    if (address.length > 3) {
      // Wait until the user has entered at least 3 characters
      await getCoordinatesFromAddress(
        address,
        "AIzaSyBw-hcIThiKSrWzF5Y9EzUSkfyD8T1DT4A"
      );
      setSupplyDetails({
        ...supplyDetails!,
        location: e.target.value != "" ? e.target.value : "",
      });
    }
  };

  const getCoordinatesFromAddress = async (address: string, googleKey: any) => {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${googleKey}`
    );
    const data = await response.json();
    if (data.status === "OK" && data.results.length > 0) {
      const lat = data.results[0].geometry.location.lat;
      const lng = data.results[0].geometry.location.lng;
      setLatitude(lat);
      setLongitude(lng);
      setSupplyDetails({
        ...supplyDetails!,
        latitude: lat,
        longitude: lng,
      });
      //   setLocationName(data.results[0].formatted_address); // Set the formatted address in the input
    } else {
      console.error("Geocoding failed:", data.status);
    }
  };
  useEffect(() => {
    setLocationName(supplyDetails?.location);
  }, [supplyDetails?.location]);
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Or use any breakpoint you prefer
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
      <div
        className="pb-3 overflow-y-auto"
        style={{ height: "calc(100vh - 22rem)" }}
      >
        <div className="pb-3 w-full flex">
          <div className="grow-[3] relative">
            <span className="text-sm font-medium">Location</span>
            <div className=" mt-2 relative">
              <Input
                type="text"
                value={locationName}
                onChange={handleAddressChange} // Allow user to type manually
                // placeholder="Search Location"
              />
              <button
                onClick={() => {
                  getPosition();
                }}
                className="absolute color-blue font-medium right-1 top-3"
              >
                Locate me
              </button>
            </div>
          </div>
        </div>
        <div className="pb-0 md:pb-3 w-full block md:flex gap-4">
          <div className="grow-[3]">
            <label className="text-sm font-medium">
              Select Seller <span className="color-red">{"*"}</span>
            </label>
            <div
              className={`relative flex items-center bg-white shrink-0 px-2 py-2 border mt-2 br-10`}
              style={{ borderRightWidth: "1px", height: "70px" }}
            >
              <div className={`flex items-center cursor-pointer w-full`}>
                <PartnerInfo
                  profilePic={supplyDetails?.profilePic || ""}
                  name={supplyDetails?.traderName || ""}
                  shortName={""}
                  partnerType={"Trader"}
                  partnerId={supplyDetails?.traderId}
                  partnerMobile={supplyDetails?.mobile || ""}
                  partyName={supplyDetails?.addressLine}
                />
              </div>
            </div>
          </div>
          <div className="grow-[3] pt-3 md:pt-0">
            <label className="text-sm font-medium">
              Select Crop <span className="color-red">{"*"}</span>
            </label>
            <div className="grow overflow-auto scrollbar mt-2">
              <SelectCrop status="true" />
            </div>
          </div>
        </div>
        <div className="pt-3 w-full block md:flex gap-4">
          <div className="grow-[3]">
            <label className="text-sm font-medium">
              Quality <span className="color-red">{"*"}</span>
            </label>
            <StyledRadioButton
              displayType="horizontal"
              data={[
                { label: "Best", value: "BEST" },
                { label: "Average", value: "AVERAGE" },
                { label: "Low", value: "LOW" },
              ]}
              value={supplyDetails?.quality || ""}
              pointer={{ label: "label", value: "value" }}
              onChange={(value) => {
                setSupplyDetails({
                  ...supplyDetails!,
                  quality: value || "",
                });
              }}
              styles={{ display: "flex", gap: "10px" }}
            />
          </div>
          <div className="grow-[3] pt-3 md:pt-0">
            <label className="text-sm font-medium">
              Unit Type <span className="color-red">{"*"}</span>
            </label>
            <StyledRadioButton
              displayType="horizontal"
              data={[
                { label: "KGS", value: "KGS" },
                { label: "Quintal", value: "QUINTAL" },
                { label: "MT/TON", value: "MT/TON" },
              ]}
              value={supplyDetails?.unitType || ""}
              pointer={{ label: "label", value: "value" }}
              onChange={(value) => {
                setSupplyDetails({
                  ...supplyDetails!,
                  unitType: value || "",
                });
              }}
              styles={{ display: "flex" }}
            />
          </div>
        </div>

        <div className="pt-3 w-full block md:flex gap-4">
          <table>
            <tr>
              <th className="text-sm font-medium" style={{ width: "40%" }}>
                Quantity*
              </th>
              <td>
                <Input
                  label=""
                  type="number"
                  required={true}
                  value={supplyDetails?.qty}
                  onFocus={(e) => {
                    e.target.value === "0" &&
                      setSupplyDetails({
                        ...supplyDetails!,
                        qty: "",
                      });
                  }}
                  onChange={(e) => {
                    setSupplyDetails({
                      ...supplyDetails!,
                      qty:
                        e.target.value != "" ? parseFloat(e.target.value) : "",
                    });
                  }}
                  fromSupplyTab={true}
                  inputStyle={{ textAlign: "right" }}
                />
              </td>
            </tr>
            <tr>
              <th className="text-sm font-medium" style={{ width: "40%" }}>
                Expected Rate* (Per KG)
              </th>
              <td>
                <Input
                  label=""
                  type="number"
                  required={true}
                  value={supplyDetails?.expRate}
                  onFocus={(e) => {
                    e.target.value === "0" &&
                      setSupplyDetails({
                        ...supplyDetails!,
                        expRate: "",
                      });
                  }}
                  onChange={(e) => {
                    setSupplyDetails({
                      ...supplyDetails!,
                      expRate:
                        e.target.value != "" ? parseFloat(e.target.value) : "",
                    });
                  }}
                  fromSupplyTab={true}
                  inputStyle={{ textAlign: "right" }}
                />
              </td>
            </tr>
            <tr>
              <th
                className="text-sm font-medium"
                style={{ borderRight: "0px" }}
              ></th>
              <td
                className="text-sm font-medium text-right"
                style={{ borderLeft: "0px" }}
              >
                Expected Total :{" "}
                <span className="color-primary">
                  {getCurrencyNumberWithSymbol(totalVal) || "0"}
                </span>
              </td>
            </tr>
          </table>
        </div>
        <div className="pt-3">
          {type == "Supply" ? (
            <div className="grow-[3]" style={{ width: "45%" }}>
              <span className="text-sm font-medium">
                Availability Date <span className="color-red">*</span>
              </span>
              <div className="mt-2">
                <SelectDate
                  onChange={handleDateSelection}
                  selectedDate={selectedDate || ""}
                  maxDate={moment().add(2, "weeks").format("YYYY-MM-DD")}
                />
              </div>
            </div>
          ) : (
            <div className="flex gap-4">
              <div className="grow-[3]">
                <span className="text-sm font-medium">
                  Expected Date <span className="color-red">*</span>
                </span>
                <div className="mt-2">
                  <SelectDate
                    onChange={handleDateSelection}
                    selectedDate={selectedDate || ""}
                    maxDate={moment().add(2, "weeks").format("YYYY-MM-DD")}
                  />
                </div>
              </div>
              <div className="grow-[3]">
                <span className="text-sm font-medium">
                  Offer Validity <span className="color-red">*</span>
                </span>
                <Select2
                  value={supplyDetails?.validity}
                  options={[
                    { label: "Today Only", value: "Today only" },
                    {
                      label: "Next 1 Days",
                      value: "Next 1 days",
                    },
                    {
                      label: "Next 2 Days",
                      value: "Next 2 days",
                    },
                    {
                      label: "Next 3 Days",
                      value: "Next 3 days",
                    },
                  ]}
                  onChange={(e) => {
                    setSupplyDetails({
                      ...supplyDetails!,
                      validity: e.value,
                    });
                  }}
                />
                {/* <SelectDate
                  onChange={handleDateSelection}
                  selectedDate={selectedDate || ""}
                /> */}
              </div>
            </div>
          )}
        </div>
        <div className="pt-3">
          <span className="text-sm font-medium">
            Upload Photo
            {/* <span className="color-red">*</span> */}
          </span>
          <div className="flex md:flex-wrap gap-2 overflow-auto pb-4">
            <div className="shrink-0 overflow-hidden p-2">
              <FileUploader
                label=""
                onChange={(e) => {
                  onUploadReceiptImage(
                    "supply",
                    e.target.files ? e.target.files[0] : null
                  );
                  // setOnEdit(true);
                }}
                fromCropUpload={true}
              />
            </div>
            {cropImages &&
              cropImages?.length > 0 &&
              cropImages?.map((image, i) => (
                <div
                  className="shrink-0 overflow-hidden border-2 p-2 rounded relative"
                  style={{
                    width: isMobile ? "30%" : "20%",
                    height: "8rem",
                  }}
                >
                  <a href={image.url} target="_blank">
                    <DocumentPreview url={image.url} docType={image.docType} />
                  </a>

                  <span
                    className="absolute right-1 top-1 p-1 rounded-full cursor-pointer"
                    style={{
                      background: "#ff0000de",
                    }}
                    onClick={() => {
                      onRemoveReceiptImage("supply", i);
                    }}
                  >
                    <FaXmark style={{ color: "white" }} />
                  </span>
                </div>
              ))}
            {supplyDetails?.imgs &&
              supplyDetails?.imgs.length &&
              supplyDetails.imgs
                .filter((img) => img.status == 1)
                .map((image, i) => (
                  <div
                    className="shrink-0 overflow-hidden border-2 p-2 rounded relative"
                    style={{
                      width: isMobile ? "30%" : "20%",
                      height: "8rem",
                    }}
                  >
                    <a href={image.url} target="_blank">
                      <DocumentPreview url={image.url} docType={"image"} />
                    </a>

                    <span
                      className="absolute right-1 top-1 p-1 rounded-full cursor-pointer"
                      style={{
                        background: "#ff0000de",
                      }}
                      onClick={() => {
                        onRemoveReceiptImage("supply", image.id, true);
                      }}
                    >
                      <FaXmark style={{ color: "white" }} />
                    </span>
                  </div>
                ))}
          </div>
          <span className="text-sm color-sub-text">
            Note: Please submit photos of harvested produce only, not crops on
            the field.
          </span>
        </div>
      </div>
      <div>
        <div className="flex items-center bottom-0 right-0 w-full bg-white py-4 justify-between border-top gap-2">
          <Button
            variant="primaryLight"
            className=""
            text="CANCEL"
            onClick={() => {
              onModalClose(false);
            }}
          />
          <Button
            variant="contained"
            className=""
            onClick={() => {
              submit();
            }}
          >
            SAVE
          </Button>
        </div>
      </div>
    </>
  );
};
export default EditSupplyPost;
