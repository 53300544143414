import { useDispatch, useSelector } from "react-redux";
import { FC, useEffect, useState } from "react";
import BillTableHeader from "./BillTableHeader";
import { TApplicationBills } from "../../../../types";
import { PanelSlider } from "../../../../components/ui";
import cancelIcon from "../../../../assets/images/close.svg";
import Verified from "../../../../assets/images/verified.svg";
import moment from "moment";
import useAllBills from "./useBills";
import BillView from "./BillView";
import useBillview from "./useBillview";
import {
  updateBillsInfo,
  updateBillsView,
} from "../../../../reducers/loansSlice";
import { TRootState } from "../../../../store";
import {
  getCurrencyNumberWithOutSymbol,
  totalBagsValue,
} from "../../../../helpers/get-currency-number";
import Quantity from "./quantity";
const tableHeader = [
  { label: "#", colWidth: 0.5 },
  { label: "Bill ID", colWidth: 2 },
  { label: "Create Date", colWidth: 2.2 },
  { label: "Particulars", colWidth: 3 },
  { label: "Rate(₹)", colWidth: 2 },
  { label: "Bill Amount(₹)", colWidth: 2.2 },
  { label: "Verified", colWidth: 1 },
];
const BillsTable: FC<{
  billsData: TApplicationBills[];
}> = ({ billsData }) => {
  const [openBillView, setOpenBillView] = useState(false);
  const { getBills, billArray } = useAllBills({
    bills: billsData,
  });
  const { getBillView } = useBillview();

  useEffect(() => {
    getBills();
    getBillView();
  }, []);

  const dispatch = useDispatch();

  const { billsInfo, billsView } = useSelector((state: TRootState) => ({
    billsInfo: state.loans.billsInfo,
    billsView: state.loans.billsView,
  }));

  return (
    <>
      <BillTableHeader data={tableHeader} />
      <div
        style={{
          height: "calc(100vh - 15rem)",
          overflowY: "auto",
          width: "100%",
        }}
        id="scroll_style"
      >
        <div>
          {billArray.length > 0 ? (
            <>
              {billArray.map((bill, i) => (
                <div
                  className={`flex border border-t mb-2 items-center cursor-pointer ${
                    i == 0 ? "rounded-b-lg" : "rounded-lg"
                  }`}
                  onClick={() => {
                    setOpenBillView(true);
                    dispatch(updateBillsInfo({ billsInfo: billArray[i] }));
                  }}
                >
                  <div className="flex border-r w-full">
                    <div
                      className="grow-[0.5] basis-0 px-2 py-2 flex flex-col border-r justify-center"
                      style={{ width: "5%" }}
                    >
                      <span className="text-sm">{i + 1}</span>
                    </div>

                    <div
                      className="grow-[2] basis-0 px-2 py-2 flex flex-col border-r justify-center"
                      style={{ width: "10%" }}
                    >
                      <span className="text-sm color-blue">{bill?.caBSeq}</span>
                      <span className="text-sm font-medium">
                        {moment(bill.billDate).format("DD-MMM-YYYY") || "-"}
                      </span>
                    </div>

                    <div
                      className="grow-[2.2] basis-0 px-2 py-2 flex flex-col border-r justify-center"
                      style={{ width: "15%" }}
                    >
                      <span className="text-sm font-medium">
                        {bill?.billDate
                          ? moment(bill.actCreatedOn).format(
                              "DD-MMM-YYYY | hh:mm:a"
                            )
                          : "-"}
                      </span>
                    </div>
                    <div className="grow-[5] basis-0 px-2 py-2 flex flex-col border-r justify-center">
                      {bill.lineItems.map((item, i) => (
                        <div className="flex">
                          <div
                            className="grow-[3] basis-0 px-2 py-2 flex flex-col border-r justify-center"
                            style={{ width: "25%" }}
                          >
                            <div className="flex items-center">
                              <img
                                src={item.imageUrl}
                                className="w-6 mr-2"
                                alt="Tomato"
                              />
                              <div className="flex flex-col text-sm">
                                <span className="text-sm font-medium">
                                  {item.cropName}
                                </span>
                                <Quantity
                                  quantity={item.qty}
                                  unit={item.qtyUnit}
                                  wastage={item.wastage}
                                  weight={item.weight}
                                  rateType={item.rateType}
                                  className="text-xs"
                                />
                                {item.bags !== null ? (
                                  <div className="flex_class text-xs">
                                    <span>
                                      <span>
                                        {item.bags.map((i) => {
                                          return (
                                            <span>
                                              <span>
                                                {i.weight
                                                  ? (i.netWeight
                                                      ? i.weight
                                                      : i.weight + i.wastage) +
                                                    " "
                                                  : ""}
                                              </span>
                                              <span>
                                                {i.wastage ? " - " : ""}
                                              </span>
                                              <span>
                                                {i.wastage ? i.wastage : ""}
                                              </span>
                                              <span>, </span>
                                            </span>
                                          );
                                        })}
                                        <span>
                                          {" "}
                                          {item.bags
                                            ? `= ${
                                                totalBagsValue(item.bags) +
                                                " KGS"
                                              } `
                                            : ""}
                                        </span>
                                      </span>
                                    </span>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>

                          <div
                            className="grow-[2] basis-0 px-2 py-2 flex flex-col justify-center"
                            style={{ width: "15%" }}
                          >
                            <span className="font-medium text-sm">
                              {getCurrencyNumberWithOutSymbol(item.rate) || "-"}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>

                    <div
                      className="grow-[2.2] basis-0 px-2 py-2 flex flex-col border-r justify-center"
                      style={{ width: "20%" }}
                    >
                      <span className="font-medium text-sm">
                        {getCurrencyNumberWithOutSymbol(bill.billAmt) || "-"}
                      </span>
                    </div>

                    <div
                      className="grow-[1] basis-0 px-2 py-2 flex flex-col justify-center"
                      style={{ width: "5%" }}
                    >
                      <img src={Verified} className="w-4" alt="Verified" />
                    </div>
                  </div>
                </div>
              ))}
            </>
          ) : (
            "no data"
          )}

          {openBillView && (
            <div>
              <PanelSlider
                direction="right_to_left"
                open={openBillView}
                onClose={() => {
                  setOpenBillView(false);
                }}
                style={{ width: "50vw" }}
              >
                <div className="py-4 px-10 border-b flex items-center justify-between">
                  <div>
                    <h2 className="text-base font-bold">
                      {billsInfo?.billId} | {billsInfo?.farmerName}
                    </h2>
                  </div>
                  <div>
                    <img
                      src={cancelIcon}
                      className="cursor-pointer"
                      onClick={() => {
                        setOpenBillView(false);
                      }}
                    />
                  </div>
                </div>
                {openBillView && (
                  <>
                    <BillView />
                  </>
                )}
              </PanelSlider>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default BillsTable;
