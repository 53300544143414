import React, { FC, useEffect, useState } from "react";
import PartnerInfo from "../../components/partner-info";
import call from "../../assets/images/call.svg";
import { Button } from "../../components/ui";
import w1 from "../../assets/images/w1.svg";
import w2 from "../../assets/images/w2.svg";
import w3 from "../../assets/images/w3.svg";
import w4 from "../../assets/images/w4.svg";
import w5 from "../../assets/images/w5.svg";
const PostDetails: FC<{}> = ({}) => {
  return (
    <div className="py-4 px-4" style={{ backgroundColor: "#EFF3EF" }}>
      <div className="border br-10 bg-white mb-4">
        <div className="py-4 px-4 border-b">
          <PartnerInfo
            profilePic={""}
            name={"ramana"}
            shortName={""}
            partnerType={"Trader"}
            partnerId={"474"}
            partnerMobile={""}
            partyName={""}
            distanceFrom={true}
            distance={"120 km"}
          />
        </div>
        <div className="">
          <div className="flex items-center gap-x-3 py-4 border-b px-4">
            <div>
              <div
                className="relative cursor-pointer"
                onClick={(e) => {
                  //   setImageGalleryOpen(true);
                  //   dispatch(
                  //     updateProposalCropImgs({
                  //       proposalCropImages: supply.imgs,
                  //     })
                  //   );
                  //   setCropname(supply?.cropName);
                  //   e.stopPropagation();
                }}
              >
                <img
                  src={
                    "https://agfin-crop-images.s3.amazonaws.com/png/Aster.png"
                  }
                  alt=""
                  className="br-10 "
                  style={{ width: "120px", height: "70px" }}
                />
              </div>
            </div>
            <div className="flex flex-col">
              <span className="text-sm font-bold">Tomato Natti</span>
              <span className="text-xs font-medium">Best Quality</span>

              <span className="text-xs">
                Post ID : <span className="color-blue">245</span> | 3 Hours ago
              </span>
            </div>
          </div>
          <div className="flex gap-2 border-b py-4 px-4">
            <div className="flex-1 flex flex-col">
              <span className="text-sm">Quantity</span>
              <span className="text-sm font-semibold">2 MT/TON</span>
            </div>
            <div className="flex-1 flex flex-col">
              <span className="text-sm">Expected Rate</span>
              <span className="text-sm font-semibold">Rs.80 Per BOX</span>
            </div>
          </div>
          <div className="flex gap-2 border-b py-4 px-4">
            <div className="flex-1 flex flex-col">
              <span className="text-sm">Availability Date</span>
              <span className="text-sm font-semibold">13-Feb-2022</span>
            </div>
          </div>
        </div>
        <div className="py-4 px-4">
          <p className="text-sm font-bold pb-2">Admin Contact Info</p>
          <div className="flex gap-2">
            <div className="flex-1 flex gap-1">
              <img src={call} alt="image" />
              <div className="flex flex-col">
                <span className="text-sm">Mobile number</span>
                <span className="text-sm font-semibold">86544764325</span>
              </div>
            </div>
            <div className="flex-1">
              <Button variant="contained">Chat with us</Button>
            </div>
          </div>
        </div>
      </div>
      <div className="border br-10 px-4 py-4 flex flex-col mb-4 bg-white">
        <span className="text-base font-bold pb-2">Get the App Now!</span>
        <span className="text-sm pb-4">
          Download the application today to get access all features!
        </span>
        <Button variant="contained" className="w-full">
          Download Now
        </Button>
      </div>
      <div className="flex flex-col">
        <p className="font-bold text-base mb-2">How it works?</p>
        <div className="border py-5 px-4 flex flex-col gap-5 bg-white">
          <div className="flex gap-4">
            <img src={w1} />
            <div className="flex flex-col gap-1">
              <span className="text-sm font-bold">
                Create Trade Business Account
              </span>
              <span className="text-sm">
                Access MANDI module and create trade business account in single
                step. 
              </span>
            </div>
          </div>
          <div className="flex gap-4">
            <img src={w2} />
            <div className="flex flex-col gap-1">
              <span className="text-sm font-bold">Post Your Requirements</span>
              <span className="text-sm">
                Access MANDI module & create trade business account in single
                step. 
              </span>
            </div>
          </div>
          <div className="flex gap-4">
            <img src={w3} />
            <div className="flex flex-col gap-1">
              <span className="text-sm font-bold">
                Review & Accept Proposals
              </span>
              <span className="text-sm">
                Access MANDI module & create trade business account in single
                step. 
              </span>
            </div>
          </div>
          <div className="flex gap-4">
            <img src={w4} />
            <div className="flex flex-col gap-1">
              <span className="text-sm font-bold">
                Track your Orders Status
              </span>
              <span className="text-sm">
                Access MANDI module & create trade business account in single
                step. 
              </span>
            </div>
          </div>
          <div className="flex gap-4">
            <img src={w5} />
            <div className="flex flex-col gap-1">
              <span className="text-sm font-bold">Make / Receive Payment</span>
              <span className="text-sm">
                Access MANDI module & create trade business account in single
                step. 
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostDetails;
