import { FaXmark } from "react-icons/fa6";
import useOrderView from "./useOrderView";
import useArrivalsView from "./useArrivalsView";
import { ChangeEvent, FC } from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { TRootState } from "../../store";
import SelectDate from "../../components/ui/select-date";
import { Button, FileUploader, Input } from "../../components/ui";
import { getCurrencyNumberWithSymbol } from "../../helpers/get-currency-number";
import DocumentViewer from "../../components/commons/document-preview";
import { useParams } from "react-router";
import { all } from "axios";
const PattiFinalization: FC<{
  editStatus: string;
  onPattiClosed: any;
  tyoeFrom: any;
}> = ({ editStatus, onPattiClosed, tyoeFrom }) => {
  const params = useParams();
  // const { onUploadReceiptImage, otherDocuments, onRemoveReceiptImage } =
  //   useOrderView();
  //   const { tradeType } = useSelector((state: TRootState) => ({
  //     tradeType: state.auth.tradeType,
  //   }));
  const {
    onUploadReceiptImage,
    pattiDocuments,
    onRemoveReceiptImage,
    alltradeDetail,
    setArrivalIInfo,
    arrivalIInfo,
    confrirmArrival,
    handlePattiDateSelection,
  } = useArrivalsView({
    traderId: parseInt(params.traderId || "0"),
    tradeId: parseInt(params.tradeId || "0"),
    mode: editStatus == "edit" ? true : false,
    type: "PATTI",
    typeFrom: tyoeFrom,
    closeModal: onPattiClosed,
  });
  useEffect(() => {
    if (arrivalIInfo != null) {
      setArrivalIInfo({
        ...arrivalIInfo,
        commPerVal: calculatePercentagefromTotal(
          arrivalIInfo?.comm !== "" ? Number(arrivalIInfo?.comm) : 0,
          Number(arrivalIInfo?.pattiAmt)
        ),
        comm: arrivalIInfo?.comm || "",
        destPfPer: parseFloat(
          calculatePercentagefromTotal(
            arrivalIInfo?.destPf !== "" ? arrivalIInfo?.destPf : 0,
            Number(arrivalIInfo?.pattiAmt)
          ).toFixed(2)
        ),
        srcPfPer: parseFloat(
          calculatePercentagefromTotal(
            arrivalIInfo?.srcPf !== "" ? arrivalIInfo?.srcPf : 0,
            Number(arrivalIInfo?.pattiAmt)
          ).toFixed(2)
        ),
      });
    }
  }, [editStatus]);
  const onChangeCommTotal = (e: ChangeEvent<HTMLInputElement>) => {
    setArrivalIInfo({
      ...arrivalIInfo,
      commPerVal: parseFloat(
        calculatePercentagefromTotal(
          e.target.value !== "" ? Number(e.target.value) : 0,
          Number(alltradeDetail?.pattiAmt)
        ).toFixed(2)
      ),
      comm: e.target.value !== "" ? parseFloat(e.target.value) : "",
    });
  };
  const onChangeComm = (e: ChangeEvent<HTMLInputElement>) => {
    setArrivalIInfo({
      ...arrivalIInfo,
      comm: parseFloat(
        calculateTotalFromPercentage(
          e.target.value !== "" ? parseFloat(e.target.value) : 0,
          Number(alltradeDetail?.pattiAmt)
        ).toFixed(2)
      ),
      commPerVal: e.target.value !== "" ? parseFloat(e.target.value) : "",
    });
  };
  const onChangePfTotal = (e: ChangeEvent<HTMLInputElement>, type: any) => {
    if (type == "dest") {
      setArrivalIInfo({
        ...arrivalIInfo,
        destPfPer: parseFloat(
          calculatePercentagefromTotal(
            e.target.value !== "" ? Number(e.target.value) : 0,
            Number(alltradeDetail?.pattiAmt)
          ).toFixed(2)
        ),
        destPf: e.target.value !== "" ? parseFloat(e.target.value) : "",
      });
    } else if (type == "src") {
      setArrivalIInfo({
        ...arrivalIInfo,
        srcPfPer: parseFloat(
          calculatePercentagefromTotal(
            e.target.value !== "" ? Number(e.target.value) : 0,
            Number(alltradeDetail?.pattiAmt)
          ).toFixed(2)
        ),
        srcPf: e.target.value !== "" ? parseFloat(e.target.value) : "",
      });
    }
  };
  const onChangePf = (e: ChangeEvent<HTMLInputElement>, type: any) => {
    if (type == "dest") {
      setArrivalIInfo({
        ...arrivalIInfo,
        destPf: parseFloat(
          calculateTotalFromPercentage(
            e.target.value !== "" ? parseFloat(e.target.value) : 0,
            Number(alltradeDetail?.pattiAmt)
          ).toFixed(2)
        ),
        destPfPer: e.target.value !== "" ? parseFloat(e.target.value) : "",
      });
    } else if (type == "src") {
      console.log(
        calculateTotalFromPercentage(
          e.target.value !== "" ? parseFloat(e.target.value) : 0,
          Number(alltradeDetail?.pattiAmt)
        ),
        "source"
      );
      setArrivalIInfo({
        ...arrivalIInfo,
        srcPf: parseFloat(
          calculateTotalFromPercentage(
            e.target.value !== "" ? parseFloat(e.target.value) : 0,
            Number(alltradeDetail?.pattiAmt)
          ).toFixed(2)
        ),
        srcPfPer: e.target.value !== "" ? parseFloat(e.target.value) : "",
      });
    }
  };
  const calculateTotalFromPercentage = (
    _percentage: number,
    total: number
  ): number => (_percentage / 100) * total;
  const calculatePercentagefromTotal = (part: number, total: number) => {
    return (part / total) * 100;
  };
  return (
    <>
      <div
        className="pb-3 overflow-y-auto"
        style={{ height: "calc(100vh - 22rem)" }}
      >
        <div className="bg-white px-3 py-4 mb-3">
          <div className="flex gap-6">
            <div className="grow-[3]">
              <span className="text-sm">
                Patti Date<span className="color-red">*</span>
              </span>
              <SelectDate
                onChange={handlePattiDateSelection}
                selectedDate={alltradeDetail?.pattiDate!}
              />
            </div>
          </div>
          <div className="block md:flex gap-4 pt-3">
            <div className="grow-[3]">
              <span className="text-sm">
                Sales Amount<span className="color-red">*</span>
              </span>
              <div className="mt-2 relative">
                <Input
                  label=""
                  type="number"
                  required={true}
                  value={alltradeDetail?.pattiAmt}
                  onChange={(e) => {
                    setArrivalIInfo({
                      ...arrivalIInfo!,
                      pattiAmt: parseFloat(e.target.value) || "",
                      comm: parseFloat(
                        calculateTotalFromPercentage(
                          Number(alltradeDetail?.commPerVal),
                          e.target.value !== "" ? Number(e.target.value) : 0
                        ).toFixed(2)
                      ),
                      destPf:
                        tyoeFrom == "DEST"
                          ? parseFloat(
                              calculateTotalFromPercentage(
                                Number(alltradeDetail?.destPfPer),
                                e.target.value !== ""
                                  ? parseFloat(e.target.value)
                                  : 0
                              ).toFixed(2)
                            )
                          : Number(alltradeDetail?.destPf),

                      srcPf:
                        tyoeFrom == "SOURCE"
                          ? parseFloat(
                              calculateTotalFromPercentage(
                                Number(alltradeDetail?.srcPfPer),
                                e.target.value !== ""
                                  ? parseFloat(e.target.value)
                                  : 0
                              ).toFixed(2)
                            )
                          : Number(alltradeDetail?.srcPf),
                    });
                  }}
                  onFocus={(e) => {
                    e.target.value === "0" &&
                      setArrivalIInfo({
                        ...arrivalIInfo,
                        pattiAmt: "",
                      });
                  }}
                />
              </div>
              {alltradeDetail?.pattiAmt! > 0 &&
                Number(alltradeDetail?.netWeight) > 0 && (
                  <span className="text-sm color-sub-text">
                    Average Rate:
                    {alltradeDetail?.pattiAmt
                      ? getCurrencyNumberWithSymbol(
                          Number(arrivalIInfo?.pattiAmt) /
                            Number(alltradeDetail?.netWeight)
                        )
                      : 0}
                  </span>
                )}
            </div>
            <div className="grow-[3] pt-3 md:pt-0">
              <span className="text-sm">
                Freight Balance Paid<span className="color-red">*</span>
              </span>
              <div className="mt-2 relative">
                <Input
                  label=""
                  type="number"
                  required={true}
                  value={alltradeDetail?.freightPaid}
                  disabled={true}
                  // onChange={(e) => {
                  //   setArrivalIInfo({
                  //     ...arrivalIInfo!,
                  //     freightPaid: parseFloat(e.target.value) || "",
                  //   });
                  // }}
                  // onFocus={(e) => {
                  //   e.target.value === "0" &&
                  //     setArrivalIInfo({
                  //       ...arrivalIInfo,
                  //       freightPaid: "",
                  //     });
                  // }}
                />
              </div>
            </div>
          </div>
          <div className="pt-3">
            <div className="flex gap-4">
              <div className="grow-[2]">
                <span className="text-sm">
                  Commission (%)<span className="color-red">*</span>
                </span>
              </div>
              <div className="grow-[2]">
                <span className="text-sm">Amount (₹)</span>
              </div>
            </div>

            <div className="mt-2 relative flex gap-4">
              <div className="grow-[2]">
                <Input
                  label=""
                  type="number"
                  required={true}
                  value={alltradeDetail?.commPerVal}
                  onChange={onChangeComm}
                  onFocus={(e) => {
                    e.target.value === "0" &&
                      setArrivalIInfo({
                        ...arrivalIInfo,
                        commPerVal: "",
                      });
                  }}
                  style={{
                    borderRight: 0,
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                  }}
                />
              </div>
              <div className="grow-[2]">
                <Input
                  label=""
                  type="number"
                  required={false}
                  value={alltradeDetail?.comm}
                  onChange={onChangeCommTotal}
                  onFocus={(e) => {
                    e.target.value === "0" &&
                      setArrivalIInfo({
                        ...arrivalIInfo,
                        comm: "",
                      });
                  }}
                  style={{
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                  }}
                />
              </div>
            </div>
          </div>
          <div className="block md:flex gap-4 pt-3">
            <div className="grow-[3]">
              <span className="text-sm">Labour Charges</span>
              <div className="mt-2 relative">
                <Input
                  label=""
                  type="number"
                  required={false}
                  value={alltradeDetail?.lbrCharges}
                  onChange={(e) => {
                    setArrivalIInfo({
                      ...arrivalIInfo!,
                      lbrCharges: parseFloat(e.target.value) || "",
                    });
                  }}
                  onFocus={(e) => {
                    e.target.value === "0" &&
                      setArrivalIInfo({
                        ...arrivalIInfo,
                        lbrCharges: "",
                      });
                  }}
                />
              </div>
            </div>
            <div className="grow-[3] pt-3 md:pt-0">
              <span className="text-sm">Miscellaneous</span>
              <div className="mt-2 relative">
                <Input
                  label=""
                  type="number"
                  required={false}
                  value={alltradeDetail?.misc}
                  onChange={(e) => {
                    setArrivalIInfo({
                      ...arrivalIInfo!,
                      misc: parseFloat(e.target.value) || "",
                    });
                  }}
                  onFocus={(e) => {
                    e.target.value === "0" &&
                      setArrivalIInfo({
                        ...arrivalIInfo,
                        misc: "",
                      });
                  }}
                />
              </div>
            </div>
          </div>
          {/* <div className="pt-3">
            <span className="text-sm">Discount</span>
            <div className="mt-2 relative">
              <Input
                label=""
                type="number"
                required={false}
                value={alltradeDetail?.discount}
                onChange={(e) => {
                  setArrivalIInfo({
                    ...arrivalIInfo!,
                    discount: parseFloat(e.target.value) || "",
                  });
                }}
                onFocus={(e) => {
                  e.target.value === "0" &&
                    setArrivalIInfo({
                      ...arrivalIInfo,
                      discount: "",
                    });
                }}
              />
            </div>
          </div> */}
          <div className="block md:flex gap-4 pt-3">
            <div className="grow-[3]">
              <span className="text-sm">
                Advance {tyoeFrom == "DEST" ? "Paid" : "Received"}
              </span>
              <div className="mt-2 relative">
                <Input
                  label=""
                  type="number"
                  required={true}
                  value={
                    tyoeFrom == "DEST"
                      ? alltradeDetail?.advancePaid
                      : alltradeDetail?.advanceRcvd
                  }
                  disabled={true}
                />
              </div>
            </div>
            <div className="grow-[3] pt-3 md:pt-0">
              <span className="text-sm">
                {" "}
                Total {tyoeFrom == "DEST" ? "Payable" : "Receivable"}
              </span>
              <div className="mt-2 relative">
                <Input
                  label=""
                  type="number"
                  required={true}
                  value={
                    tyoeFrom == "DEST"
                      ? alltradeDetail?.totalPayable
                      : alltradeDetail?.totalReceivable
                  }
                  disabled={true}
                />
              </div>
            </div>
          </div>
          <div className="py-3">
            <span className="color-primary">Platform Fees</span>
          </div>
          <div className="block md:flex gap-4 pt-3">
            <div className="grow-[3]">
              <span className="text-sm">Platform Fees (%)</span>
              <div className="mt-2 relative">
                {tyoeFrom == "DEST" ? (
                  <Input
                    label=""
                    type="number"
                    required={true}
                    value={alltradeDetail?.destPfPer}
                    onChange={(e) => {
                      onChangePf(e, "dest");
                    }}
                    onFocus={(e) => {
                      e.target.value === "0" &&
                        setArrivalIInfo({
                          ...arrivalIInfo,
                          destPfPer: "",
                        });
                    }}
                  />
                ) : (
                  <Input
                    label=""
                    type="number"
                    required={true}
                    value={alltradeDetail?.srcPfPer}
                    onChange={(e) => {
                      onChangePf(e, "src");
                    }}
                    onFocus={(e) => {
                      e.target.value === "0" &&
                        setArrivalIInfo({
                          ...arrivalIInfo,
                          srcPfPer: "",
                        });
                    }}
                  />
                )}
              </div>
            </div>
            <div className="grow-[3] pt-3 md:pt-0">
              <span className="text-sm"> Platform Fees Amount (₹)</span>
              <div className="mt-2 relative">
                {tyoeFrom == "DEST" ? (
                  <Input
                    label=""
                    type="number"
                    required={true}
                    value={alltradeDetail?.destPf}
                    onChange={(e) => {
                      onChangePfTotal(e, "dest");
                    }}
                    onFocus={(e) => {
                      e.target.value === "0" &&
                        setArrivalIInfo({
                          ...arrivalIInfo,
                          destPf: "",
                        });
                    }}
                  />
                ) : (
                  <Input
                    label=""
                    type="number"
                    required={true}
                    value={alltradeDetail?.srcPf}
                    onChange={(e) => {
                      onChangePfTotal(e, "src");
                    }}
                    onFocus={(e) => {
                      e.target.value === "0" &&
                        setArrivalIInfo({
                          ...arrivalIInfo,
                          srcPf: "",
                        });
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="block md:flex gap-4 pt-3">
            <div className="grow-[3]">
              <span className="text-sm">Platform Fees Discount (₹)</span>
              <div className="mt-2 relative">
                {tyoeFrom == "DEST" ? (
                  <Input
                    label=""
                    type="number"
                    required={true}
                    value={alltradeDetail?.destDis}
                    onChange={(e) => {
                      setArrivalIInfo({
                        ...arrivalIInfo,
                        destDis: parseFloat(e.target.value) || "",
                      });
                    }}
                    onFocus={(e) => {
                      e.target.value === "0" &&
                        setArrivalIInfo({
                          ...arrivalIInfo,
                          destDis: "",
                        });
                    }}
                  />
                ) : (
                  <Input
                    label=""
                    type="number"
                    required={true}
                    value={alltradeDetail?.srcDis}
                    onChange={(e) => {
                      setArrivalIInfo({
                        ...arrivalIInfo,
                        srcDis: parseFloat(e.target.value) || "",
                      });
                    }}
                    onFocus={(e) => {
                      e.target.value === "0" &&
                        setArrivalIInfo({
                          ...arrivalIInfo,
                          srcDis: "",
                        });
                    }}
                  />
                )}
              </div>
            </div>
            <div className="grow-[3] pt-3 md:pt-0">
              <span className="text-sm">
                {" "}
                {tyoeFrom == "DEST" ? "Final Payable" : "Final Receivable"}
              </span>
              <div className="mt-2 relative">
                <Input
                  label=""
                  type="number"
                  required={true}
                  value={
                    tyoeFrom == "DEST"
                      ? alltradeDetail?.finalPayable
                      : alltradeDetail?.finalReceivable
                  }
                  disabled={true}
                  inputStyle={{
                    color: tyoeFrom == "DEST" ? "color-red" : "color-primary",
                  }}
                />
              </div>
            </div>
          </div>
          {/* <div className="pt-3">
            <span className="text-sm">Comments</span>
            <div className="mt-2 relative">
              <Input
                label=""
                type="text"
                required={true}
                value={alltradeDetail?.pattiComments}
                onChange={(e) => {
                  setArrivalIInfo({
                    ...arrivalIInfo!,
                    pattiComments: e.target.value,
                  });
                }}
              />
            </div>
          </div> */}
          {/* <div className="pt-3">
            <div className="flex border-b">
              <div className="w-2/3 ml-auto text-xs">
                <div className="flex py-2 items-center">
                  <span className="basis-0 grow-[3] pl-4 text-left">
                    Sales Amount:
                  </span>
                  <span className="basis-0 grow-[2] pr-4 text-right">
                    {getCurrencyNumberWithSymbol(alltradeDetail?.pattiAmt) || 0}
                  </span>
                </div>
                <div className="flex py-2 items-center">
                  <span className="basis-0 grow-[3] pl-4 text-left">
                    Freight Bal Paid
                  </span>
                  <span className="basis-0 grow-[2] pr-4 text-right">
                    {getCurrencyNumberWithSymbol(alltradeDetail?.freightPaid) ||
                      0}
                  </span>
                </div>
                <div className="flex py-2 items-center">
                  <span className="basis-0 grow-[3] pl-4 text-left">
                    Commission
                  </span>
                  <span className="basis-0 grow-[2] pr-4 text-right">
                    {getCurrencyNumberWithSymbol(alltradeDetail?.comm) || 0}
                  </span>
                </div>
                <div className="flex py-2 items-center">
                  <span className="basis-0 grow-[3] pl-4 text-left">
                    Labour Charges
                  </span>
                  <span className="basis-0 grow-[2] pr-4 text-right">
                    {getCurrencyNumberWithSymbol(alltradeDetail?.lbrCharges) ||
                      0}
                  </span>
                </div>

                <div className="flex py-2 items-center">
                  <span className="basis-0 grow-[3] pl-4 text-left">
                    Miscellaneous
                  </span>
                  <span className="basis-0 grow-[2] pr-4 text-right">
                    {getCurrencyNumberWithSymbol(alltradeDetail?.misc) || 0}
                  </span>
                </div>
                <div className="flex py-2 items-center">
                  <span className="basis-0 grow-[3] pl-4 text-left">
                    Advance Paid
                  </span>
                  <span className="basis-0 grow-[2] pr-4 text-right">
                    {getCurrencyNumberWithSymbol(alltradeDetail?.advancePaid) ||
                      0}
                  </span>
                </div>
              </div>
            </div>
            <div className="flex border-b">
              <div className="w-2/3 ml-auto  ">
                <div className="flex py-2 items-center text-sm font-medium">
                  <span className="basis-0 grow-[3] pl-4 text-left">
                    Total Payable
                  </span>
                  <span className="basis-0 grow-[2] pr-4 text-right">
                    {getCurrencyNumberWithSymbol(
                      alltradeDetail?.totalPayable
                    ) || 0}
                  </span>
                </div>
                <div className="flex py-2 items-center text-xs">
                  <span className="basis-0 grow-[3] pl-4 text-left">
                    Platform Fees <br></br>Inclusive of Taxes
                  </span>
                  <span className="basis-0 grow-[2] pr-4 text-right">
                    {getCurrencyNumberWithSymbol(alltradeDetail?.srcPf) || 0}
                  </span>
                </div>
              </div>
            </div>
            <div className="flex">
              <div className="w-2/3 ml-auto text-sm color-red">
                <div className="flex py-2 items-center">
                  <span className="basis-0 grow-[3] pl-4 text-left">
                    {alltradeDetail?.tradeType == "DEST"
                      ? "Final Payable"
                      : "Final Receivable"}
                  </span>
                  <span className="basis-0 grow-[2] pr-4 text-right">
                    {alltradeDetail?.tradeType == "DEST"
                      ? getCurrencyNumberWithSymbol(
                          Number(alltradeDetail?.totalPayable) +
                            Number(alltradeDetail?.destPf) -
                            Number(alltradeDetail?.destDis || 0)
                        ) || 0
                      : getCurrencyNumberWithSymbol(
                          Number(alltradeDetail?.totalPayable) -
                            Number(alltradeDetail?.srcPf) -
                            Number(alltradeDetail?.srcDis || 0)
                        ) || 0}
                  </span>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <div className="flex items-center bottom-0 right-0 w-full bg-white px-4 py-4 border-top gap-2">
        <Button
          variant="primaryLight"
          className="w-full"
          text="CANCEL"
          onClick={() => {
            onPattiClosed(false);
          }}
        />
        <Button
          variant="contained"
          className="w-full"
          onClick={() => {
            confrirmArrival();
          }}
        >
          SAVE
        </Button>
      </div>
    </>
  );
};
export default PattiFinalization;
