import { FaBackspace, FaEdit } from "react-icons/fa";
import { FaAngleDown, FaBackward, FaXmark } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";
import CardTitleBg from "../../../components/ui/cardTitleBg";
import { Button, FileUploader, Input, Popover } from "../../../components/ui";
import UserAccountStatus from "./account-status";
import { getAccountStatusDisplayIcon } from "../../../helpers/getText";
import SelectDate from "../../../components/ui/select-date";
import moment from "moment";
import DocumentPreview from "../../../components/commons/document-preview";
import { useSelector } from "react-redux";
import { TRootState } from "../../../store";
import useBorrowerDetailsEdit from "./useBorrowerEdit";
import { ArrowDropDown } from "@mui/icons-material";
import RelationshipMngr from "../CustomerDetails/RelationshipMngr";
import { FC, useState } from "react";
import useBeneficiaryDetailsById from "../../../hooks/useBeneficiaryDetailsById";
const EditBeneficiary: FC<{
  onEditBeneficiaryModalClose: any;
  type: string;
}> = ({ onEditBeneficiaryModalClose, type }) => {
  const navigate = useNavigate();

  const params = useParams();
  const [rmOpen, setRmOpen] = useState(false);
  const {
    handleDateSelection,
    onChangeStatus,
    submit,
    kycDocs,
    onRemoveBeneficiaryImage,
    onUploadBeneficiaryImage,
    beneficiaryDetails,
    setBeneficiaryDetails,
  } = useBorrowerDetailsEdit({
    appId: parseInt(params.appId || "0"),
    cpId: parseInt(params.cpId || "0"),
    type: type,
    modalClose: onEditBeneficiaryModalClose,
  });


  const {confirmAccountNum, setConfirmAccountNum}=useBeneficiaryDetailsById({});

  const [error, setError] = useState("");

  const handleAccountNumChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setBeneficiaryDetails((prev) => ({ ...prev!, accNum: value }));

    if (confirmAccountNum) {
      validateAccountNums(value, confirmAccountNum); 
    } else {
      setError("");
    }
  };

  const handleConfirmAccountNumChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value;
    setConfirmAccountNum(value);

    if (beneficiaryDetails?.accNum) {
      validateAccountNums(beneficiaryDetails?.accNum, value);
    } else {
      setError(""); 
    }
  };

  const validateAccountNums = (accNum: string, confirmAccountNum: string) => {
    if (accNum !== confirmAccountNum) {
      setError("Account numbers do not match");
    } else {
      setError("");
    }
  };

  return (
    <>
      {" "}
      <div
        className="px-4 py-4 overflow-y-auto"
        style={{ height: "calc(100vh - 15rem)" }}
      >
        <CardTitleBg
          titile={
            <div className="flex items-center">
              <span>Status Parameters</span>
            </div>
          }
          titileClassName="px-4"
          className="bg-gray-50 "
        >
          <div className="px-4 py-2">
            <div className="flex justify-between gap-4 py-3">
              <div className="" style={{ width: "80%" }}>
                <span className="text-sm color-sub-text">Comments</span>
                <div className="mt-2 relative">
                  <Input
                    label=""
                    type="text"
                    required={true}
                    value={beneficiaryDetails?.comments}
                    onChange={(e) => {
                      setBeneficiaryDetails({
                        ...beneficiaryDetails!,
                        comments: e.target.value,
                      });
                    }}
                    className="text-sm font-semibold"
                  />
                </div>
              </div>
              <div className="flex-1">
                <span className="text-sm color-sub-text">Status</span>

                <div className="flex justify-between items-center">
                  <div className="mt-2">
                    <UserAccountStatus
                      trade_status={beneficiaryDetails?.appStatus || ""}
                      onChangeTradeStatus={onChangeStatus}
                      statusIcon={getAccountStatusDisplayIcon({
                        status: beneficiaryDetails?.appStatus,
                      })}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CardTitleBg>
        <CardTitleBg
          titile={
            <div className="flex items-center">
              <span>Risk Parameters</span>
            </div>
          }
          titileClassName="px-4"
          className="bg-gray-50 mt-4"
        >
          <div className="px-4 py-2">
            <div className="flex justify-between gap-4 py-3">
              <div className="flex-1">
                <span className="text-sm color-sub-text">
                  ORS - ONO RISK SCORE*
                </span>
                <div className="mt-2 relative">
                  <Input
                    label=""
                    type="number"
                    required={true}
                    value={beneficiaryDetails?.onoScore}
                    onChange={(e) => {
                      setBeneficiaryDetails({
                        ...beneficiaryDetails!,
                        onoScore: parseFloat(e.target.value),
                      });
                    }}
                    onFocus={(e) => {
                      e.target.value === "0" &&
                        setBeneficiaryDetails({
                          ...beneficiaryDetails!,
                          onoScore: "",
                        });
                    }}
                    className="text-sm font-semibold"
                  />
                </div>
              </div>
              <div className="flex-1">
                <span className="text-sm color-sub-text">CASH Limit* (₹)</span>
                <div className="mt-2 relative">
                  <Input
                    label=""
                    type="number"
                    required={true}
                    value={"0"}
                    disabled
                    className="text-sm font-semibold"
                  />
                </div>
              </div>
              <div className="flex-1">
                <span className="text-sm color-sub-text">Renewal Date</span>
                <div className="mt-2 relative">
                  <SelectDate
                    onChange={handleDateSelection}
                    selectedDate={
                      beneficiaryDetails?.renewDate ||
                      moment()
                        .add(1, "year")
                        .subtract(1, "day")
                        .format("YYYY-MM-DD")
                    }
                    styles={{ padding: "14px" }}
                    maxDate={moment().add(2, "years").format("YYYY-MM-DD")}
                  />
                </div>
              </div>
              <div className="flex-1">
                <span className="text-sm color-sub-text">
                  Relationship Manager
                </span>
                <div className="mt-2 relative">
                  <div
                    className="w-[270px] h-[49px] mr-[20px] border-gray cursor-pointer br-5"
                    onClick={() => {
                      setRmOpen(true);
                      //   setSearchTerm("");
                    }}
                  >
                    <div className="relative flex items-center h-full">
                      <span className="text-center ml-2 text-sm font-semibold">
                        {beneficiaryDetails?.rmName || "Select Rm"}
                      </span>

                      <span className="absolute top-2 right-0">
                        <ArrowDropDown />
                      </span>
                    </div>
                  </div>

                  {/* relationship manager dropdown */}
                  <div className="ml-[135px]">
                    <Popover
                      open={rmOpen}
                      onClose={() => {
                        setRmOpen(false);
                      }}
                      className="w-[270px] h-auto"
                    >
                      <div>
                        <RelationshipMngr
                          rmId={0}
                          onChange={(rm) => {
                            setBeneficiaryDetails({
                              ...beneficiaryDetails!,
                              rmMobile: rm.mobile,
                              rmId: rm.id,
                              rmName: rm.name,
                            });
                            setRmOpen(false);
                          }}
                          onClear={() => {
                            setBeneficiaryDetails({
                              ...beneficiaryDetails!,
                              rmId: 0,
                              rmMobile: "",
                              rmName: "",
                            });
                          }}
                        />
                      </div>
                    </Popover>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CardTitleBg>
        <CardTitleBg
          titile={
            <div className="flex items-center">
              <span>Bank Details</span>
            </div>
          }
          titileClassName="px-4"
          className="bg-gray-50 mt-4"
        >
          <div className="px-4 py-2">
            <div className="flex justify-between gap-4 py-3">
              <div className="flex-1">
                <span className="text-sm color-sub-text">Account Holder</span>
                <div className="mt-2 relative">
                  <Input
                    label=""
                    type="text"
                    required={true}
                    value={beneficiaryDetails?.accHolder}
                    onChange={(e) => {
                      setBeneficiaryDetails({
                        ...beneficiaryDetails!,
                        accHolder: e.target.value,
                      });
                    }}
                    className="text-sm font-semibold"
                  />
                </div>
              </div>
              <div className="flex-1">
                <span className="text-sm color-sub-text">Account Number</span>
                <div className="mt-2 relative">
                  <Input
                    label=""
                    type="password"
                    required={true}
                    value={beneficiaryDetails?.accNum}
                    onChange={handleAccountNumChange}
                    autoComplete="new-password"
                    className="text-sm font-semibold"
                  />
                </div>
              </div>
              <div className="flex-1">
                <span className="text-sm color-sub-text">
                  Confirm Account Number
                </span>
                <div className="mt-2 relative">
                  <Input
                    label=""
                    type="number" 
                    required={true}
                    value={confirmAccountNum}
                    onChange={handleConfirmAccountNumChange}
                    className="text-sm font-semibold"
                  />
                </div>
                {error && (
                  <span className="color-red text-sm py-2">{error}</span>
                )}
              </div>
              <div className="flex-1">
                <span className="text-sm color-sub-text">Bank Name</span>
                <div className="mt-2 relative">
                  <Input
                    label=""
                    type="text"
                    required={true}
                    value={beneficiaryDetails?.bankName}
                    onChange={(e) => {
                      setBeneficiaryDetails({
                        ...beneficiaryDetails!,
                        bankName: e.target.value,
                      });
                    }}
                    className="text-sm font-semibold"
                  />
                </div>
              </div>
              <div className="flex-1">
                <span className="text-sm color-sub-text">IFSC Code</span>
                <div className="mt-2 relative">
                  <Input
                    label=""
                    type="text"
                    required={true}
                    value={beneficiaryDetails?.ifscCode}
                    onChange={(e) => {
                      setBeneficiaryDetails({
                        ...beneficiaryDetails!,
                        ifscCode: e.target.value,
                      });
                    }}
                    className="text-sm font-semibold"
                  />
                </div>
              </div>
            </div>
          </div>
        </CardTitleBg>
        <CardTitleBg
          titile={
            <div className="flex items-center">
              <span>Documents</span>
            </div>
          }
          titileClassName="px-4"
          className="bg-gray-50 mt-4"
        >
          <div className="flex px-4 py-4 gap-4">
            <div className="px-2 py-2 border br-10" style={{ width: "50%" }}>
              <h2 className="color-sub-text pt-2 text-sm">Aadhar Card</h2>
              <div className="flex gap-4 py-4">
                      {beneficiaryDetails?.kycDocs.filter(
                        (doc) => doc.docType == "AADHAR"
                      ).length! > 0 ? (
                        <>
                          {beneficiaryDetails?.kycDocs
                            .filter((doc) => doc.docType == "AADHAR")
                            .map((item, i) => (
                              <div
                                className="shrink-0 overflow-hidden border p-2 rounded relative"
                                style={{
                                  width: "30%",
                                  height: "8rem",
                                }}
                              >
                                <a target="_blank" href={item.docUrl}>
                                  <DocumentPreview
                                    url={item.docUrl}
                                    docType={item.type}
                                  />
                                </a>
                              </div>
                            ))}
                        </>
                      ) : (
                        <div
                          className="ml-4 mt-1 border rounded-sm bg-gray-50 flex items-center justify-center p-4 text-sm text-center"
                          style={{
                            width: "20%",
                            height: "8rem",
                          }}
                        >
                          Not Uploaded Aadhar
                        </div>
                      )}
                    </div>
            </div>
            <div className="px-2 py-2 border br-10" style={{ width: "50%" }}>
              <h2 className="color-sub-text pt-2 text-sm">
                Permanent Account Number(PAN)
                <span className="color-red">*</span>
              </h2>
              <div className="flex flex-wrap gap-2 overflow-auto">
                {/* {editDetail.section === "arival_document" &&
                                    editDetail.mode === "edit" && ( */}
                <div className="shrink-0 overflow-hidden p-2">
                  <FileUploader
                    label=""
                    onChange={(e) => {
                      if (e.target.files) {
                        onUploadBeneficiaryImage("PAN_CARD", e.target.files);
                      }
                    }}
                  />
                </div>
                {/* )} */}
                {beneficiaryDetails?.kycDocs &&
                  beneficiaryDetails?.kycDocs.length > 0 &&
                  beneficiaryDetails?.kycDocs
                    .filter(
                      (doc) => doc.docType == "PAN_CARD" && doc.status !== 0
                    )
                    .map((image, i) => (
                      <div
                        className="shrink-0 overflow-hidden border-2 p-2 rounded relative mt-2"
                        style={{
                          width: "25%",
                          height: "7rem",
                        }}
                      >
                        <a href={image.docUrl} target="_blank">
                          <DocumentPreview
                            url={image.docUrl}
                            docType={image.type}
                          />
                        </a>
                        {/* {onEdit && ( */}
                        <span
                          className="absolute right-1 top-1 p-1 rounded-full cursor-pointer"
                          style={{
                            background: "#ff0000de",
                          }}
                          onClick={() => {
                            onRemoveBeneficiaryImage(
                              image.docUrl,
                              true,
                              image.id
                            );
                          }}
                        >
                          <FaXmark style={{ color: "white" }} />
                        </span>
                        {/* )} */}
                      </div>
                    ))}
                {kycDocs
                  .filter((doc) => doc.type == "PAN_CARD")
                  .map((image, i) => (
                    <div
                      className="shrink-0 overflow-hidden border-2 p-2 rounded relative mt-2"
                      style={{
                        width: "25%",
                        height: "7rem",
                      }}
                    >
                      <a href={image.url} target="_blank">
                        <DocumentPreview
                          url={image.url}
                          docType={image.url.includes(".pdf") ? "pdf" : "image"}
                        />
                      </a>
                      {/* {onEdit && ( */}
                      <span
                        className="absolute right-1 top-1 p-1 rounded-full cursor-pointer"
                        style={{
                          background: "#ff0000de",
                        }}
                        onClick={() => {
                          onRemoveBeneficiaryImage(image.url, false, i);
                        }}
                      >
                        <FaXmark style={{ color: "white" }} />
                      </span>
                      {/* )} */}
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="flex px-4 pb-4 gap-4">
            <div className="px-2 py-2 border br-10" style={{ width: "50%" }}>
              <h2 className="color-sub-text pt-2 text-sm">Bank Details</h2>
              <div className="flex flex-wrap gap-2 overflow-auto">
                {/* {editDetail.section === "arival_document" &&
                                    editDetail.mode === "edit" && ( */}
                <div className="shrink-0 overflow-hidden p-2">
                  <FileUploader
                    label=""
                    onChange={(e) => {
                      if (e.target.files) {
                        onUploadBeneficiaryImage(
                          "BANK_STATEMENT",
                          e.target.files
                        );
                      }
                    }}
                  />
                </div>
                {/* )} */}
                {beneficiaryDetails?.kycDocs &&
                  beneficiaryDetails?.kycDocs.length > 0 &&
                  beneficiaryDetails?.kycDocs
                    .filter(
                      (doc) =>
                        doc.docType == "BANK_STATEMENT" && doc.status !== 0
                    )
                    .map((image, i) => (
                      <div
                        className="shrink-0 overflow-hidden border-2 p-2 rounded relative mt-2"
                        style={{
                          width: "25%",
                          height: "7rem",
                        }}
                      >
                        <a href={image.docUrl} target="_blank">
                          <DocumentPreview
                            url={image.docUrl}
                            docType={image.type}
                          />
                        </a>
                        {/* {onEdit && ( */}
                        <span
                          className="absolute right-1 top-1 p-1 rounded-full cursor-pointer"
                          style={{
                            background: "#ff0000de",
                          }}
                          onClick={() => {
                            onRemoveBeneficiaryImage(
                              image.docUrl,
                              true,
                              image.id
                            );
                          }}
                        >
                          <FaXmark style={{ color: "white" }} />
                        </span>
                        {/* )} */}
                      </div>
                    ))}
                {kycDocs
                  .filter((doc) => doc.type == "BANK_STATEMENT")
                  .map((image, i) => (
                    <div
                      className="shrink-0 overflow-hidden border-2 p-2 rounded relative mt-2"
                      style={{
                        width: "25%",
                        height: "7rem",
                      }}
                    >
                      <a href={image.url} target="_blank">
                        <DocumentPreview
                          url={image.url}
                          docType={image.url.includes(".pdf") ? "pdf" : "image"}
                        />
                      </a>
                      {/* {onEdit && ( */}
                      <span
                        className="absolute right-1 top-1 p-1 rounded-full cursor-pointer"
                        style={{
                          background: "#ff0000de",
                        }}
                        onClick={() => {
                          onRemoveBeneficiaryImage(image.url, false, i);
                        }}
                      >
                        <FaXmark style={{ color: "white" }} />
                      </span>
                      {/* )} */}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </CardTitleBg>
      </div>
      <div className="flex items-center bottom-0 justify-between w-full bg-white px-4 py-4 border-t gap-2">
        <Button
          variant="primaryLight"
          className=""
          text="CANCEL"
          onClick={() => {
            onEditBeneficiaryModalClose(false);
          }}
        />
        <Button
          variant="contained"
          className=""
          onClick={() => {
            submit();
            //   confrirmArrival();
            // onArrivalModalClose(false);
          }}
        >
          SUBMIT
        </Button>
      </div>
    </>
  );
};
export default EditBeneficiary;
