import { ChangeEvent, FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import PartnerInfo from "../../components/partner-info";
import SelectCrop from "../../components/select-crop";
import { Button, FileUploader, Input } from "../../components/ui";
import SelectDate from "../../components/ui/select-date";
import { FaXmark } from "react-icons/fa6";
import DocumentPreview from "../../components/commons/document-preview";
import StyledRadioButton from "../../components/ui/styledRadioButton";
import useDemandDetailsEdit from "../../hooks/useDemandPostEdit";
import Select2, { OnoSelect } from "../../components/ui/select2";
import moment from "moment";
import { getCurrencyNumberWithSymbol } from "../../helpers/get-currency-number";
import $ from "jquery";
const calculate = ({
  qty,
  rate,
  qtyUnit,
}: {
  qty: number;
  rate: number | string;
  qtyUnit: string | number;
}) => {
  let total = 0;
  if (qtyUnit == "MT/TON") {
    total = Number(qty) * 1000 * Number(rate);
  } else if (qtyUnit == "QUINTAL") {
    total = Number(qty) * 100 * Number(rate);
  } else {
    total = Number(qty) * Number(rate);
  }
  return parseFloat(total.toFixed(2));
};
let calculateTimer: any = null;
const EditDemandPost: FC<{
  onModalClose: any;
  type?: string;
  fromDemandTab?: boolean;
  postID?: any;
}> = ({ onModalClose, type, fromDemandTab, postID }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const {
    demandDetails,
    handleDateSelection,
    setDemandDetails,
    submit,
    // onRemoveReceiptImage,
    onUploadReceiptImage,
    selectedDate,
    cropImages,
    onRemoveReceiptImage,
    handleOfferDateSelection,
    selectedOfferDate,
    setLatitude,
    setLongitude,
  } = useDemandDetailsEdit({
    id: fromDemandTab ? postID || "0" : parseInt(params.postId || "0"),
    modalClose: onModalClose,
    fromDemandTab: fromDemandTab,
  });
  const [totalVal, setTotal] = useState(demandDetails?.expTotal);
  const initCalculate = () => {
    if (calculateTimer) {
      clearTimeout(calculateTimer);
    }
    calculateTimer = setTimeout(() => {
      let total = calculate({
        qty: typeof demandDetails?.qty === "number" ? demandDetails?.qty : 0,
        rate: demandDetails?.expRate ? demandDetails?.expRate : 0,
        qtyUnit: demandDetails?.unitType || "",
      });
      setTotal(total);
      setDemandDetails({
        ...demandDetails!,
        expTotal: total,
      });
    }, 300);
  };
  useEffect(() => {
    initCalculate();
  }, [demandDetails?.qty, demandDetails?.expRate, demandDetails?.unitType]);
  const formatString = (str: any) => {
    if (str) {
      return str.replace(/\s+/g, "").toLowerCase();
    }
  };
  useEffect(() => {
    let daysVal;

    if (demandDetails?.validity === "Next 1 days") {
      daysVal = 1;
    } else if (demandDetails?.validity === "Next 2 days") {
      daysVal = 2;
    } else if (demandDetails?.validity === "Next 3 days") {
      daysVal = 3;
    } else if (demandDetails?.validity === "Next 4 days") {
      daysVal = 4;
    } else {
      daysVal = 0;
    }

    const today = new Date();
    const futureDate = new Date();
    futureDate.setDate(today.getDate() + daysVal);
    setDemandDetails({
      ...demandDetails!,
      offerValidity: moment(futureDate).format("YYYY-MM-DD") || "",
    });
  }, [demandDetails?.validity]);
  const [locationName, setLocationName] = useState(demandDetails?.location);

  const getPosition = () => {
    //    setStreetVillage("");
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition, posError);
    } else {
      alert("Sorry, Geolocation is not supported by this browser.");
      // Alert is browser does not support geolocation
    }
  };
  const posError = () => {
    if (navigator.permissions) {
      navigator.permissions.query({ name: "geolocation" }).then((res) => {
        if (res.state === "denied") {
          alert(
            "Enable location permissions for this website in your browser settings."
          );
        }
      });
    } else {
      alert(
        "Unable to access your location. You can continue by submitting location manually."
      ); // Obtaining Lat/long from address necessary
    }
  };
  const showPosition = async (position: any) => {
    let lat = position.coords.latitude; // You have obtained latitude coordinate!
    let long = position.coords.longitude; // You have obtained
    setLatitude(lat);
    setLongitude(long);
    await getAddress(lat, long, "AIzaSyBw-hcIThiKSrWzF5Y9EzUSkfyD8T1DT4A");
  };
  // Converting lat/long from browser geolocation into city, state, and zip code using Google Geocoding API
  const getAddress = (lat: any, long: any, googleKey: any) => {
    var hi = fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=${googleKey}`
    )
      .then((res) => res.json())
      .then((address) => setZip(address));
  };
  // Dispatching city, state, and zip code to store state
  const setZip = (address: any) => {
    var pincodeValue;
    // let pincode = address.results[0].formatted_address;
    for (var i = 0; i < address.results[0].address_components.length; i++) {
      if (address.results[0].address_components[i].types[0] == "postal_code") {
        pincodeValue = address.results[0].address_components[i].long_name;
      }
    }
    pincodeValue = pincodeValue.replace(/\D/g, "");
    let city = address.results[5].address_components[2].short_name;
    let state = address.results[5].address_components[3].short_name;
    $("#city").val(city);
    $("#state").val(state);
    $("#zip").val(pincodeValue);
    // setPincode(pincodeValue);
    setLocationName(city);
    // setStateVal(state);
  };
  const handleAddressChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const address = e.target.value;
    setLocationName(e.target.value);
    if (address.length > 3) {
      // Wait until the user has entered at least 3 characters
      await getCoordinatesFromAddress(
        address,
        "AIzaSyBw-hcIThiKSrWzF5Y9EzUSkfyD8T1DT4A"
      );
      setDemandDetails({
        ...demandDetails!,
        location: e.target.value != "" ? e.target.value : "",
      });
    }
  };

  const getCoordinatesFromAddress = async (address: string, googleKey: any) => {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${googleKey}`
    );
    const data = await response.json();
    if (data.status === "OK" && data.results.length > 0) {
      const lat = data.results[0].geometry.location.lat;
      const lng = data.results[0].geometry.location.lng;
      setLatitude(lat);
      setLongitude(lng);
      setDemandDetails({
        ...demandDetails!,
        latitude: lat,
        longitude: lng,
      });
      //   setLocationName(data.results[0].formatted_address); // Set the formatted address in the input
    } else {
      console.error("Geocoding failed:", data.status);
    }
  };
  useEffect(() => {
    setLocationName(demandDetails?.location);
  }, [demandDetails?.location]);
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Or use any breakpoint you prefer
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
      <div
        className="pb-3 overflow-y-auto"
        style={{ height: "calc(100vh - 22rem)" }}
      >
        <div className="pb-3 w-full">
          <div className="grow-[3] relative">
            <span className="text-sm font-medium">Location</span>
            <div className=" mt-2 relative">
              <Input
                type="text"
                value={locationName}
                onChange={handleAddressChange} // Allow user to type manually
                // placeholder="Search Location"
              />
              <button
                onClick={() => {
                  getPosition();
                }}
                className="absolute color-blue font-medium right-1 top-3"
              >
                Locate me
              </button>
            </div>
          </div>
        </div>
        <div className="pb-0 md:pb-3 w-full block md:flex gap-4">
          <div className="grow-[3]">
            <label className="text-sm font-medium">
              Select Buyer <span className="color-red">{"*"}</span>
            </label>
            <div
              className={`relative flex items-center bg-white shrink-0 px-2 py-2 border br-10 mt-2`}
              style={{ borderRightWidth: "1px", height: "70px" }}
            >
              <div className={`flex items-center cursor-pointer w-full`}>
                <PartnerInfo
                  profilePic={demandDetails?.profilePic || ""}
                  name={demandDetails?.traderName || ""}
                  shortName={""}
                  partnerType={"Trader"}
                  partnerId={demandDetails?.traderId}
                  partnerMobile={demandDetails?.mobile || ""}
                  partyName={demandDetails?.addressLine}
                />
              </div>
            </div>
          </div>
          <div className="grow-[3] pt-3 md:pt-0">
            <label className="text-sm font-medium">
              Select Crop <span className="color-red">{"*"}</span>
            </label>
            <div className="grow overflow-auto scrollbar mt-2">
              <SelectCrop status="true" />
            </div>
          </div>
        </div>
        <div className="pt-3 w-full block md:flex gap-4">
          <div className="grow-[3]">
            <label className="text-sm font-medium">
              Quality <span className="color-red">{"*"}</span>
            </label>
            <StyledRadioButton
              displayType="horizontal"
              data={[
                { label: "Best", value: "BEST" },
                { label: "Average", value: "AVERAGE" },
                { label: "Low", value: "LOW" },
              ]}
              value={demandDetails?.quality || ""}
              pointer={{ label: "label", value: "value" }}
              onChange={(value) => {
                setDemandDetails({
                  ...demandDetails!,
                  quality: value || "",
                });
              }}
              styles={{ display: "flex", gap: "10px" }}
            />
          </div>
          <div className="grow-[3] pt-3 md:pt-0">
            <label className="text-sm font-medium">
              Unit Type <span className="color-red">{"*"}</span>
            </label>
            <StyledRadioButton
              displayType="horizontal"
              data={[
                { label: "KGS", value: "KGS" },
                { label: "Quintal", value: "QUINTAL" },
                { label: "MT/TON", value: "MT/TON" },
              ]}
              value={demandDetails?.unitType || ""}
              pointer={{ label: "label", value: "value" }}
              onChange={(value) => {
                setDemandDetails({
                  ...demandDetails!,
                  unitType: value || "",
                });
              }}
              styles={{ display: "flex" }}
            />
          </div>
        </div>

        <div className="pt-3 w-full block md:flex gap-4">
          <table>
            <tr>
              <th className="text-sm font-medium" style={{ width: "40%" }}>
                Quantity*
              </th>
              <td>
                <Input
                  label=""
                  type="number"
                  required={true}
                  value={demandDetails?.qty}
                  onFocus={(e) => {
                    e.target.value === "0" &&
                      setDemandDetails({
                        ...demandDetails!,
                        qty: "",
                      });
                  }}
                  onChange={(e) => {
                    setDemandDetails({
                      ...demandDetails!,
                      qty:
                        e.target.value != "" ? parseFloat(e.target.value) : "",
                    });
                  }}
                  fromSupplyTab={true}
                  inputStyle={{ textAlign: "right" }}
                />
              </td>
            </tr>
            <tr>
              <th className="text-sm font-medium" style={{ width: "40%" }}>
                Expected Rate* (Per KG)
              </th>
              <td>
                <Input
                  label=""
                  type="number"
                  required={true}
                  value={demandDetails?.expRate}
                  onFocus={(e) => {
                    e.target.value === "0" &&
                      setDemandDetails({
                        ...demandDetails!,
                        expRate: "",
                      });
                  }}
                  inputStyle={{ textAlign: "right" }}
                  onChange={(e) => {
                    setDemandDetails({
                      ...demandDetails!,
                      expRate:
                        e.target.value != "" ? parseFloat(e.target.value) : "",
                    });
                  }}
                  fromSupplyTab={true}
                />
              </td>
            </tr>
            <tr>
              <th
                className="text-sm font-medium"
                style={{ borderRight: "0px" }}
              ></th>
              <td
                className="text-sm font-medium text-right"
                style={{ borderLeft: "0px" }}
              >
                Expected Total :{" "}
                <span className="color-primary">
                  {getCurrencyNumberWithSymbol(totalVal) || "0"}
                </span>
              </td>
            </tr>
          </table>
        </div>
        <div className="pt-3">
          <div className="block md:flex gap-4">
            <div className="grow-[3]">
              <span className="text-sm font-medium">
                Expected Date <span className="color-red">*</span>
              </span>
              <div className="mt-2">
                <SelectDate
                  onChange={handleDateSelection}
                  selectedDate={selectedDate || ""}
                  maxDate={moment().add(2, "weeks").format("YYYY-MM-DD")}
                />
              </div>
            </div>
            <div className="grow-[3] flex flex-col gap-1 pt-3 md:pt-0">
              <span className="text-sm mb-2 font-medium">
                Offer Validity <span className="color-red">*</span>
              </span>
              <div className="">
                <Select2
                  value={demandDetails?.validity}
                  options={[
                    { label: "Today Only", value: "Today only" },
                    {
                      label: "Next 1 Days",
                      value: "Next 1 days",
                    },
                    {
                      label: "Next 2 Days",
                      value: "Next 2 days",
                    },
                    {
                      label: "Next 3 Days",
                      value: "Next 3 days",
                    },
                    {
                      label: "Next 4 Days",
                      value: "Next 4 days",
                    },
                  ]}
                  onChange={(e) => {
                    // calculateDateDifference(e.value);
                    setDemandDetails({
                      ...demandDetails!,
                      validity: e.value,
                    });
                  }}
                  fromSupplyDemand={true}
                />
              </div>
              <span className="text-sm">
                Offer Validity date :{" "}
                {moment(demandDetails?.offerValidity).format("DD-MMM-YYYY")}
              </span>
              {/* <SelectDate
                onChange={handleOfferDateSelection}
                selectedDate={selectedOfferDate || ""}
              /> */}
            </div>
          </div>
        </div>
        <div className="pt-3">
          <span className="text-sm font-medium">Upload Photo</span>
          <div className="flex md:flex-wrap gap-2 overflow-auto pb-4">
            <div className="shrink-0 overflow-hidden p-2">
              <FileUploader
                label=""
                onChange={(e) => {
                  onUploadReceiptImage(
                    "demand",
                    e.target.files ? e.target.files[0] : null
                  );
                  // setOnEdit(true);
                }}
                fromCropUpload={true}
              />
            </div>
            {cropImages &&
              cropImages?.length > 0 &&
              cropImages?.map((image, i) => (
                <div
                  className="shrink-0 overflow-hidden border-2 p-2 rounded relative"
                  style={{
                    width: isMobile ? "30%" : "20%",
                    height: "8rem",
                  }}
                >
                  <a href={image.url} target="_blank">
                    <DocumentPreview url={image.url} docType={image.docType} />
                  </a>

                  <span
                    className="absolute right-1 top-1 p-1 rounded-full cursor-pointer"
                    style={{
                      background: "#ff0000de",
                    }}
                    onClick={() => {
                      onRemoveReceiptImage("demand", i);
                    }}
                  >
                    <FaXmark style={{ color: "white" }} />
                  </span>
                </div>
              ))}
            {demandDetails?.imgs &&
              demandDetails?.imgs.length &&
              demandDetails.imgs
                .filter((img) => img.status == 1)
                .map((image, i) => (
                  <div
                    className="shrink-0 overflow-hidden border-2 p-2 rounded relative"
                    style={{
                      width: isMobile ? "30%" : "20%",
                      height: "8rem",
                    }}
                  >
                    <a href={image.url} target="_blank">
                      <DocumentPreview url={image.url} docType={"image"} />
                    </a>

                    <span
                      className="absolute right-1 top-1 p-1 rounded-full cursor-pointer"
                      style={{
                        background: "#ff0000de",
                      }}
                      onClick={() => {
                        onRemoveReceiptImage("demand", image.id, true);
                      }}
                    >
                      <FaXmark style={{ color: "white" }} />
                    </span>
                  </div>
                ))}
          </div>
          <span className="text-sm color-sub-text">
            Note: Please submit photos of harvested produce only, not crops on
            the field.
          </span>
        </div>
      </div>
      <div>
        <div className="flex items-center bottom-0 right-0 w-full bg-white py-4 justify-between border-top gap-2">
          <Button
            variant="primaryLight"
            className=""
            text="CANCEL"
            onClick={() => {
              onModalClose(false);
            }}
          />
          <Button
            variant="contained"
            className=""
            onClick={() => {
              submit();
            }}
          >
            SAVE
          </Button>
        </div>
      </div>
    </>
  );
};
export default EditDemandPost;
