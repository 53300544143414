import React, { FC } from "react";
const DocumentViewer: FC<{ docType: "image" | "pdf"; url: string }> = ({
  docType,
  url,
}) => {
  return (
    <>
      {docType === "image" ? (
        <img src={url} />
      ) : docType === "pdf" ? (
        <iframe
          src={url}
          className="h-full w-full"
          style={{ pointerEvents: "none" }}
        ></iframe>
      ) : (
        <></>
      )}
    </>
  );
};
export default DocumentViewer;
