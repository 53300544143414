import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TApplicationViewDetails, TBeneficiaryDetails, TBillsInfo, TBillView, TPdfSettings } from "../types";

type TinitialState = {
  applicationViewDetails: TApplicationViewDetails | null;
  pdfSettings: TPdfSettings[];
  billsView: TBillView | null;
  billsInfo: TBillsInfo | null;
  beneficiaryDetails: TBeneficiaryDetails | null;
  appliViewById: TApplicationViewDetails | null;
  reviewedComment : string;
  verifyComment: string;
  approveComment: string;
  disburseComment: string;
  rejectComment: string;
};
const initialState: TinitialState = {
  applicationViewDetails: null,
  pdfSettings: [],
  billsView: null,
  billsInfo: null,
  beneficiaryDetails: null,
  appliViewById: null,
  reviewedComment: "",
  verifyComment: "",
  approveComment: "",
  disburseComment: "",
  rejectComment: ""
};
const tradeSlice = createSlice({
  name: "loans",
  initialState: initialState,
  reducers: {
    updateApplicationDetails: (
      state,
      action: PayloadAction<{
        applicationViewDetails: TApplicationViewDetails | null;
      }>
    ) => {
      state.applicationViewDetails = action.payload.applicationViewDetails;
    },
    setPdfSettings: (state, action: PayloadAction<TPdfSettings[]>) => {
      state.pdfSettings = action.payload;
    },

    updateBillsView: (
      state,
      action: PayloadAction<{
        billsView: TBillView | null;
      }>
    ) => {
      state.billsView = action.payload.billsView;
    },

    updateBillsInfo: (
      state,
      action: PayloadAction<{
        billsInfo: TBillsInfo | null;
      }>
    ) => {
      state.billsInfo = action.payload.billsInfo;
    },

    updateBeneficiaryDetai: (
      state,
      action: PayloadAction<{
        beneficiaryDetails: TBeneficiaryDetails | null;
      }>
    ) => {
      state.beneficiaryDetails = action.payload.beneficiaryDetails;
    },

    updateAppliViewById: (
      state,
      action: PayloadAction<{
        appliViewById: TApplicationViewDetails | null;
      }>
    ) => {
      state.appliViewById = action.payload.appliViewById;
    },
    setReviewComment: (state, action: PayloadAction<string>) => {
      state.reviewedComment = action.payload;
    },
    setVerifyComment: (state, action: PayloadAction<string>) => {
      state.verifyComment = action.payload;
    },
    setApproveComment: (state, action: PayloadAction<string>) => {
      state.approveComment = action.payload;
    },
    setDisburseComment: (state, action: PayloadAction<string>) => {
      state.disburseComment = action.payload;
    },
    setRejectComment: (state, action: PayloadAction<string>) => {
      state.rejectComment = action.payload;
    },
    clearComments: (state) => {
      state.reviewedComment = '';
      state.verifyComment = "";
      state.approveComment = "";
      state.disburseComment = "";
      state.rejectComment = "";
    },
  },
});

export const { updateApplicationDetails, setPdfSettings, updateBillsView, updateBillsInfo, updateBeneficiaryDetai, updateAppliViewById, setReviewComment, setVerifyComment, setApproveComment, setDisburseComment, setRejectComment, clearComments} = tradeSlice.actions;
export default tradeSlice.reducer;
