import React from "react";
import LoanDetails from "../LoanBankDetails.tsx/LoanDetails";
import BenificiaryDetails from "../Risk-Fraud/BenificiaryDetails";
import CashHistory from "../Cash-History/CashHistory";
import { TApplicationViewDetails } from "../../../../types";

const FinalReview: React.FC<{ finalLoanDetails: TApplicationViewDetails }> = ({
  finalLoanDetails,
}) => {
  return (
    <>
      <div>
        <LoanDetails details={finalLoanDetails} padding={"pb-4"} />
        <BenificiaryDetails details={finalLoanDetails} />
        <CashHistory details={finalLoanDetails} />
      </div>
    </>
  );
};
export default FinalReview;
