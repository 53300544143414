import { CSSProperties, FC, ReactNode } from "react";

const Card: FC<{
  className?: string;
  titile?: string | ReactNode;
  titileClassName?: string;
  children: ReactNode;
  style?: CSSProperties;
}> = ({
  className = "",
  titile,
  titileClassName = "",
  children,
  style = {},
}) => {
  return (
    <>
      <div
        className={`border rounded overflow-hidden bg-white ${className}`}
        style={style}
      >
        {titile && (
          <div
            className={`py-2 px-2 border-b color-primary font-semibold  ${titileClassName}`}
          >
            {titile}
          </div>
        )}
        {children}
      </div>
    </>
  );
};
export default Card;
