import { FC, useState } from "react";

const UserAccountStatusString: FC<{
  statusIcon: { color: string; icon: string; label: string };
}> = ({ statusIcon }) => {
  return (
    <>
      <div className="flex gap-2">
        <img src={statusIcon?.icon} className="w-6 h-6" />
        <h1 className={`text-base font-semibold ${statusIcon.color}`}>
          {statusIcon.label}
        </h1>
      </div>
    </>
  );
};
export default UserAccountStatusString;
