import React, { useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { Input, Slider, Button, TabsList } from "../../components/ui";
import Trades from "../../components/home/trades";
import Supply from "../supply";
import Demand from "../demand";
import { useDispatch, useSelector } from "react-redux";
import {
  updateActiveTradeTab,
  updateCropData,
} from "../../reducers/tradeSlice";
import { TRootState } from "../../store";
import Modal from "../../components/ui/modal";
import ModalHeader from "../../components/ui/modal/modal-header";
import EditSupplyPost from "../supply/edit-supply-post";
import PostSupplyDemand from "../supply/post-supply-demand";
const Home = () => {
  const dispatch = useDispatch();
  // const activeTradeTab = useSelector(
  //   (state: TRootState) => state.trades.activeTradeTab
  // );
  const { activeTradeTab, loginType } = useSelector((state: TRootState) => ({
    activeTradeTab: state.trades.activeTradeTab,
    loginType: state.auth.userInfo?.type,
  }));
  const [step, setStep] = useState(
    activeTradeTab != ""
      ? activeTradeTab
      : loginType == "BAYER"
      ? "SUPPLY"
      : "OPEN"
  );
  console.log(activeTradeTab, "homeee");
  const [searchText, setSearchText] = useState("");
  const [postSupplyDemandModal, setPostSupplyDemandModal] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div className="container mx-auto py-4 px-4 md:px-0">
      <div className="block md:border-b md:flex ">
        {loginType == "BAYER" ? (
          <TabsList
            data={[
              { label: "Supply", value: "SUPPLY" },
              { label: "Demand", value: "DEMAND" },
              { label: "Open trades", value: "OPEN" },
              { label: "Closed trades", value: "CLOSED" },
            ]}
            activeTab={step}
            onChange={(tab, i) => {
              setStep(tab.value);
              dispatch(updateActiveTradeTab(tab.value));
              // setStep(tab.value === "OPEN" ? "OPEN" : "CLOSED");
              // setOnTabOpen(tab.value === "OPEN" ? true : false);
            }}
          />
        ) : (
          <TabsList
            data={[
              { label: "Open trades", value: "OPEN" },
              { label: "Closed trades", value: "CLOSED" },
            ]}
            activeTab={step}
            onChange={(tab, i) => {
              setStep(tab.value);
              dispatch(updateActiveTradeTab(tab.value));
              // setStep(tab.value === "OPEN" ? "OPEN" : "CLOSED");
              // setOnTabOpen(tab.value === "OPEN" ? true : false);
            }}
          />
        )}

        <div className="flex ml-auto gap-5 mt-4 md:mt-0 justify-between md:justify-none">
          <div
            className="flex gap-4 relative"
            style={{ width: isMobile ? "50%" : "" }}
          >
            <FaSearch
              className="absolute color-primary"
              size={15}
              style={{ top: "14px", left: "6px" }}
            />
            <Input
              type="text"
              palceholder="Search by Name / ID"
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value.toLowerCase());
              }}
              inputStyle={{ textIndent: "22px", padding: "9px 5px" }}
            />
            {/* <Button>Record Payment</Button> */}
          </div>
          {step == "SUPPLY" && (
            <Button
              variant="contained"
              className=""
              onClick={() => {
                setPostSupplyDemandModal(true);
                dispatch(
                  updateCropData({
                    selectedCrop: null,
                  })
                );
              }}
            >
              Post Supply
            </Button>
          )}
          {step == "DEMAND" && (
            <Button
              variant="contained"
              className=""
              onClick={() => {
                setPostSupplyDemandModal(true);
                dispatch(
                  updateCropData({
                    selectedCrop: null,
                  })
                );
              }}
            >
              Post Demand
            </Button>
          )}
        </div>
      </div>
      <div>
        <div className="h-full pt-4">
          {step == "SUPPLY" && <Supply searchText={searchText} />}
          {step == "DEMAND" && <Demand searchText={searchText} />}
          {(step == "OPEN" || step == "CLOSED") && (
            <Trades status={step} searchText={searchText} displayMode="table" />
          )}
        </div>
      </div>
      {postSupplyDemandModal && (
        <>
          <Modal
            open={postSupplyDemandModal}
            onClose={() => {
              setPostSupplyDemandModal(false);
            }}
            width={isMobile ? "100% " : "40vw"}
          >
            <>
              <ModalHeader
                title={`Create ${step == "SUPPLY" ? "Supply" : "Demand"} Post`}
                content={<></>}
              />
              <div className="py-3 px-3 md:px-6">
                <PostSupplyDemand
                  onModalClose={setPostSupplyDemandModal}
                  type={step == "SUPPLY" ? "Supply" : "Demand"}
                />
              </div>
            </>
          </Modal>
        </>
      )}
    </div>
  );
};

export default Home;
