import { useEffect, useState } from "react"; 
import {useSelector } from "react-redux";
import { TSmartBoard } from "../../../../types";
import { TRootState } from "../../../../store";
import useAxiosClient from "../../../../hooks/useAxiosClient";

const useSmartboard = ({ 
}) => {
  const { handleGetCall } = useAxiosClient();
  
  const { adminId } = useSelector((state: TRootState) => ({
    adminId:state.auth.adminId
  }));

  const [smartBoard, setSmartBoard] = useState<TSmartBoard | null>();

  useEffect(() => {
    handleGetCall<TSmartBoard>(
      `admin/cash/smart-board/adminId/${adminId}`
    ).then(({ data }) => {
      if (data) {
        setSmartBoard(data);
      } else {
        setSmartBoard(null);
      }
    });
  }, []);
  return { 
    smartBoard,
     setSmartBoard 
  };
};
export default useSmartboard;
