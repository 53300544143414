import { useEffect, useState } from "react"; //hooks
import { useSelector } from "react-redux"; //hook from react redux allows component to extract data from redux store state
import useAxiosClient from "./useAxiosClient"; // custom hook used to making HTTP requests using Axios
//TypeScript types/interfaces used for defining data structures.
import { TTrade } from "../types/index";
import { TRootState } from "../store";
import moment from "moment";
import { toast } from "react-toastify";

const useTrades = ({
  status,
  fromDate,
  toDate,
}: //openStatus,
{
  status: string;
  fromDate: string;
  toDate: string;
  //openStatus: boolean;
}) => {
  const { handleGetCall } = useAxiosClient();
  const { adminId, tententType } = useSelector((state: TRootState) => ({
    adminId: state.auth.adminId,
    tententType: state.auth.userInfo?.type,
  }));
  const [trades, setTrades] = useState<TTrade[]>([]);
  const [refresh, setRefresh] = useState(false);
  // const [hasError, setHasError] = useState(false); // 502 error
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (status === "CLOSED") {
      var closedCalenderDate = JSON.parse(
        localStorage.getItem("closedCalenderDate") ||
          JSON.stringify({
            fromDate: moment().format("YYYY-MM-DD"),
            toDate: moment().format("YYYY-MM-DD"),
          })
      );
      var value = localStorage.getItem("closedDateChange") || "false";
      if (value === "true") {
        fromDate = closedCalenderDate.fromDate;
        toDate = closedCalenderDate.toDate;
      } else {
        fromDate = "";
        toDate = "";
      }
    } else if (status == "OPEN") {
      var openCalenderDate = JSON.parse(
        localStorage.getItem("calenderDate") ||
          JSON.stringify({
            fromDate: moment().format("YYYY-MM-DD"),
            toDate: moment().format("YYYY-MM-DD"),
          })
      );
      var value = localStorage.getItem("dateChange") || "false";
      if (value === "true") {
        fromDate = openCalenderDate.fromDate;
        toDate = openCalenderDate.toDate;
      } else {
        fromDate = "";
        toDate = "";
      }
    }
  });

  useEffect(() => {
    // setIsLoading(true);
    // setHasError(false); // Reset error state before API call
    handleGetCall<TTrade[]>(
      `/admin/trade-platform/trades/adminId/${adminId}/tradeStatus/${status}?fromDate=${fromDate}&toDate=${toDate}&tenant=${tententType}&tradeStatus=${status}`
    ).then(({ data }) => {
      if (data) {
        setTrades(data);
         setIsLoading(false);
      } else {
        setTrades([]);
       setIsLoading(false);
      }
    })
    // .catch((error) => {
    //   if (error.response && error.response.status === 502) {
    //     setHasError(true); 
    //     toast.error("Bad Gateway: The server is unavailable. Please try again later.");
    //   }
    // })
    // .finally(() => {
    //   setIsLoading(false);
    // });
  }, [status, fromDate, toDate, refresh]);

  return { trades, refresh, setRefresh, isLoading, setIsLoading };
};
export default useTrades;
