import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import useAxiosClient from "../../hooks/useAxiosClient";
import { TRootState } from "../../store";
import {
  TCrop,
  TDestinationPartner,
  TSupplyDemandReq,
  TTraderNotifications,
} from "../../types";
import {
  updateCropData,
  updatePartyData,
  updateSupplyDetails,
} from "../../reducers/tradeSlice";

const useSupplyDetailsById = ({ id, type }: { id: number; type?: string }) => {
  const { handleGetCall, handlePostCall } = useAxiosClient();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { adminId, role, InitSupplyDetail, tententType } = useSelector(
    (state: TRootState) => ({
      adminId: state.auth.adminId,
      role: state.auth.userInfo?.roleType,
      InitSupplyDetail: state.trades.supplyDetailsById,
      tententType: state.auth.userInfo?.type,
    })
  );
  const [supplyDemandDetails, setSupplyDemandDetails] =
    useState<TSupplyDemandReq | null>(InitSupplyDetail);

  const [searchText, setSearchText] = useState("");
  const getSupplyDetail = () => {
    handleGetCall<TSupplyDemandReq>(
      `admin/demand-supply/supply/supplyId/${id}`
    ).then(({ data }) => {
      if (data) {
        let cropsData: TCrop;
        let selectedParty = {
          addrLine: data?.addressLine,
          altMobile: data?.altMobile,
          city: data?.city,
          traderId: data?.traderId,
          marketName: "",
          mobile: data?.mobile,
          name: data?.traderName,
          shopNum: "",
          tradeName: "",
          relMgr: "",
          rmMobile: "",
          status: "",
        };
        if (data.cropName) {
          cropsData = {
            baseName: "",
            cropId: data?.cropId || 0,
            cropName: data?.cropName || "",
            imageUrl: data?.cropUrl || "",
            type: "",
            units: "",
            varietyName: "",
            qtyUnit: "",
            rateType: "",
            qty: data?.qty || "",
            weight: "",
            freightAmt: "",
            status: 1,
            tradeId: 0,
            weightUnit: "",
            id: 0,
            addWastage: "",
            destWeight: "",
            destRate: "",
            rate: data?.expRate || "",
            unitType: "",
          };
          dispatch(
            updateCropData({
              //   selectedParty: selectedParty,
              selectedCrop: cropsData,
            })
          );
        } else {
          dispatch(
            updateCropData({
              //  selectedParty: selectedParty,
              selectedCrop: null,
            })
          );
        }

        setSupplyDemandDetails({
          ...data,
          quality: data?.quality ? data?.quality.toString().toUpperCase() : "",
          traderNotifns: data?.traderNotifns ? data?.traderNotifns : [],
          //   totalBillsAmount: data?.buyBillAmt + data?.sellBillAmt,
        });
        dispatch(
          updateSupplyDetails({
            supplyDetailsById: {
              ...data,
              quality: data?.quality
                ? data?.quality.toString().toUpperCase()
                : "",
              traderNotifns: data?.traderNotifns ? data?.traderNotifns : [],
              //   totalBillsAmount: data?.buyBillAmt + data?.sellBillAmt,
            },
          })
        );
      } else {
        setSupplyDemandDetails(null);
      }
    });
  };

  const [selectedBills, setSelectedBills] = useState<TTraderNotifications[]>(
    []
  );
  const [selectAll, setSelectAll] = useState(false);
  // Toggle all bills
  const handleSelectAll = () => {
    let allBills =
      supplyDemandDetails?.traderNotifns &&
      supplyDemandDetails?.traderNotifns.length > 0
        ? supplyDemandDetails?.traderNotifns
        : [];
    allBills = supplyDemandDetails?.traderNotifns
      ? supplyDemandDetails?.traderNotifns.filter(
          (partner) => partner.status !== "SENT"
        )
      : [];

    if (!selectAll) {
      if (searchText) {
        let searchTextLower = searchText.toLowerCase();
        let filteredBuyers = allBills.filter((partner) => {
          if (
            (partner.traderName &&
              partner.traderName.toLowerCase().includes(searchTextLower)) ||
            (partner.traderId &&
              partner.traderId.toString().includes(searchTextLower))
          ) {
            return true;
          } else {
            return false;
          }
        });
        setSelectedBills(filteredBuyers);
        setSelectAll(true);
      } else {
        setSelectedBills(allBills);
        setSelectAll(true);
      }
    } else {
      setSelectAll(false);
      setSelectedBills([]); // Select all (store entire bill objects)
    }
  };
  function handleCheckboxChange(info: any) {
    setSelectedBills((prev: any) => {
      const isSelected = prev.some(
        (selected: any) => selected.traderId === info.traderId
      );
      if (isSelected) {
        return prev.filter(
          (selected: any) => selected.traderId !== info.traderId
        );
      } else {
        if (info.status !== "CANCELLED" && info.billStatus !== "status") {
          return [...prev, info];
        } else {
          return prev;
        }
        // return [...prev, info];
      }
    });
  }

  const sendNotificationsEvent = async () => {
    if (selectedBills.length == 0) {
      toast.error("Please select  ");
      return;
    }

    try {
      let postBody = {
        id: 0,
        supDmdId: supplyDemandDetails?.id,
        traderDtls:
          selectedBills.length > 0 &&
          selectedBills.map((item) => {
            return {
              account: supplyDemandDetails?.mlcMobile,
              altMobile: item?.altMobile,
              mobile: item?.mobile,
              rmMobile: "",
              tradeId: 0,
              traderId: item.traderId,
              traderSeq: 0,
            };
          }),
        type: type,
      };

      await handlePostCall<any>({
        URL: `admin/demand-supply/notify/adminId/${adminId}`,
        apiParams: postBody,
      })
        .then(({ data, status }) => {
          toast.success(status.message);
          navigate(`/supply-details/${id}`);
          setSelectedBills([]);
        })
        .catch((err: any) => {
          toast.error(err.response.data.status.message);
        });
    } catch (ex) {}
  };
  return {
    supplyDemandDetails,
    getSupplyDetail,
    role,
    handleCheckboxChange,
    handleSelectAll,
    selectedBills,
    searchText,
    setSearchText,
    sendNotificationsEvent,
    selectAll,
  };
};
export default useSupplyDetailsById;
