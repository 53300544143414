import { FC } from "react";
import { TQuantityUnit, TRateType } from "../../../../types";
import { qtyValues } from "../../../../helpers/getText";
const Quantity: FC<{
  quantity: number;
  unit: TQuantityUnit;
  wastage: number;
  weight: number;
  rateType: TRateType;
  className?: string;
}> = ({ quantity, unit, wastage, weight, rateType, className = "" }) => {
  return (
    <span className={`text-sm ${className}`}>
      {/* {quantity} {QUANTITY[unit].short_name}
       */}
      {qtyValues(quantity, unit, weight, rateType)}
    </span>
  );
};
export default Quantity;
