import { useParams } from 'react-router-dom';
import loanReject from '../../../../assets/images/rejectLoan.svg'
import { Button } from '../../../../components/ui';
import useApplicationViewById from '../application-view/useApplicationViewById';
const RejectLoanModal: React.FC<{
    onClose: () => void;
}> = ({ onClose }) => {
    const params = useParams();
    const {
        rejectStepEvent,
    } = useApplicationViewById({
        applicationId: parseInt(params.applicationId || "0"),
    });
    return (
        <>
            <div className="flex items-center justify-center">
                <div className="flex flex-col items-center px-4 py-4">
                    <img src={loanReject} alt="loan disbursed" className="mb-4" />
                    <span className="text-lg font-bold text-center">
                        You are about to reject the loan. Do you want to proceed?
                    </span>
                    <div className="flex mt-4">
                        <Button
                            text="NO"
                            className="w-[100px] mx-[10px]"
                            variant="primaryLight"
                            onClick={() => onClose()}
                        />
                        <Button
                            text="YES"
                            className="w-[100px] mx-[10px]"
                            onClick={() =>{
                            onClose()
                            rejectStepEvent()
                        }}
                        />
                    </div>
                </div>
            </div>

        </>
    )
}
export default RejectLoanModal;