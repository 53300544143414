import { FC } from "react";
import defultProfilePic from "../../assets/images/single_bill.svg";
import { getMaskedMobileNumber } from "../../helpers/get-currency-number";
import { getPartnerType } from "../../helpers/getText";
import { TPartnerInfo } from "../../types";

const PartnerInfo: FC<TPartnerInfo> = ({
  profilePic,
  name,
  shortName,
  partnerType,
  partnerId,
  partnerMobile,
  trader = false,
  partnetAddress = "",
  partyName,
  fromLoansParty = false,
  distanceFrom,
  distance,
}) => {
  return (
    <div className="flex items-center gap-x-3">
      <div>
        <img
          src={profilePic || defultProfilePic}
          alt="user_profile_pic"
          className="w-8 h-8 br-100"
          style={{ maxWidth: "initial" }}
        />
      </div>
      <div className="flex flex-col">
        <span className="text-sm font-bold">
          {name} {shortName ? "-" + shortName : ""}
        </span>
        <span className="text-xs font-medium">
          {(fromLoansParty || partnerType) ? partnerType : "Trader"}
          {partnerId !== "" ? `- ${partnerId}` : ``}
          {partnerMobile !== "" && !distanceFrom
            ? ` | ${getMaskedMobileNumber(partnerMobile)}` || ""
            : distance
            ? ` | Distance ${distance} km`
            : ""}
        </span>
        {partnetAddress && (
          <p className="text-sm font-semibold">{partnetAddress}</p>
        )}
        <p className="text-xs">{partyName}</p>
      </div>
    </div>
  );
};
export default PartnerInfo;
