import React, { useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  Title,
  BarController,
  LineController,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import useApplicationViewById from "../application-view/useApplicationViewById";
import { useParams } from "react-router-dom";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  Title,
  BarController,
  LineController
);

const TransactionChart: React.FC = () => {
  const params = useParams();

  const {
    getApplicationDetail,
    applicationViewDetails,
    setApplicationViewDetails,
  } = useApplicationViewById({
    applicationId: parseInt(params.applicationId || "0"),
  });

  const getMonths = () => {
    return applicationViewDetails?.monthlyData?.map((month) => month.monthYear);
  };

  const getBills = () => {
    return applicationViewDetails?.monthlyData?.map(
      (month) => month.totalBills
    );
  };
  const getMaxCount = () => {
    // let bills = applicationViewDetails?.monthlyData?.map((month)=>Number(month.totalBills));

    // let bills = applicationViewDetails
    //   ? applicationViewDetails?.monthlyData?.map((month) =>
    //       Number(month.totalBills)
    //     )
    //   : [];
    //   return Math.max(...bills!);

    const bills =
      applicationViewDetails?.monthlyData?.map(
        (month) => Number(month?.totalBills) || 0
      ) || [];

    return bills.length > 0 ? Math.max(...bills) : 0;
  };

  const data = {
    labels: getMonths(),
    datasets: [
      {
        type: "bar" as const,
        label: "Bills Created",
        data: getBills(),
        backgroundColor: "#16A02CD6",
        barThickness: 20,
      },
      {
        type: "line" as const,
        label: "Trend Line",
        data: getBills(),
        borderColor: "#DC2626",
        borderWidth: 2,
        pointBackgroundColor: "rgba(220, 38, 38, 0.8)",
        fill: false,
      },
    ],
  };

  const options = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        max: getMaxCount(),
        ticks: {
          stepSize: Number(getMaxCount() / 5),
          color: "#4B5563",
        },
        grid: {
          color: "#E5E7EB",
        },
      },
      x: {
        ticks: {
          color: "#4B5563",
        },
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: true,
      },
    },
  };

  useEffect(() => {
    getApplicationDetail();
  }, [params]);

  return (
    <div className="p-6 border rounded-lg shadow-lg bg-white w-auto h-auto">
      <Chart type="bar" data={data} options={options} />
    </div>
  );
};

export default TransactionChart;
