import { FC } from "react";
import { TTableHeader } from "../../../../types";

const BillTableHeader: FC<{ data: TTableHeader }> = ({ data = [] }) => {
  return (
    <div className="flex w-full bg-light-primary py-2 rounded-t-xl border-1">
      {data.map((item, i) => (
        <span
          key={`column-${i}`}
          className="flex font-medium color-text-primary basis-0 px-2"
          style={{ flexGrow: item.colWidth, fontSize: "0.75rem" }}
        >
          {item.data ? (
            <>
              {item.data.map((subitem, j) => (
                <span
                  className="font-medium color-text-primary basis-0 px-2"
                  key={`column-${i}-${j}`}
                  style={{ flexGrow: subitem.colWidth, fontSize: "0.75rem" }}
                >
                  {subitem.label}
                </span>
              ))}
            </>
          ) : (
            <>{item.label}</>
          )}
        </span>
      ))}
    </div>
  );
};
export default BillTableHeader;
