import { ChangeEvent, FC, useEffect, useState } from "react";
import { IoMdCloseCircleOutline, IoMdSearch } from "react-icons/io";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import PartnerInfo from "../../components/partner-info";
import SelectCrop from "../../components/select-crop";
import { Button, FileUploader, Input, RadioButton } from "../../components/ui";
import SelectDate from "../../components/ui/select-date";
import PartyDropDown from "../../components/ui/select-party";
import { OnoSelect } from "../../components/ui/select2";
import { updateCropData } from "../../reducers/tradeSlice";
import "./style.module.scss";
import { FaXmark } from "react-icons/fa6";
import DocumentPreview from "../../components/commons/document-preview";
import StyledRadioButton from "../../components/ui/styledRadioButton";
import call from "../../assets/images/call.svg";
import useCreateTradeSupplyDEmand from "../../hooks/useCreateTrade";
import moment from "moment";
import { toCamelCaseWord } from "../../helpers/getText";
const AssignOffTaker: FC<{
  onModalClose: any;
  type: string;
  fromPropsal?: boolean;
  onFalsePropsal?: any;
}> = ({ onModalClose, type, fromPropsal, onFalsePropsal }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const [showTransporterOption, setShowTransporterOptions] = useState(false);
  const [showSrcOption, setShowSrcOption] = useState(false);
  const {
    details,
    handleDateSelection,
    setDetails,
    submit,
    selectedDate,
    setSeletedPartner,
    setSeletedSrc,
    selectedCrop,
    selectedTrader,
    partyData,
    handelSearch,
    clearSearch,
    searchText,
    traderData,
    selectedPartner,
    selectedSrc,
    setTraderData,
    traderContactInfo,
    tapalInvoice,
    receipts,
    otherDocuments,
    onUploadImage,
    onRemoveImage,
  } = useCreateTradeSupplyDEmand({
    id: parseInt(params.postId || "0"),
    modalClose: onModalClose,
    type: type,
    fromPropsal: fromPropsal,
  });
  useEffect(() => {
    if (fromPropsal) {
      dispatch(
        updateCropData({
          selectedCrop: {
            ...selectedCrop!,
            rate: traderContactInfo?.rate || "",
            qtyUnit: "BAGS",
            weightUnit: "RATE_PER_KG",
            weight: details?.qty || 0,
            unitType: details?.unitType ? details?.unitType.toString() : "",
            qty: 0,
          },
        })
      );
      setSeletedPartner({
        ...selectedPartner!,
        addrLine: traderContactInfo?.addressLine || "",
        altMobile: traderContactInfo?.altMobile || "",
        city: "",
        traderId: traderContactInfo?.traderId || 0,
        mobile: traderContactInfo?.mobile || "",
        name: "",
        tradeName: traderContactInfo?.traderName || "",
        type: "DEST",
      });
    }
  }, [fromPropsal]);
  const onChangeRate = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(
      updateCropData({
        selectedCrop: {
          ...selectedCrop!,
          rate: e.target.value !== "" ? parseFloat(e.target.value) : "",
        },
      })
    );
    var weight = selectedCrop?.weight;
    if (
      selectedCrop?.weightUnit == "RATE_PER_KG" &&
      selectedCrop != null &&
      selectedCrop?.weight
    ) {
      if (selectedCrop?.unitType == "QUINTAL") {
        weight = selectedCrop?.weight * 100;
      } else if (selectedCrop?.unitType == "MT/TON") {
        weight = selectedCrop?.weight * 1000;
      } else {
        weight = selectedCrop?.weight;
      }
    }
    if (
      selectedCrop != null &&
      selectedCrop?.weight &&
      selectedCrop?.weightUnit == "RATE_PER_KG"
    ) {
      setDetails({
        ...details!,
        cmdityCost: parseFloat(
          (parseFloat(e.target.value) * Number(weight)).toFixed(2)
        ),
      });
    } else if (selectedCrop?.weightUnit == "RATE_PER_UNIT") {
      setDetails({
        ...details!,
        cmdityCost: parseFloat(
          (parseFloat(e.target.value) * Number(selectedCrop?.qty)).toFixed(2)
        ),
      });
    }
  };
  const onChangeCost = (e: ChangeEvent<HTMLInputElement>) => {
    setDetails({
      ...details!,
      cmdityCost: e.target.value !== "" ? parseFloat(e.target.value) : "",
    });
    if (
      selectedCrop != null &&
      selectedCrop?.weight &&
      selectedCrop?.weightUnit == "RATE_PER_KG"
    ) {
      dispatch(
        updateCropData({
          selectedCrop: {
            ...selectedCrop!,
            rate: parseFloat(
              (
                parseFloat(e.target.value) / Number(selectedCrop?.weight)
              ).toFixed(2)
            ),
          },
        })
      );
    } else if (selectedCrop?.weightUnit == "RATE_PER_UNIT") {
      dispatch(
        updateCropData({
          selectedCrop: {
            ...selectedCrop!,
            rate: parseFloat(
              (parseFloat(e.target.value) / Number(selectedCrop?.qty)).toFixed(
                2
              )
            ),
          },
        })
      );
    }
  };
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Or use any breakpoint you prefer
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
      <div
        className="pb-3 overflow-y-auto"
        style={{
          height: isMobile ? "calc(100vh - 18rem)" : "calc(100vh - 22rem)",
        }}
      >
        <div className={`border rounded overflow-hidden bg-white br-10 `}>
          <div className={`py-2 px-2 bg-light-primary border-b font-medium  `}>
            {type == "SUPPLY" ? "Seller" : "Buyer"} Information
          </div>

          <div className="px-2 md:px-3">
            <div className="py-3 border-b block md:flex justify-between">
              <PartnerInfo
                profilePic={details?.profilePic || ""}
                name={details?.traderName || ""}
                shortName={""}
                partnerType={"Trader"}
                partnerId={details?.traderId}
                partnerMobile={details?.mobile || ""}
                partyName={details?.addressLine}
              />
              <div className="flex gap-4 md:gap-8 pt-3 md:pt-0 justify-between md:justify-none">
                <div className="flex gap-1 md:gap-4 items-center">
                  <img src={call} className="w-8 h-8 " />
                  <div className="flex flex-col">
                    <span className="text-sm">Mobile Mobile </span>
                    <span>
                      <span className="text-sm font-semibold">
                        {details?.mobile || "-"}
                      </span>
                    </span>
                  </div>
                </div>
                <div className="flex gap-1 md:gap-4 items-center">
                  <img src={call} className="w-8 h-8 " />
                  <div className="flex flex-col">
                    <span className="text-sm">Alternative Mobile </span>
                    <span>
                      <span className="text-sm font-semibold">
                        {details?.altMobile || "-"}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="py-3">
              <div
                className=" block md:flex justify-between "
                style={{ width: "100%" }}
              >
                <div
                  className="grow-[3] basis-0 flex flex-col border-b pb-2 md:border-none md:pb-0"
                  style={{ width: isMobile ? "100%" : "30%" }}
                >
                  <div className="flex items-center gap-x-3">
                    <div>
                      <img
                        src={details?.cropUrl}
                        alt=""
                        className="br-100 w-8 h-8 "
                      />
                    </div>
                    <div className="flex flex-col">
                      <span className="text-sm font-bold">
                        {details?.cropName}
                      </span>
                      <span className="text-xs font-medium">
                        {details?.quality
                          ? toCamelCaseWord(details?.quality) + " Quality"
                          : "-"}
                      </span>

                      <span className="text-xs">
                        Post ID :{" "}
                        <span className="color-blue">{details?.id}</span> |{" "}
                        {details?.timeAgo}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex md:hidden border-b py-2 md:border-none md:py-0">
                  <div
                    className="grow-[2] basis-0 flex px-2 flex-col"
                    style={{ width: "20%" }}
                  >
                    <span className="text-sm">Quantity</span>
                    <span className="text-sm font-semibold">
                      {" "}
                      {details?.qty}
                      {details?.unitType}
                    </span>
                  </div>
                  <div
                    className="grow-[2] basis-0 flex px-2 flex-col"
                    style={{ width: "20%" }}
                  >
                    <span className="text-sm">
                      {type == "SUPPLY" ? "Expected" : "Offer"} Rate
                    </span>
                    <span className="font-semibold text-sm ">
                      Rs.{details?.expRate} Per KG
                    </span>
                  </div>
                </div>
                <div
                  className="grow-[2] basis-0 hidden md:flex px-2 flex-col "
                  style={{ width: "20%" }}
                >
                  <span className="text-sm">Quantity</span>
                  <span className="text-sm font-semibold">
                    {" "}
                    {details?.qty}
                    {details?.unitType}
                  </span>
                </div>
                <div
                  className="grow-[2] basis-0 hidden md:flex px-2 flex-col"
                  style={{ width: "20%" }}
                >
                  <span className="text-sm">
                    {type == "SUPPLY" ? "Expected" : "Offer"} Rate
                  </span>
                  <span className="font-semibold text-sm ">
                    Rs.{details?.expRate} Per KG
                  </span>
                </div>
                <div
                  className="grow-[2] basis-0 flex px-2 flex-col border-b py-2 md:border-none md:py-0"
                  style={{ width: isMobile ? "100%" : "20%" }}
                >
                  <span className="text-sm">
                    {type == "SUPPLY" ? "Availability" : "Expected"} Date
                  </span>
                  <span className="text-sm font-semibold">
                    {type == "SUPPLY"
                      ? moment(details?.avlDate).format("DD-MMM-YYYY") || "-"
                      : moment(details?.expDate).format("DD-MMM-YYYY") || "-"}
                  </span>
                </div>
                {type == "DEMAND" && (
                  <div
                    className="grow-[2] basis-0 flex px-2 flex-col py-2  md:py-0"
                    style={{ width: isMobile ? "100%" : "20%" }}
                  >
                    <span className="text-sm">Offer Validity</span>
                    <span className="text-sm font-semibold">
                      {moment(details?.offerValidity).format("DD-MMM-YYYY") ||
                        "-"}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={`border rounded overflow-hidden bg-white br-10 mt-4`}>
          <div className={`py-2 px-2 bg-light-primary border-b font-medium  `}>
            {type == "SUPPLY" ? " Assign Buyer" : "Assign Seller"}
          </div>

          <div className="px-3 pb-4">
            <div className="pt-3 w-full flex gap-4">
              <div className="grow-[3]">
                <label className="text-sm">
                  Trade Type <span className="color-red">{"*"}</span>
                </label>
                <StyledRadioButton
                  displayType="horizontal"
                  data={[
                    { label: "Order Mode", value: "ORDER" },
                    { label: "Commission Mode", value: "COMM" },
                  ]}
                  value={details?.tradeMode || ""}
                  pointer={{ label: "label", value: "value" }}
                  onChange={(value) => {
                    setDetails({
                      ...details!,
                      tradeMode: value || "",
                    });
                  }}
                  styles={{ display: "flex", gap: "10px" }}
                />
              </div>
            </div>
            <div className="pt-3 w-full block md:flex gap-4">
              <div className="grow-[3]">
                <span className="text-sm">
                  {type == "SUPPLY" ? "Select Buyer" : "Select Seller"}
                  <span className="color-red">*</span>
                </span>
                {fromPropsal ? (
                  <div
                    className={`relative flex items-center shrink-0 px-2 py-2 border br-10 mt-2`}
                    style={{ borderRightWidth: "1px" }}
                  >
                    <div
                      className={`flex items-center cursor-pointer w-full`}
                      style={{ height: "53px" }}
                    >
                      <PartnerInfo
                        profilePic=""
                        name={selectedPartner?.tradeName || ""}
                        shortName={""}
                        partnerType={"Trader"}
                        partnerId={selectedPartner?.traderId}
                        partnerMobile={selectedPartner?.mobile || ""}
                        partyName={selectedPartner?.addrLine}
                      />
                    </div>
                  </div>
                ) : (
                  <div
                    className={`relative flex items-center shrink-0 px-2 py-2 border br-10 mt-2`}
                    style={{ borderRightWidth: "1px" }}
                  >
                    <div
                      className={`flex items-center cursor-pointer w-full`}
                      style={{ height: "53px" }}
                      onClick={() => {
                        setShowTransporterOptions(true);
                      }}
                    >
                      {selectedPartner != null ? (
                        <PartnerInfo
                          profilePic=""
                          name={selectedPartner.tradeName}
                          shortName={""}
                          partnerType={"Trader"}
                          partnerId={selectedPartner.traderId}
                          partnerMobile={selectedPartner.mobile}
                          partyName={selectedPartner.name}
                        />
                      ) : (
                        <div className={`relative w-full `}>
                          <input
                            className="w-full px-5 cursor-pointer"
                            placeholder="Search by Trade Name / Trade Id"
                            value={searchText}
                            onChange={handelSearch}
                          />
                          <IoMdSearch
                            className="absolute color-primary"
                            style={{
                              top: ".3rem",
                              left: "0rem",
                              fontSize: "1.2rem",
                            }}
                          />
                        </div>
                      )}
                    </div>
                    {(searchText !== "" || selectedPartner != null) && (
                      <IoMdCloseCircleOutline
                        onClick={() => {
                          clearSearch("DEST");
                        }}
                        className="absolute color-primary cursor-pointer w-10"
                        style={{
                          top: "32%",
                          right: "1rem",
                          fontSize: "1.2rem",
                          color: "gray",
                          cursor: "pointer",
                        }}
                      />
                    )}
                    <PartyDropDown
                      open={showTransporterOption}
                      onChange={(trader) => {
                        setShowTransporterOptions(false);
                        dispatch(
                          updateCropData({
                            selectedParty: trader,
                            selectedCrop: selectedCrop,
                          })
                        );
                        setSeletedPartner(trader);
                        setTraderData(partyData);
                      }}
                      allTraders={traderData}
                      selectedPartner={selectedPartner}
                      onClose={() => {
                        setShowTransporterOptions(false);
                      }}
                    />
                  </div>
                )}
              </div>
              <div className="grow-[3] pt-3 md:pt-0">
                <span className="text-sm">
                  Select Crop <span className="color-red">*</span>
                </span>
                <div className="grow overflow-auto scrollbar mt-2">
                  <SelectCrop status="true" />
                </div>
              </div>
              <div className="grow-[3] pt-3 md:pt-0">
                <span className="text-sm">
                  Pickup Date <span className="color-red">*</span>
                </span>
                <div className="mt-2">
                  <SelectDate
                    onChange={handleDateSelection}
                    selectedDate={selectedDate}
                    statusStyle={true}
                    maxDate={moment().add(2, "weeks").format("YYYY-MM-DD")}
                  />
                </div>
              </div>
            </div>
            <div className="pt-3 block md:flex w-full gap-4">
              <div className="grow-[3]">
                <span className="text-sm">Total Quantity</span>
                <div className="mt-2 relative">
                  <Input
                    label=""
                    type="number"
                    required={true}
                    value={selectedCrop?.qty}
                    onFocus={(e) => {
                      e.target.value === "0" &&
                        dispatch(
                          updateCropData({
                            selectedCrop: {
                              ...selectedCrop!,
                              qty: "",
                            },
                          })
                        );
                    }}
                    onChange={(e) => {
                      dispatch(
                        updateCropData({
                          selectedCrop: {
                            ...selectedCrop!,
                            qty:
                              e.target.value !== ""
                                ? parseFloat(e.target.value)
                                : "",
                            weight:
                              selectedCrop?.qtyUnit == "KGS"
                                ? parseFloat(e.target.value)
                                : selectedCrop?.weight!,
                          },
                        })
                      );
                    }}
                  />
                  {/* {selectedCrop != null && ( */}
                  <>
                    <div
                      className="absolute cursor-pointer border-l pl-2 flex justify-center text-sm"
                      style={{
                        top: ".6rem",
                        right: "1rem",
                        cursor: "pointer",
                        width: "6rem",
                      }}
                    >
                      <div style={{ width: "100px" }}>
                        <OnoSelect
                          value={selectedCrop?.qtyUnit}
                          options={[
                            { label: "CRATES", value: "CRATES" },
                            { label: "BAGS", value: "BAGS" },
                            { label: "SACS", value: "SACS" },
                            { label: "BOXES", value: "BOXES" },
                            { label: "KGS", value: "KGS" },
                            { label: "LOADS", value: "LOADS" },
                            { label: "PIECES", value: "PIECES" },
                          ]}
                          onChange={(e) => {
                            dispatch(
                              updateCropData({
                                selectedCrop: {
                                  ...selectedCrop!,
                                  qtyUnit: e.value,
                                  weight:
                                    e.value == "KGS"
                                      ? selectedCrop?.qty || ""
                                      : selectedCrop?.weight || "",
                                },
                              })
                            );
                          }}
                        />
                      </div>
                    </div>
                  </>
                  {/* )} */}
                </div>
              </div>
              <div className="grow-[3] pt-3 md:pt-0">
                <span className="text-sm">
                  Total Weight<span className="color-red">*</span>
                </span>
                <div className="mt-2 relative">
                  {selectedCrop?.qtyUnit == "KGS" ? (
                    <Input
                      label=""
                      type="number"
                      required={true}
                      value={selectedCrop?.weight}
                    />
                  ) : (
                    <Input
                      label=""
                      type="number"
                      required={true}
                      value={selectedCrop?.weight}
                      onFocus={(e) => {
                        e.target.value === "0" &&
                          dispatch(
                            updateCropData({
                              selectedCrop: {
                                ...selectedCrop!,
                                weight: "",
                              },
                            })
                          );
                      }}
                      onChange={(e) => {
                        dispatch(
                          updateCropData({
                            selectedCrop: {
                              ...selectedCrop!,
                              weight:
                                e.target.value !== ""
                                  ? parseFloat(e.target.value)
                                  : "",
                            },
                          })
                        );
                      }}
                    />
                  )}
                  {selectedCrop != null && (
                    <div
                      className="absolute cursor-pointer border-l pl-2 flex justify-center text-sm"
                      style={{
                        top: ".6rem",
                        right: "1rem",
                        cursor: "pointer",
                        width: "6rem",
                      }}
                    >
                      <div style={{ width: "100px" }}>
                        <OnoSelect
                          value={selectedCrop?.unitType}
                          options={[
                            { label: "KGS", value: "KGS" },
                            {
                              label: "QUINTAL",
                              value: "QUINTAL",
                            },
                            {
                              label: "MT/TON",
                              value: "MT/TON",
                            },
                          ]}
                          onChange={(e) => {
                            dispatch(
                              updateCropData({
                                selectedCrop: {
                                  ...selectedCrop,
                                  unitType: e.value,
                                  // weight:
                                  //   e.value == "KGS"
                                  //     ? selectedCrop?.qty
                                  //     : selectedCrop?.weight,
                                },
                              })
                            );
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {details?.tradeMode == "ORDER" && (
                <div className="grow-[3] pt-3 md:pt-0">
                  <span className="text-sm">
                    Rate<span className="color-red">*</span>
                  </span>
                  <div className="mt-2 relative">
                    <Input
                      label=""
                      type="number"
                      required={true}
                      value={selectedCrop?.rate}
                      onFocus={(e) => {
                        e.target.value === "0" &&
                          dispatch(
                            updateCropData({
                              selectedCrop: {
                                ...selectedCrop!,
                                rate: "",
                              },
                            })
                          );
                      }}
                      onChange={(e) => {
                        onChangeRate(e);
                      }}
                    />
                    {selectedCrop != null && (
                      <>
                        <div
                          className="absolute cursor-pointer border-l pl-2 flex justify-center text-sm"
                          style={{
                            top: ".6rem",
                            right: "1rem",
                            cursor: "pointer",
                            width: "8rem",
                          }}
                        >
                          <OnoSelect
                            value={selectedCrop?.weightUnit}
                            options={[
                              { label: "Rate per KG", value: "RATE_PER_KG" },
                              {
                                label: "Rate per UNIT",
                                value: "RATE_PER_UNIT",
                              },
                            ]}
                            onChange={(e) => {
                              dispatch(
                                updateCropData({
                                  selectedCrop: {
                                    ...selectedCrop,
                                    weightUnit: e.value,
                                    // weight:
                                    //   e.value == "KGS"
                                    //     ? selectedCrop?.qty
                                    //     : selectedCrop?.weight,
                                  },
                                })
                              );
                            }}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className="pt-3 block md:flex w-full gap-4">
              {details?.tradeMode == "ORDER" && (
                <div className="grow-[3]">
                  <span className="text-sm">
                    Commodity Cost <span className="color-red">*</span>
                  </span>
                  <div className="mt-2 relative">
                    <Input
                      label=""
                      type="number"
                      required={true}
                      value={details?.cmdityCost}
                      onFocus={(e) => {
                        e.target.value === "0" &&
                          setDetails({
                            ...details!,
                            cmdityCost: "",
                          });
                      }}
                      onChange={(e) => {
                        onChangeCost(e);
                      }}
                    />
                  </div>
                </div>
              )}

              <div className="grow-[3] pt-3 md:pt-0">
                <span className="text-sm">Freight Amount</span>
                <div className="mt-2 relative">
                  <Input
                    label=""
                    type="number"
                    required={true}
                    value={details?.freightAmt}
                    onFocus={(e) => {
                      e.target.value === "0" &&
                        setDetails({
                          ...details!,
                          freightAmt: "",
                        });
                    }}
                    onChange={(e) => {
                      setDetails({
                        ...details!,
                        freightAmt:
                          e.target.value !== ""
                            ? parseFloat(e.target.value)
                            : "",
                      });
                    }}
                  />
                </div>
              </div>
              {details?.tradeMode == "ORDER" && (
                <div className="grow-[3] pt-3 md:pt-0">
                  <span className="text-sm">
                    Invoice Amount<span className="color-red">*</span>
                  </span>
                  <div className="mt-2 relative">
                    <Input
                      label=""
                      type="number"
                      required={true}
                      value={details?.invAmt}
                      disabled={true}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="pt-3 block md:flex w-full gap-4">
              <div className="grow-[3]">
                <span className="text-sm">Freight Advance</span>
                <div className="mt-2 relative">
                  <Input
                    label=""
                    type="number"
                    required={true}
                    value={details?.freightAdv}
                    onFocus={(e) => {
                      e.target.value === "0" &&
                        setDetails({
                          ...details!,
                          freightAdv: "",
                        });
                    }}
                    onChange={(e) => {
                      setDetails({
                        ...details!,
                        freightAdv:
                          e.target.value !== ""
                            ? parseFloat(e.target.value)
                            : "",
                      });
                    }}
                  />
                </div>
              </div>
              <div className="grow-[3] pt-3 md:pt-0">
                <span className="text-sm">Balance Freight</span>
                <div className="mt-2 relative">
                  <Input
                    label=""
                    type="number"
                    required={true}
                    value={details?.balFreight}
                    disabled={true}
                  />
                </div>
              </div>
              <div className="grow-[3] pt-3 md:pt-0">
                <span className="text-sm">Truck Number</span>
                <div className="mt-2 relative">
                  <Input
                    label=""
                    type="text"
                    required={true}
                    value={details?.srcVehNum}
                    onFocus={(e) => {
                      e.target.value === "0" &&
                        setDetails({
                          ...details!,
                          srcVehNum: "",
                        });
                    }}
                    onChange={(e) => {
                      setDetails({
                        ...details!,
                        srcVehNum: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="pt-3 block md:flex w-full gap-4">
              <div
                className="grow-[3]"
                style={{ width: isMobile ? "100%" : "50%" }}
              >
                <h2 className="color-text-primary pb-2 pt-2 text-sm">
                  Tapal / Invoice<span className="color-red">*</span>
                </h2>
                <div className="flex gap-2 overflow-auto">
                  <div className="shrink-0 overflow-hidden p-2">
                    <FileUploader
                      label=""
                      onChange={(e) => {
                        onUploadImage(
                          "tapal",
                          e.target.files ? e.target.files[0] : null
                        );
                      }}
                    />
                  </div>

                  {tapalInvoice.length > 0 &&
                    tapalInvoice.map((image, i) => (
                      <div
                        className="shrink-0 overflow-hidden border-2 p-2 rounded relative"
                        style={{
                          width: "33.333%",
                          height: "8rem",
                        }}
                        key={`tapal-doc-${i}`}
                      >
                        <a href={image.url} target="_blank">
                          <DocumentPreview
                            url={image.url}
                            docType={image.docType}
                          />
                        </a>

                        <span
                          className="absolute right-1 top-1 p-1 rounded-full cursor-pointer"
                          style={{ background: "#ff0000de" }}
                          onClick={() => {
                            onRemoveImage("tapal", i);
                          }}
                        >
                          <FaXmark style={{ color: "white" }} />
                        </span>
                      </div>
                    ))}
                </div>
              </div>

              <div
                className="grow-[3] pt-3 md:pt-0"
                style={{ width: isMobile ? "100%" : "50%" }}
              >
                <h2 className="color-text-primary px-0 md:px-4 pb-2 pt-2 text-sm">
                  Weighbridge receipt
                </h2>
                <div className="flex px-0 md:px-4 pb-0 md:pb-4 gap-2 overflow-auto">
                  <div className="shrink-0 overflow-hidden p-2">
                    <FileUploader
                      label=""
                      onChange={(e) => {
                        onUploadImage(
                          "receipt",
                          e.target.files ? e.target.files[0] : null
                        );
                      }}
                    />
                  </div>

                  {receipts.length > 0 &&
                    receipts.map((image, i) => (
                      <div
                        className="shrink-0 overflow-hidden border-2 p-2 rounded relative"
                        style={{
                          width: "33.333%",
                          height: "8rem",
                        }}
                        key={`tapal-doc-${i}`}
                      >
                        <a href={image.url} target="_blank">
                          <DocumentPreview
                            url={image.url}
                            docType={image.docType}
                          />
                        </a>

                        <span
                          className="absolute right-1 top-1 p-1 rounded-full cursor-pointer"
                          style={{ background: "#ff0000de" }}
                          onClick={() => {
                            onRemoveImage("receipt", i);
                          }}
                        >
                          <FaXmark style={{ color: "white" }} />
                        </span>
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className="pt-3">
              <div className="grow-[3]">
                {" "}
                <h2 className="color-text-primary pb-2 pt-2 text-sm">
                  Other Documents
                </h2>
                <div className="flex gap-2 overflow-auto pb-4">
                  <div className="shrink-0 overflow-hidden p-2">
                    <FileUploader
                      label=""
                      onChange={(e) => {
                        onUploadImage(
                          "other",
                          e.target.files ? e.target.files[0] : null
                        );
                      }}
                    />
                  </div>

                  {otherDocuments.length > 0 &&
                    otherDocuments.map((image, i) => (
                      <div
                        className="shrink-0 overflow-hidden border-2 p-2 rounded relative"
                        style={{
                          width: "33.333%",
                          height: "8rem",
                        }}
                        key={`tapal-doc-${i}`}
                      >
                        <a href={image.url} target="_blank">
                          <DocumentPreview
                            url={image.url}
                            docType={image.docType}
                          />
                        </a>

                        <span
                          className="absolute right-1 top-1 p-1 rounded-full cursor-pointer"
                          style={{ background: "#ff0000de" }}
                          onClick={() => {
                            onRemoveImage("other", i);
                          }}
                        >
                          <FaXmark style={{ color: "white" }} />
                        </span>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="flex items-center bottom-0 right-0 w-full justify-between bg-white py-4 border-top gap-2">
          <Button
            variant="primaryLight"
            className=""
            text="CANCEL"
            onClick={() => {
              onModalClose();
              onFalsePropsal(false);
            }}
          />
          <Button
            variant="contained"
            className=""
            onClick={() => {
              submit();
            }}
          >
            CREATE TRADE
          </Button>
        </div>
      </div>
    </>
  );
};
export default AssignOffTaker;
