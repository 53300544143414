import { FC, useEffect, useState } from "react";
import useCreateSupplyDemand from "../../hooks/useCreateSupplyDemand";
import PartnerInfo from "../../components/partner-info";
import NodataAvailable from "../../assets/images/NodataAvailable.svg";
import { TDestinationPartner } from "../../types";
import useSupplyDetailsById from "./useSupplyDetails";
import Modal from "../../components/ui/modal";
import ModalHeader from "../../components/ui/modal/modal-header";
import ContactInfo from "./contact-info";
import { event } from "jquery";
const SendNotification: FC<{
  type?: string;
  id?: any;
  traderData?: any;
  selectedBills?: any;
  handleSelectAll?: any;
  handleCheckboxChange?: any;
  searchText?: string;
  selectAll?: boolean;
}> = ({
  type,
  id,
  traderData,
  selectedBills,

  handleSelectAll,
  handleCheckboxChange,
  searchText,
  selectAll,
}) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Or use any breakpoint you prefer
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  // const [viewContactInfo, setViewContactInfo] = useState(false);
  return (
    <div className="overflow-auto">
      <table className="table-auto w-full border-collapse border text-sm bg-white">
        {/* <div
        className="border overflow-hidden "
        style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}
      > */}
        <thead className="bg-light-primary text-left sticky top-0 z-10 font-medium">
          <th className="text-center px-2 font-semibold">
            <label className="">
              <input
                type="checkbox"
                checked={
                  selectedBills.length ==
                    traderData.filter((doc: any) => doc.status != "SENT")
                      .length && selectedBills.length != 0
                }
                onChange={() => {
                  if (
                    traderData.length > 0
                    // &&
                    // traderData.every((item: any) => item.status !== "SENT")
                  ) {
                    handleSelectAll(); // Call the function with parentheses
                  }
                }}
                disabled={
                  traderData.length > 0 &&
                  traderData.every((item: any) => item.status === "SENT")
                    ? true
                    : false
                }
              />
            </label>
          </th>

          <th
            className="px-2 py-2 font-semibold"
            style={{ minWidth: isMobile ? "250px" : "" }}
          >
            Destination Party
          </th>
          <th
            className="px-2 py-2 font-semibold"
            style={{ minWidth: isMobile ? "120px" : "" }}
          >
            Mobile Number
          </th>
          <th
            className="px-2 py-2 font-semibold"
            style={{ minWidth: isMobile ? "140px" : "" }}
          >
            Alternative Mobile
          </th>
          <th
            className="px-2 py-2 font-semibold"
            style={{ minWidth: isMobile ? "120px" : "" }}
          ></th>
        </thead>

        {/* <div
          className="overflow-auto bg-white"
          style={{ height: "calc(100vh - 33rem)" }}
        > */}
        <tbody
          className="overflow-auto bg-white"
          // style={{ height: "calc(100vh - 33rem)" }}
        >
          {traderData.length > 0 ? (
            <>
              {(searchText !== ""
                ? traderData.filter((trade: any) => {
                    return (
                      trade.traderId.toString().includes(searchText) ||
                      trade.traderName.toLowerCase().includes(searchText)
                    );
                  })
                : traderData
              )
                .filter((doc: any) => doc.status !== "SENT")
                .map((trade: any) => (
                  <tr
                    key={trade.traderId}
                    className={`border-b text-sm ${
                      trade?.status !== "SENT" ? "cursor-pointer" : ""
                    }`}
                    onClick={() => {
                      if (trade?.status !== "SENT") {
                        handleCheckboxChange(trade);
                      }
                    }}
                  >
                    <td className="text-center px-2">
                      <input
                        type="checkbox"
                        checked={
                          selectedBills &&
                          selectedBills.length > 0 &&
                          selectedBills.some(
                            (r: any) => r.traderId === trade.traderId
                          )
                        }
                        disabled={trade.status === "SENT"}
                      />
                    </td>
                    <td className="px-2">
                      <PartnerInfo
                        profilePic=""
                        name={trade?.traderName || ""}
                        shortName=""
                        partnerType={trade?.tradeType}
                        partnerId={trade?.traderId}
                        partnerMobile={trade?.mobile}
                        partyName={trade?.addrLine}
                      />
                    </td>
                    <td className="px-2">
                      {trade.mobile ? trade.mobile : "-"}
                    </td>
                    <td className="px-2">
                      {trade.altMobile ? trade.altMobile : "-"}
                    </td>
                    <td className="px-2">
                      <span className="color-primary font-medium">
                        {trade.status === "SENT" ? "Sent" : ""}
                      </span>
                      <span>{trade.timeAgo}</span>
                    </td>
                  </tr>
                ))}
              {traderData.some((doc: any) => doc.status === "SENT") && (
                <>
                  <tr>
                    <td
                      colSpan={5}
                      className="text-sm color-primary px-4 py-2 bg-light-primary font-medium"
                    >
                      Notifications sent (
                      {
                        traderData.filter((doc: any) => doc.status === "SENT")
                          .length
                      }
                      )
                    </td>
                  </tr>
                  {(searchText !== ""
                    ? traderData.filter((trade: any) => {
                        return (
                          trade.traderId.toString().includes(searchText) ||
                          trade.traderName.toLowerCase().includes(searchText)
                        );
                      })
                    : traderData
                  )
                    .filter((doc: any) => doc.status === "SENT")
                    .map((trade: any) => (
                      <tr key={trade.traderId} className="border-b text-sm">
                        <td className="text-center px-2">
                          <input
                            type="checkbox"
                            checked={
                              selectedBills &&
                              selectedBills.length > 0 &&
                              selectedBills.some(
                                (r: any) => r.traderId === trade.traderId
                              )
                            }
                            disabled={trade.status === "SENT"}
                          />
                        </td>
                        <td className="px-2">
                          <PartnerInfo
                            profilePic=""
                            name={trade?.traderName || ""}
                            shortName=""
                            partnerType={trade?.tradeType}
                            partnerId={trade?.traderId}
                            partnerMobile={trade?.mobile}
                            partyName={trade?.addrLine}
                          />
                        </td>
                        <td className="px-2">
                          {trade.mobile ? trade.mobile : "-"}
                        </td>
                        <td className="px-2">
                          {trade.altMobile ? trade.altMobile : "-"}
                        </td>
                        <td className="px-2">
                          <span className="flex flex-col">
                            <span className="color-primary font-medium">
                              {trade.status === "SENT" ? "Sent" : ""}
                            </span>
                            <span>{trade.timeAgo}</span>
                          </span>
                        </td>
                      </tr>
                    ))}
                </>
              )}
            </>
          ) : (
            <tr>
              <td
                colSpan={5}
                className="flex items-center justify-center py-8 bg-white"
              >
                <img src={NodataAvailable} alt="No data available" />
              </td>
            </tr>
          )}
        </tbody>

        {/* </div> */}
        {/* </div> */}
      </table>
      {/* {viewContactInfo && (
        <>
          <Modal
            open={viewContactInfo}
            onClose={() => {
              setViewContactInfo(false);
            }}
            width="40vw"
          >
            <>
              <ModalHeader title={"Seller Contact Info"} content={<></>} />
              <div className="py-3 px-6">hii</div>
            </>
          </Modal>
        </>
      )} */}
    </div>
  );
};
export default SendNotification;
