import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useAxiosClient from "./useAxiosClient";
import { TRootState } from "../store";
import moment from "moment";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Compressor from "compressorjs";
import {
  TCrop,
  TDestinationPartner,
  TSupplyDemandCropImages,
  TSupplyDemandReq,
} from "../types";
import {
  updateActiveTradeTab,
  updateAllSupplyDetails,
  updateCropData,
  updatePartyData,
  updateSupplyDetails,
} from "../reducers/tradeSlice";
const useCreateSupplyDemand = ({
  id,
  modalClose,
  fromSupplyTab,
  type,
}: {
  id?: number;
  modalClose?: any;
  fromSupplyTab?: boolean;
  type?: string;
}) => {
  const { handleGetCall, handlePutCall, handlePostCall } = useAxiosClient();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [kycDocs, setKycDocs] = useState<
    Array<{ docType: "image" | "pdf"; url: string; type: string }>
  >([]);
  const {
    supplyDEtailsObj,
    adminId,
    selectedCrop,
    selectedTrader,
    partyData,
    deletedCrop,
    selectedSrcParty,
    tententType,
  } = useSelector((state: TRootState) => ({
    supplyDEtailsObj: state.trades.supplyDetailsById,
    adminId: state.auth.adminId,
    selectedCrop: state.trades.selectedCrop,
    selectedTrader: state.trades.selectedParty,
    partyData: state.trades.partyData,
    deletedCrop: state.trades.deletedCrop,
    selectedSrcParty: state.trades.selectedSrcParty,
    tententType: state.auth.userInfo?.type,
  }));
  // const [supplyDetails, setSupplyDetails] = useState<TSupplyDemandReq | null>(
  //   supplyDEtailsObj
  // );
  const [billCreationStatus, setBillCreationStatus] = useState<{
    status: "" | "PENDING" | "COMPLETED" | "LOADING";
  }>({ status: "PENDING" });
  const [supplyDetails, setSupplyDetails] = useState<{
    avlDate: string;
    expRate: number | "";
    expTotal: number;
    imgs: TSupplyDemandCropImages[];
    qty: number | "";
    quality: string | number;
    traderId: number;
    unitType: string | number;
    addressLine: string;
    altMobile: string;
    city: string;
    expDate: string;
    latitude: number;
    location: string;
    longitude: number;
    mobile: string;
    traderName: string;
    offerValidity: string;
    id: number;
    status: string;
    validity: string;
  }>({
    avlDate: "",
    expRate: 0,
    expTotal: 0,
    imgs: [],
    qty: 0,
    quality: "BEST",
    traderId: 0,
    unitType: "KGS",
    addressLine: "",
    altMobile: "",
    city: "",
    expDate: "",
    latitude: 0,
    location: "",
    longitude: 0,
    mobile: "",
    traderName: "",
    offerValidity: "",
    id: 0,
    status: "",
    validity: "",
  });

  const [traderData, setTraderData] = useState<TDestinationPartner[]>([]);
  const [tradeType, setTradeType] = useState(
    type == "Supply" ? "SOURCE" : "DEST"
  );
  const [selectedPartner, setSeletedPartner] =
    useState<TDestinationPartner | null>(null);
  const [selectedSrc, setSeletedSrc] = useState<TDestinationPartner | null>(
    null
  );
  const [latitude, setLatitude] = useState<number | null>(null);
  const [longitude, setLongitude] = useState<number | null>(null);
  const [selectedDate, setSelectedDate] = useState(
    supplyDetails?.avlDate ? supplyDetails?.avlDate : ""
  );
  let traderId = supplyDetails?.traderId || 0;
  const handleDateSelection = (_date: string) => {
    let date = moment(_date).format("YYYY-MM-DD");
    //  setCalenderOpen(false);
    setSelectedDate(date);
    setSupplyDetails({
      ...supplyDetails!,
      avlDate: type == "Supply" ? date : "",
      expDate: type == "Demand" ? date : "",
    });
  };
  const [searchText, setSearchText] = useState("");
  const handelSearch = (e: any) => {
    let searchtext = e.target.value;
    setSearchText(searchtext);
    if (searchText) {
      let searchTextLower = searchtext.toLowerCase();
      let filteredBuyers = partyData.filter((partner) => {
        if (
          (partner.tradeName &&
            partner.tradeName.toLowerCase().includes(searchTextLower)) ||
          (partner.addrLine &&
            partner.addrLine.toLowerCase().includes(searchText)) ||
          (partner.traderId &&
            partner.traderId.toString().includes(searchTextLower))
        ) {
          return true;
        } else {
          return false;
        }
      });
      setTraderData(filteredBuyers);
    } else {
      setTraderData(partyData);
    }
  };
  const clearSearch = (type: string) => {
    if (type == "DEST") {
      setSearchText("");
      setTraderData(partyData);
      setSeletedPartner(null);
    } else {
      setSearchText("");
      setTraderData(partyData);
      setSeletedSrc(null);
    }
  };
  const [cropImages, setCropImages] = useState<
    Array<{ docType: "image" | "pdf"; url: string }>
  >([]);
  useEffect(() => {
    getPartyData(tradeType);
  }, [tradeType]);
  const getPartyData = (tradeType: string) => {
    handleGetCall<TDestinationPartner[]>(
      `/admin/trade-platform/parties/adminId/${adminId}/type/${tradeType}?tenant=${tententType}`
    ).then(({ data }) => {
      setTraderData(data);
      dispatch(updatePartyData({ partyData: data }));
    });
  };
  const onUploadReceiptImage = async (type: "supply", file: any) => {
    let fileType = "";
    if (file.type === "application/pdf") {
      fileType = "pdf";
    } else if (file.type.includes("image/")) {
      fileType = "image";
    }
    let formdata = new FormData();
    formdata.append("id", traderId.toString());
    formdata.append("type", "SUPPLY");
    if (fileType === "pdf") {
      formdata.append("file", file, file.name.replace(" ", "-"));
      let imageUploadResponse = await handlePostCall<string[]>({
        URL: "/mandi/files/seller-buyer/imgs/upload",
        apiParams: formdata,
        config: {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      });
      let url = imageUploadResponse.data[0];
      setCropImages([{ docType: "pdf", url: url }, ...cropImages]);
    } else {
      new Compressor(file, {
        quality: 0.8,
        success: async (compressedResult) => {
          formdata.append(
            "file",
            compressedResult,
            file.name.replace(" ", "-")
          );
          let imageUploadResponse = await handlePostCall<string[]>({
            URL: "/mandi/files/seller-buyer/imgs/upload",
            apiParams: formdata,
            config: {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            },
          });
          let url = imageUploadResponse.data[0];

          setCropImages([...cropImages, { docType: "image", url: url }]);
        },
      });
    }
  };

  const onRemoveReceiptImage = (
    type: "supply",
    index: number,
    isExist: boolean = false
  ) => {
    if (type == "supply") {
      if (isExist === true && supplyDetails !== null) {
        let invoices = [...supplyDetails.imgs];
        let i = invoices.findIndex((img) => img.id === index);
        invoices[i] = { ...invoices[i], status: 0 };
        setSupplyDetails({ ...supplyDetails, imgs: invoices });
      } else {
        let newtapalInvoice = [...cropImages];
        newtapalInvoice.splice(index, 1);
        setCropImages(newtapalInvoice);
      }
    }
  };
  const submit = async () => {
    setBillCreationStatus({ ...billCreationStatus, status: "LOADING" });
    let imgs: Array<{
      id: number;
      status: number;
      addedBy: number;
      type: string;
      url: string;
    }> = [];
    let images = imgs;
    if (selectedPartner == null) {
      toast.error("Please select Trader");
      setBillCreationStatus({ ...billCreationStatus, status: "" });
      return;
    }
    if (selectedCrop == null) {
      toast.error("Please select Crop ");
      setBillCreationStatus({ ...billCreationStatus, status: "" });
      return;
    }
    if (supplyDetails?.quality == "") {
      toast.error("Please select Quality ");
      setBillCreationStatus({ ...billCreationStatus, status: "" });
      return;
    }
    if (selectedCrop != null && selectedCrop?.unitType == "") {
      toast.error("Please select Unit Type ");
      setBillCreationStatus({ ...billCreationStatus, status: "" });
      return;
    }
    if (selectedCrop != null && supplyDetails?.qty == 0) {
      toast.error("Please enter Quantity");
      setBillCreationStatus({ ...billCreationStatus, status: "" });
      return;
    }
    if (supplyDetails?.expRate == 0) {
      toast.error("Please enter Rate");
      setBillCreationStatus({ ...billCreationStatus, status: "" });
      return;
    }
    if (supplyDetails?.avlDate == "" && type == "Supply") {
      toast.error("Please select Availability Date");
      setBillCreationStatus({ ...billCreationStatus, status: "" });
      return;
    }

    if (supplyDetails?.expDate == "" && type == "Demand") {
      toast.error("Please select Expected Date");
      setBillCreationStatus({ ...billCreationStatus, status: "" });
      return;
    }
    if (supplyDetails?.validity == "" && type == "Demand") {
      toast.error("Please select Validity");
      setBillCreationStatus({ ...billCreationStatus, status: "" });
      return;
    }
    // if (
    //   cropImages.length === 0 &&
    //   supplyDetails?.imgs.length == 0 &&
    //   type == "Supply"
    // ) {
    //   toast.error("Please upload atleast one image ");
    //   return;
    // }

    // if (cropImages.length === 0 && supplyDetails?.imgs == null) {
    //   toast.error("Please upload atleast one image");
    //   return;
    // }
    // if (removeImageStatus) {
    if (supplyDetails != null) {
      if (supplyDetails?.imgs && supplyDetails?.imgs.length > 0) {
        for (let file of supplyDetails?.imgs) {
          if (file.status == 0) {
            images.push({
              ...file,
              addedBy: adminId,
              id: file.id,
            });
          }
        }
      }
    }
    // }
    if (cropImages.length > 0) {
      for (let file of cropImages) {
        images.push({
          id: 0,
          status: 1,
          addedBy: adminId || 0,
          type: "supply",
          url: file.url,
        });
      }
    }
    const allStatusZero =
      supplyDetails?.imgs &&
      supplyDetails?.imgs.every((item) => item.status === 0);
    const allStatusZeroEDitImages =
      images.length > 0 && images.every((item) => item.status === 0);
    if (
      allStatusZero &&
      allStatusZeroEDitImages &&
      supplyDetails?.imgs != null
    ) {
      toast.error("Please upload atleast one image");
      setBillCreationStatus({ ...billCreationStatus, status: "" });
      return;
    }
    try {
      let postBody = {
        avlDate: type == "Supply" ? supplyDetails?.avlDate : "",
        cropId: selectedCrop?.cropId,
        expRate: supplyDetails?.expRate,
        expTotal: supplyDetails?.expTotal,
        id: supplyDetails?.id,
        imgs: images,
        qty: supplyDetails?.qty,
        quality: supplyDetails?.quality,
        traderId: selectedPartner?.traderId,
        unitType: supplyDetails?.unitType,
        expDate: type == "Demand" ? supplyDetails?.expDate : "",
        latitude: latitude || 0,
        location: supplyDetails?.location || "",
        longitude: longitude || 0,
        offerValidity: supplyDetails?.offerValidity,
        traderName: selectedPartner?.name,
        validity: type == "Demand" ? supplyDetails?.validity : "",
      };

      await handlePostCall<any>({
        URL: `admin/demand-supply/adminId/${adminId}?type=${type?.toUpperCase()}`,
        apiParams: postBody,
      })
        .then(({ data, status }) => {
          toast.success(status.message);

          navigate(`/trades`);
          dispatch(updateActiveTradeTab("SUPPLY"));
          handleGetCall<TSupplyDemandReq[]>(
            `admin/demand-supply/supply/adminId/${adminId}`
          ).then(({ data }) => {
            if (data) {
              dispatch(updateAllSupplyDetails({ allSupplys: data }));
            }
          });
          setBillCreationStatus({ ...billCreationStatus, status: "" });
          modalClose(false);
        })
        .catch((err: any) => {
          toast.error(err.response.data.status.message);
          setBillCreationStatus({ ...billCreationStatus, status: "" });
        });
    } catch (ex) {}
  };
  useEffect(() => {
    if (supplyDEtailsObj == null || fromSupplyTab) {
      handleGetCall<TSupplyDemandReq>(
        `admin/demand-supply/supply/supplyId/${id}`
      ).then(({ data }) => {
        if (data) {
          let cropsData: TCrop;

          if (data.cropName) {
            cropsData = {
              baseName: "",
              cropId: data?.cropId || 0,
              cropName: data?.cropName || "",
              imageUrl: data?.cropUrl || "",
              type: "",
              units: "",
              varietyName: "",
              qtyUnit: "",
              rateType: "",
              qty: data?.qty || "",
              weight: "",
              freightAmt: "",
              status: 1,
              tradeId: 0,
              weightUnit: "",
              id: 0,
              addWastage: "",
              destWeight: "",
              destRate: "",
              rate: data?.expRate || "",
              unitType: "",
            };
            dispatch(
              updateCropData({
                selectedCrop: cropsData,
              })
            );
          } else {
            dispatch(
              updateCropData({
                selectedCrop: null,
              })
            );
          }
          setSelectedDate(data?.avlDate);
          dispatch(
            updateSupplyDetails({
              supplyDetailsById: {
                ...data,
                quality: data?.quality
                  ? data?.quality.toString().toUpperCase()
                  : "",
              },
            })
          );
          // setSupplyDetails({ ...data });
        } else {
          // setSupplyDetails(null);
        }
      });
    }
  }, []);

  return {
    supplyDetails,
    handleDateSelection,
    setSupplyDetails,
    submit,
    onUploadReceiptImage,
    kycDocs,
    selectedDate,
    cropImages,
    setCropImages,
    onRemoveReceiptImage,
    selectedCrop,
    selectedTrader,
    partyData,
    deletedCrop,
    selectedSrcParty,
    setSeletedPartner,
    selectedPartner,
    handelSearch,
    clearSearch,
    searchText,
    setTraderData,
    traderData,
    latitude,
    setLatitude,
    longitude,
    setLongitude,
    billCreationStatus,
  };
};
export default useCreateSupplyDemand;
