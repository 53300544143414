/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, memo } from "react";
import moment from "moment";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import classes from "./style.module.scss";
import Button from "../../../components/ui/button";
import calenderIcon from "../../../assets/images/date_icon.svg";
const week_days = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
const days_array = ["Sun", "Mon", "Tues", "Wed", "Thu", "Fri", "Sat"];
const months_array = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
const months_ful_array = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const date = new Date();

// type Tdate = {
//   day: number;
//   day_name: string;
//   month: number;
//   month_short_name: string;
//   month_full_name: string;
//   year: number;
//   date: string;
// };

// type TdateRange = {
//   fromdate: Tdate | null;
//   todate: Tdate | null;
//   selectionComplete: boolean;
// };

// type TCalanderProps = {
//   mode?: 'single' | 'range';
//   initialDate?: string;
//   initialDateRange?: { fromdate: string; todate: string };
//   onChange?: (data: { fromdate: string; todate: string; date: string }) => void;
//   style?: CSSProperties;
// };
const getDayObj = (date, disable = false, week_start_day_after = 0) => {
  let firstdayStartPos = 0;
  if (week_start_day_after === 0) {
    let firstDateOfMonth = new Date(moment(date).format("YYYY-MM-01"));
    firstdayStartPos = firstDateOfMonth.getDay();
  }
  let d = new Date(date);
  let day = d.getDate();
  let day2 = day;
  if (day2 <= 9) {
    day2 = "0" + day2;
  }
  let day_name = days_array[d.getDay()];
  let month = d.getMonth();
  let month_short_name = months_array[month];
  let month_full_name = months_ful_array[month];
  let year = d.getFullYear();
  let obj = {
    day: day,
    day_name: day_name,
    month: month,
    month_short_name: month_short_name,
    month_full_name: month_full_name,
    year: year,
    date: year + "-" + month_short_name + "-" + day2,
    disable: disable,
    week: Math.ceil((day + (week_start_day_after || firstdayStartPos)) / 7),
  };
  return obj;
};
const getDaysInMonth = (month, year, maxDate = "") => {
  // Since no month has fewer than 28 days
  let date = new Date(year, month, 1);
  let days = [];
  let d = new Date(date);
  let start_day = d.getDay();
  let maxDateObj = {};
  let maxDateYear = 0;
  let maxDateMonth = 0;
  let maxDateDay = 0;
  if (maxDate !== "") {
    maxDateObj = new Date(maxDate);
    maxDateDay = maxDateObj.getDate();
    maxDateYear = maxDateObj.getFullYear();
    maxDateMonth = maxDateObj.getMonth();
  }
  for (let i = 0; i < start_day; i++) {
    let obj = {
      day: 0,
      day_name: "",
      month: d.getMonth(),
      month_short_name: "",
      month_full_name: "",
      year: d.getFullYear(),
      date: "",
      week: 0,
    };
    days.push(obj);
  }
  while (date.getMonth() === month) {
    let obj = getDayObj(date, false, start_day);
    if (obj.year > maxDateYear) {
      obj.disable = true;
    }
    if (
      maxDate !== "" &&
      obj.year >= maxDateYear &&
      obj.month >= maxDateMonth
    ) {
      if (obj.month === maxDateMonth && obj.day > maxDateDay) {
        obj.disable = true;
      } else if (obj.month > maxDateMonth) {
        obj.disable = true;
      }
    }
    days.push(obj);
    date.setDate(date.getDate() + 1);
  }
  return days;
};
const Calander = ({
  mode = "single",
  initialDateRange,
  onChange,
  style,
  currentDate = "",
  maxDate = "",
  onClear = {},
  fromLoans = false,
}) => {
  const [dates, setDates] = useState([]);
  const [displayMonth, setDisplayMonth] = useState({
    month: date.getMonth(),
    year: date.getFullYear(),
  });
  const [selectedDate, setSelectedDate] = useState(
    getDayObj(currentDate || date)
  );

  const [dateRange, setDateRange] = useState({
    fromdate: null,
    todate: null,
    selectionComplete: false,
  });

  const constuct_dates = (month, year) => {
    let days = getDaysInMonth(month, year, maxDate);
    setDates(days);
  };

  const changeMonth = (action) => {
    let { month, year } = displayMonth;
    if (action === "next") {
      if (month >= 11) {
        month = 0;
        year += 1;
      } else {
        month += 1;
      }
    } else {
      if (month <= 0) {
        month = 11;
        year -= 1;
      } else {
        month -= 1;
      }
    }
    setDisplayMonth({ month: month, year: year });
    constuct_dates(month, year);
  };

  const onSelectDate = (date) => {
    if (date.disable === true) {
      return;
    }
    if (mode === "single") {
      setSelectedDate(date);
      typeof onChange === "function" &&
        onChange({
          date: date.date,
          fromdate: date.date,
          todate: date.date,
        });
    } else if (mode === "range") {
      if (dateRange.fromdate === null) {
        setDateRange({ ...dateRange, fromdate: date });
      } else if (
        dateRange.fromdate !== null &&
        dateRange.selectionComplete === true
      ) {
        setDateRange({
          ...dateRange,
          fromdate: date,
          todate: null,
          selectionComplete: false,
        });
      } else if (
        moment(date.date).diff(dateRange.fromdate?.date, "days") <= 0
      ) {
        setDateRange({
          ...dateRange,
          fromdate: date,
          todate: null,
          selectionComplete: false,
        });
      } else {
        setDateRange({ ...dateRange, todate: date, selectionComplete: true });
      }
    } else if (mode === "weekly") {
      let weekDates = dates.filter((dat) => {
        return dat.week === date.week;
      });
      setDateRange({
        ...dateRange,
        fromdate: weekDates[0],
        todate: weekDates[weekDates.length - 1],
      });
      setSelectedDate(date);
    }
  };
  const onClickRangeDate = (date) => {
    let dateObj = new Date(date.date);
    let dateObjM = dateObj.getMonth();
    let dateObjY = dateObj.getFullYear();
    setDisplayMonth({ month: dateObjM, year: dateObjY });
    constuct_dates(dateObjM, dateObjY);
  };
  const handleClear = () => {
    onClear();
  };
  const handelContinueClick = () => {
    if (mode === "weekly") {
      let today_date = moment().format("YYYY-MMM-DD");
      if (
        today_date === dateRange.fromdate.date &&
        today_date === dateRange.todate.date
      ) {
        let selectedWeek = selectedDate.week;
        let weekdays = dates.filter((dat) => {
          return dat.week === selectedWeek && dat.disable === false;
        });
        onChange({
          fromdate: weekdays[0].date,
          todate: weekdays[weekdays.length - 1].date,
        });
        return;
      }
    }
    console.log(dateRange.fromdate.date);
    onChange({
      fromdate: dateRange.fromdate.date,
      todate: dateRange.todate !== null ? dateRange.todate.date : "",
    });
  };
  // useEffect(() => {
  //   if (
  //     dateRange.selectionComplete &&
  //     dateRange.fromdate !== null &&
  //     dateRange.todate !== null
  //   ) {
  //     typeof onChange === "function" &&
  //       onChange({
  //         fromdate: dateRange.fromdate.date,
  //         todate: dateRange.todate.date,
  //         date: "",
  //       });
  //   }
  // }, [dateRange.selectionComplete]);
  console.log(currentDate, "currentDate");
  useEffect(() => {
    if (mode === "single") {
      if (currentDate === "") {
        setDisplayMonth({ month: date.getMonth(), year: date.getFullYear() });
        constuct_dates(date.getMonth(), date.getFullYear());
      } else {
        let currentDateObj = new Date(currentDate);
        setDisplayMonth({
          month: currentDateObj.getMonth(),
          year: currentDateObj.getFullYear(),
        });
        constuct_dates(currentDateObj.getMonth(), currentDateObj.getFullYear());
      }
    } else if (mode === "range" || mode === "weekly") {
      if (initialDateRange) {
        const { fromdate, todate } = initialDateRange;
        let d = new Date(fromdate);
        let monthStartDate = new Date(moment(fromdate).format("YYYY-MM-01"));
        setDisplayMonth({ month: d.getMonth(), year: d.getFullYear() });
        constuct_dates(d.getMonth(), d.getFullYear());
        let dayObj = {};
        if (mode === "weekly") {
          dayObj = getDayObj(d, false, monthStartDate.getDay());
        } else {
          dayObj = getDayObj(d);
        }
        setDateRange({
          fromdate: dayObj,
          todate: getDayObj(new Date(todate)),
          selectionComplete: false,
        });
        setSelectedDate(dayObj);
      } else {
        setDisplayMonth({ month: date.getMonth(), year: date.getFullYear() });
        constuct_dates(date.getMonth(), date.getFullYear());
        let d = new Date();
        setDateRange({
          fromdate: getDayObj(d),
          todate: getDayObj(d),
          selectionComplete: false,
        });
      }
    }
  }, [mode]);
  return (
    <div className={classes.calanderContainer} style={style}>
      {mode === "range" && (
        <div className="flex px-2 py-2">
          <div className="flex items-center grow gap-1">
            <span className="text-sm">From :</span>
            <span
              className={`flex gap-1 items-center border px-2 py-2 rounded-md text-sm cursor-pointer ${
                dateRange.fromdate && dateRange.todate ? classes.focused : ""
              }`}
              onClick={() => {
                onClickRangeDate(dateRange.fromdate);
              }}
            >
              <img src={calenderIcon} className="h-4" />
              <span style={{ width: "5.5rem" }}>
                {dateRange.fromdate?.date || "- - -:- -:- -"}
              </span>
            </span>
          </div>
          <div className="flex items-center gap-1 ml-auto text-sm">
            <span className="text-sm">To :</span>
            <span
              className={`flex gap-1 border px-2 py-2 items-center rounded-md cursor-pointer ${
                dateRange.fromdate && !dateRange.todate ? classes.focused : ""
              }`}
              onClick={() => {
                onClickRangeDate(dateRange.todate);
              }}
            >
              <img src={calenderIcon} className="h-4" />
              <span style={{ width: "5.5rem" }}>
                {dateRange.todate?.date || "- - -:- - -:- -"}
              </span>
            </span>
          </div>
        </div>
      )}
      <div className={classes.header + " flex px-5 py-3"}>
        <span
          className={classes.navigation}
          onClick={() => {
            changeMonth("prev");
          }}
        >
          <BsChevronLeft />
        </span>
        <span
          className="grow font-semibold text-center"
          style={{ fontSize: "20px", color: "var(--ono-blue)" }}
        >
          {months_array[displayMonth.month]} {displayMonth.year}
        </span>
        <span
          className={classes.navigation}
          onClick={() => {
            changeMonth("next");
          }}
        >
          <BsChevronRight />
        </span>
      </div>
      <div>
        <div className={classes.weekdays}>
          {week_days.map((day, index) => (
            <span key={"week_day_" + index}>{day}</span>
          ))}
        </div>
        <div className={classes.dates}>
          {dates.map((dat, index) => (
            <React.Fragment key={"date-" + index}>
              {dat.day > 0 ? (
                <span
                  data-week={dat.week}
                  data-weekly={mode === "weekly" ? true : false}
                  className={
                    mode === "single" &&
                    selectedDate.date === dat.date &&
                    selectedDate.month === dat.month &&
                    selectedDate.year === dat.year
                      ? classes.active
                      : mode === "range" &&
                        dateRange.fromdate?.date === dat.date
                      ? classes.fromdate
                      : mode === "range" && dateRange.todate?.date === dat.date
                      ? classes.todate
                      : mode === "weekly" &&
                        selectedDate.year === dat.year &&
                        selectedDate.month === dat.month &&
                        selectedDate.week === dat.week
                      ? classes.active
                      : ""
                  }
                  onClick={() => {
                    dat.disable === false && onSelectDate(dat);
                  }}
                >
                  <span
                    data-disable={dat.disable}
                    data-curdate={
                      dat.day === selectedDate.day &&
                      dat.year === selectedDate.year &&
                      dat.month === selectedDate.month &&
                      mode === "single" &&
                      selectedDate.day === date.getDate()
                    }
                    className={`text-xs ${classes.date}`}
                  >
                    {dat.day}
                  </span>
                </span>
              ) : (
                <span></span>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
      {(mode === "range" || mode === "weekly") && (
        <div className="flex" style={{ width: "100%" }}>
          {fromLoans ? (
            ""
          ) : (
            <Button
              text="Clear All"
              className="w-full h-[40px] mr-2"
              onClick={handleClear}
              variant="primaryLight"
            />
          )}

          <Button
            text="Apply"
            className="w-full h-[40px]"
            onClick={handelContinueClick}
          />
        </div>
      )}
    </div>
  );
};
export default memo(Calander);
