import { useSelector } from "react-redux";
import PartnerInfo from "../../../../components/partner-info";
import { TApplicationViewDetails } from "../../../../types";
import { TRootState } from "../../../../store";
import { getMaskedMobileNumber } from "../../../../helpers/get-currency-number";
import defultProfilePic from "../../../../assets/images/single_bill.svg";
const Comments: React.FC<{ data: TApplicationViewDetails; type?: string }> = ({
  data,
  type,
}) => {
  const { adminId, adminInfo } = useSelector((state: TRootState) => ({
    adminId: state.auth.adminId,
    adminInfo: state.auth.userInfo,
  }));

  const { reviewComments, verifyComments, approveComments, disburseComments, rejectComments } = useSelector((state: TRootState) => ({
    reviewComments: state.loans.reviewedComment,
    verifyComments: state.loans.verifyComment,
    approveComments: state.loans.approveComment,
    disburseComments: state.loans.disburseComment,
    rejectComments: state.loans.rejectComment
  }));
  
  return (
    <>
      <div
        className="flex py-4 px-4 items-start w-full gap-2 bg-white"
        style={{ borderTop: "0px" }}
      >
        <div
          className="flex-shrink-0 flex flex-col justify-center"
          style={{ width: "25%" }}
        >
          <div className="flex items-center gap-x-3 bg-gray px-3 py-3 br-10">
            <div>
              <img
                src={defultProfilePic}
                alt="user_profile_pic"
                className="w-8 h-8 br-100"
                style={{ maxWidth: "initial" }}
              />
            </div>
            <div className="flex flex-col">
              <span className="text-sm font-bold">{adminInfo?.name}</span>
              <span className="text-xs font-medium">Loan approval Manager</span>

              <p className="text-xs">
                {" "}
                {adminInfo?.mobile !== ""
                  ? ` ${getMaskedMobileNumber(adminInfo?.mobile)}`
                  : ``}
              </p>
            </div>
          </div>
        </div>

        <div
          className="grow border br-10 px-3 py-3 bg-light-primary"
          style={{ width: "70%" }} 
        >
          <span className="text-xs font-semibold">Review Comment:</span>
          <br />
          <span className="text-sm">
            {data?.disbPrgs
              .filter((doc) => doc.progStatus == type)
              .map((image, i) => (
                <span>{image.remarks}</span>
              ))}
          </span>
        </div>
      </div>
    </>
  );
};
export default Comments;
