import React, { useEffect, useRef, useState } from "react";
import { BsArrowDownCircle, BsArrowUpCircle } from "react-icons/bs";
import cash_d from "../../../assets/images/cash_d.svg";
import cash_r from "../../../assets/images/cash_r.svg";
import outs_icon from "../../../assets/images/outs_icon.svg";
import overdue from "../../../assets/images/overdue.svg";
import useLoansSmartboard from "./useLoansSmartboard";
import { getCurrencyNumberWithSymbol } from "../../../helpers/get-currency-number";
import NodataAvailable from "../../../assets/images/NodataAvailable.svg";
import useSmartboard from "../Applications/Select-bill/useSmartboard";
const LoansSmartboard = () => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const contentRef = useRef<HTMLDivElement>(null);
  const [contentHeight, setContentHeight] = useState("0px");
  // const { loanSmartboardDetails } = useLoansSmartboard();
  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  useEffect(() => {
    if (!isCollapsed && contentRef.current) {
      setContentHeight(`${contentRef.current.scrollHeight}px`);
    } else {
      setContentHeight("0px");
    }
  }, [isCollapsed]);
  
  const { smartBoard } = useSmartboard({});

  return (
    <div className="bg-white border br-10 cursor-pointer">
      {" "}
      <div
        onClick={toggleCollapse}
        className="flex justify-between items-center px-2 py-2 border-b cursor-pointer"
      >
        <span>Smartboard</span>

        {isCollapsed ? <BsArrowDownCircle /> : <BsArrowUpCircle />}
      </div>
      <div
        ref={contentRef}
        className="content"
        style={{
          maxHeight: contentHeight,
          overflow: "hidden",
          transition: "max-height 0.5s ease, opacity 0.5s ease",
          opacity: isCollapsed ? 0 : 1,
        }}
      >
        {smartBoard != null ? (
          <div>
            <div className="flex border-b">
              <div
                className="grow-[2] flex justify-between items-end py-4 px-4 border-r"
                style={{ width: "20%" }}
              >
                <div className="flex gap-3">
                  <img src={cash_d} alt="image" />
                  <div className="flex flex-col">
                    <span className="text-sm color-text-primary font-medium">
                      Cash Disbursed
                    </span>
                    <span className="text-base color-red font-semibold">
                      {getCurrencyNumberWithSymbol(
                        smartBoard.disbAmt
                      ) || 0}
                    </span>
                    {/* <span className="text-sm">(₹20 crore)</span> */}
                  </div>
                </div>
                {/* <span className="color-blue text-xs font-medium">See All</span> */}
              </div>
              <div
                className="grow-[2] flex justify-between items-end py-4 px-4 border-r"
                style={{ width: "20%" }}
              >
                <div className="flex gap-3">
                  <img src={cash_r} alt="image" />
                  <div className="flex flex-col">
                    <span className="text-sm color-text-primary font-medium">
                      Cash Recovered
                    </span>
                    <span className="text-base color-primary font-semibold">
                      {getCurrencyNumberWithSymbol(
                        smartBoard.recoveredAmt
                      ) || 0}
                    </span>
                    {/* <span className="text-sm">(₹20 crore)</span> */}
                  </div>
                </div>
                {/* <span className="color-blue text-xs font-medium">See All</span> */}
              </div>
              <div
                className="grow-[2] flex justify-between items-end py-4 px-4 border-r"
                style={{ width: "20%" }}
              >
                <div className="flex gap-3">
                  <img src={outs_icon} alt="image" />
                  <div className="flex flex-col">
                    <span className="text-sm color-text-primary font-medium">
                      Outstanding
                    </span>
                    <span className="text-base color-orange font-semibold">
                      {getCurrencyNumberWithSymbol(
                        smartBoard.outstandingAmt
                      ) || 0}
                    </span>
                    {/* <span className="text-sm">(₹20 crore)</span> */}
                  </div>
                </div>
                {/* <span className="color-blue text-xs font-medium">See All</span> */}
              </div>
              <div
                className="grow-[2] flex justify-between items-end py-4 px-4 border-r"
                style={{ width: "20%" }}
              >
                <div className="flex gap-3">
                  <img src={overdue} alt="image" />
                  <div className="flex flex-col">
                    <span className="text-sm color-text-primary font-medium">
                      Overdue
                    </span>
                    <span className="text-base color-brown font-semibold">
                      {getCurrencyNumberWithSymbol(
                        smartBoard.overDueAmt
                      ) || 0}
                    </span>
                    {/* <span className="text-sm">(₹20 crore)</span> */}
                  </div>
                </div>
                {/* <span className="color-blue text-xs font-medium">See All</span> */}
              </div>
            </div>
            <div className="flex border-b items-center">
              <span className="font-medium px-4">DPD</span>
              {smartBoard?.delayDetails.length > 0 &&
                smartBoard?.delayDetails.map((item, i) => (
                  <div className="grow-[2] py-4" style={{ width: "20%" }}>
                    <div className="flex items-center gap-3">
                      <span className="font-medium">
                        {" "}
                        {item.pmtDays != "NPA" ? "" : item.pmtDays}
                      </span>
                      <div className="flex flex-col">
                        <span className="text-xs color-text-primary font-medium">
                          {item.pmtDays != "NPA"
                            ? item.pmtDays
                            : item.loans + " Loans"}
                        </span>
                        <span className="text-sm font-medium">
                          <span>
                            {item.pmtDays != "NPA"
                              ? (item.loans || 0) + " Loans"
                              : ""}
                          </span>
                          <span>
                            ({getCurrencyNumberWithSymbol(item.amount) || 0})
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            <div className="flex font-medium">
              <div
                className="grow-[2] flex py-4 px-4 border-r"
                style={{ width: "20%" }}
              >
                <span className="text-sm color-text-primary font-medium">
                  Unique Borrowers : {smartBoard?.borrowers || "0"}
                </span>
              </div>
              <div
                className="grow-[2] flex py-4 px-4 border-r"
                style={{ width: "20%" }}
              >
                <span className="text-sm color-text-primary font-medium">
                  Unique Beneficiaries :{" "}
                  {smartBoard?.beneficiaries || "0"}
                </span>
              </div>
              <div
                className="grow-[2] flex py-4 px-4 "
                style={{ width: "20%" }}
              >
                <span className="text-sm color-text-primary font-medium">
                  Total Disbursements :{" "}
                  {smartBoard?.disbursements || "0"}
                </span>
              </div>
              <div
                className="grow-[2] flex py-4 px-4 "
                style={{ width: "20%" }}
              ></div>
            </div>
          </div>
        ) : (
          <div className="flex items-center justify-center py-8">
            <img src={NodataAvailable} alt="image" />
          </div>
        )}
      </div>
    </div>
  );
};

export default LoansSmartboard;
