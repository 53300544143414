import { FC, useState } from "react";
import { FaAngleDown } from "react-icons/fa";
import { Popover } from "../../../components/ui";
import timer from "../../../assets/images/timer-img.png";
import hold from "../../../assets/images/hold.svg";
import reject from "../../../assets/images/reject.svg";
import tickmark from "../../../assets/images/verified.svg";
import blocked from "../../../assets/images/blocked.svg";
import { ArrowDropDown } from "@mui/icons-material";
const statusList = [
  {
    label: "Under Review",
    value: "UNDER REVIEW",
    icon: timer,
    color: "color-orange",
  },
  {
    label: "KYC Approved",
    value: "ACTIVE",
    icon: tickmark,
    color: "color-primary",
  },

  {
    label: "Rejected",
    value: "REJECTED",
    icon: reject,
    color: "color-red",
  },
  { label: "Blocked", value: "BLOCKED", icon: blocked, color: "color-red" },
  { label: "On Hold", value: "HOLD", icon: hold, color: "color-orange" },
];
const UserAccountStatus: FC<{
  trade_status: string;
  onChangeTradeStatus: (status: string) => void;
  statusIcon: { color: string; icon: string };
}> = ({ trade_status, onChangeTradeStatus, statusIcon }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Popover
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        fullWidth={true}
        element={
          <div
            onClick={() => {
              setOpen(true);
            }}
            className="bg-white p-3 br-10 border flex items-center gap-4 cursor-pointer"
          >
            <div className="flex gap-2 justify-between">
              <img src={statusIcon?.icon} className="w-6" />
              <h1 className={`text-base font-bold ${statusIcon.color}`}>
                {trade_status}
              </h1>
              <div className="cursor-pointer">
                    <ArrowDropDown />
                  </div>
            </div>
          </div>
        }
      >
        <div>
          {statusList.map((tradeStatus) => (
            <div
              className="flex items-center px-3 py-2 gap-2 cursor-pointer "
              onClick={() => {
                onChangeTradeStatus(tradeStatus.value);
                setOpen(false);
              }}
            >
              <img src={tradeStatus.icon} className="w-6" />
              <span className={`font-semibold text-sm ${tradeStatus.color}`}>
                {tradeStatus.label}
              </span>
            </div>
          ))}
        </div>
      </Popover>
    </>
  );
};
export default UserAccountStatus;
