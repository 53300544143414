import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useAxiosClient from "./useAxiosClient";
import { TBeneficiaryDetails, TCashKycDoc } from "../types/index";
import { TRootState } from "../store";
import { updateBeneficiaryDetails } from "../reducers/borrowerSlice";

const useBeneficiaryDetailsById = ({ appId }: { appId?: number }) => {
  const { handleGetCall } = useAxiosClient();
  const dispatch = useDispatch();
  const { adminId, role, InitBorrowerDetail } = useSelector(
    (state: TRootState) => ({
      adminId: state.auth.adminId,
      role: state.auth.userInfo?.roleType,
      InitBorrowerDetail: state.borrowers.beneficiaryDetails,
    })
  );
  const [beneficiaryDetails, setBeneficiaryDetails] =
    useState<TBeneficiaryDetails | null>(InitBorrowerDetail);
    const [confirmAccountNum, setConfirmAccountNum] = useState(InitBorrowerDetail?.accNum);

  const getBeneficiaryDetail = () => {
    handleGetCall<TBeneficiaryDetails>(
      `admin/cash/borrowers/adminId/${adminId}/appId/${appId}`
    ).then(({ data }) => {
      
      if (data) {
        let kycDocs: TCashKycDoc[] = [];
        if (data.kycDocs != null && data.kycDocs.length > 0) {
          for (let image of data.kycDocs) {
            let docTypes: "pdf" | "image" = image.docUrl.includes(".pdf")
              ? "pdf"
              : "image";
            kycDocs.push({ ...image, type: docTypes, status: 2 });
          }
          console.log(kycDocs);
        }

        setBeneficiaryDetails({
          ...data,
          kycDocs: kycDocs,
        });
        setConfirmAccountNum(data.accNum);
        dispatch(
          updateBeneficiaryDetails({
            beneficiaryDetails: {
              ...data,
              kycDocs: kycDocs,
            },
          })
        );
      } else {
        setBeneficiaryDetails(null);
      }
    });
  };

  return { beneficiaryDetails, getBeneficiaryDetail, role, confirmAccountNum, setConfirmAccountNum };
};
export default useBeneficiaryDetailsById;

