import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useAxiosClient from "./useAxiosClient";
import {
  TBorrowerDetails,
  TBorrowers,
  TCashKycDoc,
  TTrader,
  TTraderKycDoc,
} from "../types/index";
import { TRootState } from "../store";
import { updateBorrowerDetails } from "../reducers/borrowerSlice";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const useBorrowerDetailsById = ({ cpId }: { cpId: number }) => {
  const { handleGetCall, handlePostCall } = useAxiosClient();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { adminId, role, InitBorrowerDetail } = useSelector(
    (state: TRootState) => ({
      adminId: state.auth.adminId,
      role: state.auth.userInfo?.roleType,
      InitBorrowerDetail: state.borrowers.borrowerDetails,
    })
  );
  const [onRefresh, setOnRefresh] = useState(false);
  const [borrowerDetails, setBorrowerDetails] =
    useState<TBorrowerDetails | null>(InitBorrowerDetail);

  const getBorrowerDetail = () => {
    handleGetCall<TBorrowerDetails>(
      `admin/cash/borrowers/adminId/${adminId}/cpId/${cpId}`
    ).then(({ data }) => {
      if (data) {
        let partnersObj = null;
        let totalPartners = 0;
        let kycDocs: TCashKycDoc[] = [];
        let bankDocs: TCashKycDoc[] = [];
        let itrDocs: TCashKycDoc[] = [];
        let licenceDocs: TCashKycDoc[] = [];
        if (data.kycDocs != null && data.kycDocs.length > 0) {
          for (let image of data.kycDocs) {
            let docTypes: "pdf" | "image" = image.docUrl.includes(".pdf")
              ? "pdf"
              : "image";
            kycDocs.push({ ...image, type: docTypes, status: 2 });
            // if (image.docType === "PAN_CARD") {
            //   panDocs.push({ ...image, type: docTypes, status: 1 });
            // } else if (image.docType === "BANK_STATEMENT") {
            //   bankDocs.push({ ...image, type: docTypes, status: 1 });
            // } else if (image.docType === "ITR") {
            //   itrDocs.push({ ...image, type: docTypes, status: 1 });
            // } else if (image.docType === "LICENCE") {
            //   licenceDocs.push({ ...image, type: docTypes, status: 1 });
            // }
          }
          console.log(kycDocs);
        }
        if (data.partnerDetails.length > 0) {
          const sellers = data.partnerDetails
            .filter(
              (item) =>
                item.partyType === "FARMER" || item.partyType === "TRADER"
            )
            .reduce((sum, item) => sum + item.total, 0);

          partnersObj = data.partnerDetails.reduce((obj: any, item) => {
            if (item.partyType === "FARMER" || item.partyType === "TRADER") {
              obj["seller"] = sellers;
            } else {
              obj[item.partyType.toLowerCase()] = item.total;
            }
            return obj;
          }, {});
          totalPartners = data.partnerDetails.reduce(
            (sum, item) => sum + item.total,
            0
          );
        }

        setBorrowerDetails({
          ...data,
          totalBillsAmount: data?.buyBillAmt + data?.sellBillAmt,
          totalBills: data?.buyBills + data?.sellBills,
          partnerObjects: partnersObj || null,
          totalPartners: totalPartners,
          kycDocs: kycDocs,
          tenureString: data.tenure ? data.tenure.toString() : "",
        });
        dispatch(
          updateBorrowerDetails({
            borrowerDetails: {
              ...data,
              totalBillsAmount: data?.buyBillAmt + data?.sellBillAmt,
              totalBills: data?.buyBills + data?.sellBills,
              partnerObjects: partnersObj || null,
              totalPartners: totalPartners,
              kycDocs: kycDocs,
              tenureString: data.tenure ? data.tenure.toString() : "",
            },
          })
        );
      } else {
        setBorrowerDetails(null);
      }
    });
  };
  const analyseEvent = () => {
    let images = [];
    if (borrowerDetails?.kycDocs && borrowerDetails?.kycDocs.length > 0) {
      for (let file of borrowerDetails?.kycDocs) {
        if (file.docType == "BANK_STATEMENT") {
          images.push({
            docId: file.id,
            fileUrl: file.docUrl,
            // password: "",
          });
        }
      }
    }
    //dev-api.onoark.com/v1/admin/bank-stmt/analyze
    handlePostCall({
      URL: `admin/intgr/bank-stmt/analyze`,
      apiParams: {
        files: images,
        pfId: borrowerDetails?.cpId,
        platform: "CASH",
      },
    })
      .then(({ data, status }) => {
        toast.success(status.message);
        getBorrowerDetail();
        navigate(`/borrower-detail/${borrowerDetails?.cpId}`);
      })
      .catch((err: any) => {
        toast.error(err.response.data.status.message);
      });
  };

  return { borrowerDetails, getBorrowerDetail, role, analyseEvent };
};
export default useBorrowerDetailsById;
