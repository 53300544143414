import { ArrowBack } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PartnerInfo from "../../components/partner-info";
import edit from "../../assets/images/edit_round.svg";
import { Button, Input } from "../../components/ui";
import Modal from "../../components/ui/modal";
import ModalHeader from "../../components/ui/modal/modal-header";
import EditSupplyPost from "./edit-supply-post";
import market from "../../assets/images/mandi.svg";
import ContactInfo from "./contact-info";
import EditPostRequest from "./edit-post-request";
import AssignOffTaker from "./assign-off-taker";
import CropImageGallery from "./crop-image-gallery";
import call from "../../assets/images/call.svg";
import useSupplyDetailsById from "./useSupplyDetails";
import moment from "moment";
import { useDispatch } from "react-redux";
import {
  updateActiveTradeTab,
  updateProposalCropImgs,
  updateTraderContact,
} from "../../reducers/tradeSlice";
import NodataAvailable from "../../assets/images/NodataAvailable.svg";
import { FaCheck } from "react-icons/fa6";
import { FaCheckCircle, FaSearch } from "react-icons/fa";
import { toCamelCaseWord } from "../../helpers/getText";
import getCurrencyNumber, {
  getCurrencyNumberWithOutSymbol,
} from "../../helpers/get-currency-number";
import SendNotification from "./supplyDemandNotification";
const SupplyPostView = () => {
  const navigate = useNavigate(); // To navigate to the KYC documents page
  const dispatch = useDispatch();
  const params = useParams();
  const {
    supplyDemandDetails,
    getSupplyDetail,
    selectedBills,
    searchText,
    setSearchText,
    handleSelectAll,
    handleCheckboxChange,
    sendNotificationsEvent,
    selectAll,
  } = useSupplyDetailsById({
    id: parseInt(params.postId || "0"),
    type: "SUPPLY",
  });
  useEffect(() => {
    getSupplyDetail();
  }, [params]);

  const [supplyPostEdit, setSupplyPostEdit] = useState(false);
  const [viewContactInfo, setViewContactInfo] = useState(false);
  const [requestPostEdit, setRequestPostEdit] = useState(false);
  const [assignOffTakerStatus, setAssignOffTakerStatus] = useState(false);
  const [imageGalleryOpen, setImageGalleryOpen] = useState(false);
  const [propsalAccept, setAropsalAccept] = useState(false);
  const [tabsReq, setTabsReq] = useState("Requests");
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Or use any breakpoint you prefer
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div className="">
      <div className="md:border overflow-hidden bg-white px-4">
        <div className="block md:flex text-sm py-4 cursor-pointer items-center">
          <div
            className="flex border-b pb-2 md:border-none md:pb-0"
            style={{ width: isMobile ? "100%" : "30%" }}
          >
            <div
              className="grow-[2.5] basis-0 flex items-center cursor-pointer gap-1 "
              style={{ width: isMobile ? "auto" : "30%" }}
              onClick={() => {
                navigate("/trades");
              }}
            >
              <ArrowBack />
              <PartnerInfo
                profilePic={supplyDemandDetails?.profilePic || ""}
                name={supplyDemandDetails?.traderName || ""}
                shortName={""}
                partnerType={supplyDemandDetails?.tradeType}
                partnerId={supplyDemandDetails?.traderId}
                partnerMobile={supplyDemandDetails?.mobile || ""}
                partyName={supplyDemandDetails?.addressLine}
              />
            </div>
            <div className="flex md:hidden " style={{}}>
              <div>
                {supplyDemandDetails?.status != "ASSIGNED" ? (
                  <img
                    src={edit}
                    onClick={() => {
                      setSupplyPostEdit(true);
                    }}
                    className="cursor-pointer w-8 h-8"
                  />
                ) : (
                  <Button variant="primaryLight" className="">
                    <FaCheckCircle color="#16A02C" />{" "}
                    {isMobile ? " " : "Status :"} {supplyDemandDetails?.status}
                  </Button>
                )}
              </div>
            </div>
          </div>
          <div
            className="flex gap-4 items-center pt-2 md:pt-0"
            style={{ width: isMobile ? "100%" : "25%" }}
          >
            <img src={market} className="w-8 h-8 " />
            <div className="flex flex-col">
              <span className="text-sm">Mandi Address </span>
              <span>
                <span className="text-sm font-semibold">
                  {supplyDemandDetails?.location
                    ? supplyDemandDetails?.location + ","
                    : ""}
                  {supplyDemandDetails?.city
                    ? supplyDemandDetails?.city + ","
                    : ""}
                  {supplyDemandDetails?.state ? supplyDemandDetails?.state : ""}
                  {supplyDemandDetails?.pincode
                    ? " " + supplyDemandDetails?.pincode
                    : ""}
                </span>
              </span>
            </div>
          </div>
          <div className="flex md:hidden gap-2 py-2 pt-4 border-b">
            <div className=" gap-4 items-center flex" style={{ width: "50%" }}>
              <img src={call} className="w-8 h-8 " />
              <div className="flex flex-col">
                <span className="text-sm">Mobile number </span>
                <span>
                  <span className="text-sm font-semibold">
                    {supplyDemandDetails?.mobile || "-"}
                  </span>
                </span>
              </div>
            </div>
            <div className=" gap-4 items-center flex" style={{ width: "50%" }}>
              <img src={call} className="w-8 h-8 " />
              <div className="flex flex-col">
                <span className="text-sm">Alternative Mobile </span>
                <span>
                  <span className="text-sm font-semibold">
                    {supplyDemandDetails?.altMobile || "-"}
                  </span>
                </span>
              </div>
            </div>
          </div>
          <div
            className=" gap-4 items-center hidden md:flex"
            style={{ width: "20%" }}
          >
            <img src={call} className="w-8 h-8 " />
            <div className="flex flex-col">
              <span className="text-sm">Mobile number </span>
              <span>
                <span className="text-sm font-semibold">
                  {supplyDemandDetails?.mobile || "-"}
                </span>
              </span>
            </div>
          </div>
          <div
            className=" gap-4 items-center hidden md:flex"
            style={{ width: "20%" }}
          >
            <img src={call} className="w-8 h-8 " />
            <div className="flex flex-col">
              <span className="text-sm">Alternative Mobile </span>
              <span>
                <span className="text-sm font-semibold">
                  {supplyDemandDetails?.altMobile || "-"}
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="md:px-4 md:py-4">
        <div className="md:border md:br-10 px-2 bg-white">
          {" "}
          <div
            className=" border-b text-sm md:py-4 pb-2 block md:flex justify-between items-center"
            style={{ width: "100%" }}
          >
            <div
              className=" block md:flex justify-between px-2 "
              style={{ width: "100%" }}
            >
              <div
                className="grow-[3] basis-0 flex flex-col border-b md:border-none pb-2 md:pb-2"
                style={{ width: isMobile ? "100%" : "30%" }}
              >
                <div className="flex items-center gap-x-3">
                  <div>
                    <img
                      src={supplyDemandDetails?.cropUrl}
                      alt=""
                      className="br-100 w-8 h-8 "
                    />
                  </div>
                  <div className="flex flex-col">
                    <span className="text-sm font-bold">
                      {supplyDemandDetails?.cropName}
                    </span>
                    <span className="text-xs font-medium">
                      {supplyDemandDetails?.quality
                        ? toCamelCaseWord(supplyDemandDetails?.quality) +
                          " Quality"
                        : "-"}
                    </span>

                    <span className="text-xs">
                      Post ID :{" "}
                      <span className="color-blue">
                        {supplyDemandDetails?.id}
                      </span>{" "}
                      | {supplyDemandDetails?.timeAgo}
                    </span>
                  </div>
                </div>
              </div>
              {supplyDemandDetails?.imgs ? (
                <div className="flex gap-4 md:hidden py-2 border-b overflow-x-auto">
                  {supplyDemandDetails?.imgs.map((crop, i) => (
                    <img
                      src={crop.url}
                      alt=""
                      className="br-10 cursor-pointer"
                      style={{ width: "60px", height: "60px" }}
                      onClick={() => {
                        setImageGalleryOpen(true);
                        dispatch(
                          updateProposalCropImgs({
                            proposalCropImages: supplyDemandDetails.imgs,
                          })
                        );
                      }}
                    />
                  ))}
                </div>
              ) : (
                ""
              )}

              <div className="flex md:hidden py-2 border-b">
                <div
                  className="grow-[2] basis-0 flex px-2 flex-col"
                  style={{ width: "20%" }}
                >
                  <span className="text-sm">Quantity</span>
                  <span className="text-sm font-semibold">
                    {" "}
                    {getCurrencyNumberWithOutSymbol(supplyDemandDetails?.qty) +
                      " " || "-"}
                    {supplyDemandDetails?.unitType}
                  </span>
                </div>
                <div
                  className="grow-[2] basis-0 flex px-2 flex-col"
                  style={{ width: "20%" }}
                >
                  <span className="text-sm">Expected Rate</span>
                  <span className="font-semibold text-sm ">
                    {" "}
                    Rs.{supplyDemandDetails?.expRate} Per KG
                  </span>
                </div>
              </div>
              <div
                className="grow-[2] basis-0  px-2 flex-col hidden md:flex"
                style={{ width: "20%" }}
              >
                <span className="text-sm">Quantity</span>
                <span className="text-sm font-semibold">
                  {" "}
                  {getCurrencyNumberWithOutSymbol(supplyDemandDetails?.qty) +
                    " " || "-"}
                  {supplyDemandDetails?.unitType}
                </span>
              </div>
              <div
                className="grow-[2] basis-0  px-2 flex-col hidden md:flex"
                style={{ width: "20%" }}
              >
                <span className="text-sm">Expected Rate</span>
                <span className="font-semibold text-sm ">
                  {" "}
                  Rs.{supplyDemandDetails?.expRate} Per KG
                </span>
              </div>
              <div className="flex md:hidden py-2">
                <div
                  className="grow-[2] basis-0 flex px-2 flex-col"
                  style={{ width: "20%" }}
                >
                  <span className="text-sm">Availability Date</span>
                  <span className="text-sm font-semibold">
                    {" "}
                    {moment(supplyDemandDetails?.avlDate).format(
                      "DD-MMM-YYYY"
                    ) || "-"}
                  </span>
                </div>
                <div
                  className="grow-[2] basis-0 flex px-2 flex-col"
                  style={{ width: "20%" }}
                >
                  <span className="text-sm">Requests</span>
                  <span className="font-semibold text-sm ">{`${
                    supplyDemandDetails?.propsCount
                      ? `${supplyDemandDetails?.propsCount}`
                      : "-"
                  }
                          `}</span>
                </div>
              </div>
              <div
                className="grow-[2] basis-0 px-2 flex-col hidden md:flex"
                style={{ width: "20%" }}
              >
                <span className="text-sm">Availability Date</span>
                <span className="text-sm font-semibold">
                  {" "}
                  {moment(supplyDemandDetails?.avlDate).format("DD-MMM-YYYY") ||
                    "-"}
                </span>
              </div>
              <div
                className="grow-[2] basis-0 hidden md:flex px-2 flex-col"
                style={{ width: "20%" }}
              >
                <span className="text-sm">Requests</span>
                <span className="font-semibold text-sm ">{`${
                  supplyDemandDetails?.propsCount
                    ? `${supplyDemandDetails?.propsCount}`
                    : "-"
                }
                          `}</span>
              </div>
            </div>
            <div className="hidden md:flex " style={{ width: "20%" }}>
              <div>
                {supplyDemandDetails?.status != "ASSIGNED" ? (
                  <img
                    src={edit}
                    onClick={() => {
                      setSupplyPostEdit(true);
                    }}
                    className="cursor-pointer w-10 h-10"
                  />
                ) : (
                  <Button variant="primaryLight" className="">
                    <FaCheckCircle color="#16A02C" />{" "}
                    {isMobile ? "" : "Status :"} {supplyDemandDetails?.status}
                  </Button>
                )}
              </div>
            </div>
          </div>
          <div className="py-4 px-2 flex justify-between items-center">
            <div className=" gap-4 hidden md:flex">
              {supplyDemandDetails?.imgs
                ? supplyDemandDetails?.imgs.map((crop, i) => (
                    <img
                      src={crop.url}
                      alt=""
                      className="br-10 cursor-pointer"
                      style={{ width: "60px", height: "60px" }}
                      onClick={() => {
                        setImageGalleryOpen(true);
                        dispatch(
                          updateProposalCropImgs({
                            proposalCropImages: supplyDemandDetails.imgs,
                          })
                        );
                      }}
                    />
                  ))
                : ""}
            </div>
            {supplyDemandDetails?.status != "ASSIGNED" ? (
              <Button
                variant="contained"
                className=""
                onClick={() => {
                  setAssignOffTakerStatus(true);
                }}
              >
                Assign Buyer
              </Button>
            ) : (
              <Button
                variant="contained"
                className=""
                onClick={() => {
                  if (supplyDemandDetails?.tradeDtls?.tradeId) {
                    navigate(
                      `/trade-detail/${supplyDemandDetails?.traderId}/${supplyDemandDetails?.tradeDtls?.tradeId}`
                    );
                  } else {
                    navigate("/trades");
                  }
                  dispatch(updateActiveTradeTab("OPEN"));
                }}
              >
                Go To Trades
              </Button>
            )}
          </div>
        </div>
      </div>
      {supplyDemandDetails?.status == "ASSIGNED" &&
        supplyDemandDetails?.tradeDtls &&
        (supplyDemandDetails?.proposals &&
        supplyDemandDetails?.proposals.some(
          (doc) => doc.prgStatus === "ASSIGNED"
        ) ? (
          ""
        ) : (
          <div className="px-4 py-1">
            <div className="border-b pb-2 flex gap-4">
              <button
                className={`cursor-pointer px-4 py-1 rounded-t-lg bg-white-200  ${
                  tabsReq === "Requests"
                    ? "border-green-600 border-b-4 color-primary"
                    : ""
                } text-base`}
                onClick={() => setTabsReq("Requests")}
              >
                Assigned Buyer
              </button>
              {/* <span className="rounded-t-lg border-green-600 border-b-4 pb-2 font-medium color-primary">
                Assigned Buyer
              </span> */}
              {
                <button
                  className={`cursor-pointer px-4 py-1 rounded-t-lg bg-white-200  ${
                    tabsReq === "Notifications"
                      ? "border-green-600 border-b-4 color-primary"
                      : ""
                  } text-base`}
                  onClick={() => setTabsReq("Notifications")}
                >
                  Send Notifications
                </button>
              }
            </div>
            {tabsReq == "Requests" && (
              <div className="bg-white border br-10 py-4 px-4 block md:flex justify-between items-center mt-4">
                <div
                  className="block md:flex items-center gap-4"
                  style={{ width: isMobile ? "100%" : "50%" }}
                >
                  {" "}
                  <div className="grow-[2] basis-0 flex px-2 flex-col border-b md:border-none pb-2 md:pb-0">
                    <PartnerInfo
                      profilePic={""}
                      name={supplyDemandDetails?.tradeDtls?.traderName || ""}
                      shortName={""}
                      partnerType={"Trader"}
                      partnerId={supplyDemandDetails?.tradeDtls?.traderId}
                      partnerMobile={
                        supplyDemandDetails?.tradeDtls?.mobile || ""
                      }
                      partyName={supplyDemandDetails?.tradeDtls?.addrLine}
                    />
                  </div>
                  <div className="flex md:hidden py-2 ">
                    <div
                      className="grow-[2] basis-0 flex px-2 flex-col"
                      style={{ width: "20%" }}
                    >
                      <span className="text-sm">Pickup Date</span>
                      <span className="font-semibold text-sm ">
                        {moment(supplyDemandDetails?.tradeDtls?.date).format(
                          "DD-MMM-YYYY"
                        )}
                      </span>
                    </div>
                    <div
                      className="grow-[2] basis-0 flex px-2 flex-col"
                      style={{ width: "20%" }}
                    >
                      <span className="text-sm">Offer Price</span>
                      <span className="font-semibold text-sm ">
                        Rs. {supplyDemandDetails?.tradeDtls?.rate} Per Kg
                      </span>
                    </div>
                  </div>
                  <div
                    className="grow-[2] basis-0 px-2 flex-col hidden md:flex"
                    style={{ width: "20%" }}
                  >
                    <span className="text-sm">Pickup Date</span>
                    <span className="font-semibold text-sm ">
                      {moment(supplyDemandDetails?.tradeDtls?.date).format(
                        "DD-MMM-YYYY"
                      )}
                    </span>
                  </div>
                  <div
                    className="grow-[2] basis-0 flex px-2 flex-col hidden md:flex"
                    style={{ width: "20%" }}
                  >
                    <span className="text-sm">Offer Price</span>
                    <span className="font-semibold text-sm ">
                      Rs. {supplyDemandDetails?.tradeDtls?.rate} Per Kg
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
      <div className="px-4 py-1">
        <div>
          <div className="pb-2 flex gap-4 justify-between">
            <div className="flex gap-4">
              <button
                className={`cursor-pointer px-4 py-1 rounded-t-lg bg-white-200 whitespace-nowrap text-base  ${
                  supplyDemandDetails?.proposals &&
                  supplyDemandDetails?.status == "ASSIGNED"
                    ? supplyDemandDetails?.proposals.some(
                        (doc) => doc.prgStatus === "ASSIGNED"
                      ) && supplyDemandDetails?.status == "ASSIGNED"
                      ? tabsReq === "Requests"
                        ? "border-green-600 border-b-4 color-primary"
                        : ""
                      : supplyDemandDetails?.proposals.length > 1
                      ? ""
                      : tabsReq === "Requests"
                      ? "border-green-600 border-b-4 color-primary"
                      : ""
                    : tabsReq === "Requests"
                    ? "border-green-600 border-b-4 color-primary"
                    : ""
                } text-base`}
                onClick={() => setTabsReq("Requests")}
              >
                {supplyDemandDetails?.proposals &&
                supplyDemandDetails?.status == "ASSIGNED"
                  ? supplyDemandDetails?.proposals.some(
                      (doc) => doc.prgStatus === "ASSIGNED"
                    ) && supplyDemandDetails?.status == "ASSIGNED"
                    ? "Accepted Requests"
                    : supplyDemandDetails?.proposals.length > 1
                    ? ""
                    : tabsReq === "Requests"
                    ? "Requests"
                    : ""
                  : supplyDemandDetails?.status == "ASSIGNED" &&
                    tabsReq === "Notifications"
                  ? ""
                  : "Requests" + `(${supplyDemandDetails?.propsCount || 0})`}
              </button>
              {supplyDemandDetails?.status != "ASSIGNED" ||
              (supplyDemandDetails?.proposals &&
                supplyDemandDetails?.status == "ASSIGNED" &&
                supplyDemandDetails?.proposals.some(
                  (doc) => doc.prgStatus === "ASSIGNED"
                )) ? (
                <button
                  className={`cursor-pointer px-4 py-1 rounded-t-lg whitespace-nowrap bg-white-200  ${
                    tabsReq === "Notifications"
                      ? "border-green-600 border-b-4 color-primary"
                      : ""
                  } text-base`}
                  onClick={() => setTabsReq("Notifications")}
                >
                  Send Notifications
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
          {tabsReq == "Notifications" && (
            <div className="block md:flex justify-between pt-2">
              <div
                className="relative group-[4] mb-2 md:mb-0"
                style={{ width: isMobile ? "100%" : "20%" }}
              >
                <FaSearch
                  className="absolute color-primary"
                  size={15}
                  style={{ top: "14px", left: "6px" }}
                />
                <Input
                  type="text"
                  palceholder="Search by Buyer Name / ID"
                  value={searchText}
                  onChange={(e) => {
                    setSearchText(e.target.value.toLowerCase());
                  }}
                  inputStyle={{ textIndent: "22px", padding: "8px 5px" }}
                />
                {/* <Button>Record Payment</Button> */}
              </div>
              <Button
                variant="contained"
                className={isMobile ? "ml-auto" : ""}
                onClick={() => {
                  sendNotificationsEvent();
                }}
                disabled={selectedBills.length == 0 ? true : false}
              >
                Send Notificaions
              </Button>
            </div>
          )}
          {tabsReq == "Requests" && (
            <>
              {supplyDemandDetails?.proposals ? (
                supplyDemandDetails?.proposals
                  .filter((doc) => doc.prgStatus == "ASSIGNED")
                  .map((req, index) => (
                    <div className="bg-white border br-10 py-4 px-2 md:px-4 block md:flex justify-between items-center mt-4">
                      <div
                        className="flex items-center gap-4 border-b md:border:none pb-2 md:pb-0"
                        style={{ width: isMobile ? "100%" : "30%" }}
                      >
                        <PartnerInfo
                          profilePic={req?.profilePic || ""}
                          name={req?.traderName || ""}
                          shortName={""}
                          partnerType={req?.tradeType}
                          partnerId={req?.traderId}
                          partnerMobile={""}
                          partyName={req?.addressLine}
                          distanceFrom={true}
                          distance={
                            req?.distance ? req?.distance.toFixed(2) : ""
                          }
                        />
                        {
                          req?.imgs && req.imgs.length > 0 && (
                            // req.imgs.map((img, i) => (
                            <div
                              className="relative cursor-pointer"
                              onClick={() => {
                                setImageGalleryOpen(true);
                                dispatch(
                                  updateProposalCropImgs({
                                    proposalCropImages: req.imgs,
                                  })
                                );
                              }}
                            >
                              <img
                                src={req.imgs[0].url}
                                alt=""
                                className="br-10 "
                                style={{ width: "120px", height: "70px" }}
                              />
                              {req.imgs.length > 1 && (
                                <div
                                  className="br-10 absolute top-0 flex justify-center items-center"
                                  style={{
                                    width: "70px",
                                    height: "70px",
                                    backgroundColor: "#000",
                                    opacity: "0.5",
                                    right: "-10px",
                                  }}
                                >
                                  <span className="text-sm text-white font-medium">
                                    +{req.imgs.length - 1}
                                  </span>
                                </div>
                              )}
                            </div>
                          )
                          // ))
                        }
                      </div>
                      <div
                        className="flex items-center gap-4 border-b md:border-none py-2"
                        style={{ width: isMobile ? "100%" : "40%" }}
                      >
                        <div
                          className="grow-[2] basis-0 flex px-2 flex-col"
                          style={{ width: isMobile ? "50%" : "20%" }}
                        >
                          <span className="text-sm">Expected Date</span>
                          <span className="text-sm font-semibold">
                            {moment(req.expDate).format("DD-MMM-YYYY") || "-"}
                          </span>
                        </div>
                        <div
                          className="grow-[2] basis-0 flex px-2 flex-col"
                          style={{ width: "20%" }}
                        >
                          <span className="text-sm">Offer Price</span>
                          <span className="font-semibold text-sm ">
                            Rs.{req.rate} Per KG
                          </span>
                        </div>
                        <div
                          className="grow-[2] basis-0  px-2 flex-col hidden md:flex"
                          style={{ width: isMobile ? "50%" : "20%" }}
                        >
                          <span className="text-sm">Offer Validity </span>
                          <span className="text-sm font-semibold">
                            {" "}
                            {req?.offerValidity || req?.validity
                              ? (req?.validity ? req?.validity + " | " : "") +
                                moment(req?.offerValidity).format("DD-MMM-YYYY")
                              : "-"}
                          </span>
                        </div>
                      </div>
                      <div className="flex md:hidden border-b py-2">
                        <div
                          className="grow-[2] basis-0 flex px-2 flex-col"
                          style={{ width: "100%" }}
                        >
                          <span className="text-sm">Offer Validity </span>
                          <span className="text-sm font-semibold">
                            {" "}
                            {req?.offerValidity || req?.validity
                              ? (req?.validity ? req?.validity + " | " : "") +
                                moment(req?.offerValidity).format("DD-MMM-YYYY")
                              : "-"}
                          </span>
                        </div>
                      </div>
                      <div className="flex items-center md:gap-4 py-2 md:py-0">
                        <div>
                          {supplyDemandDetails?.status != "ASSIGNED" && (
                            <img
                              src={edit}
                              className="cursor-pointer w-8 h-8"
                              onClick={() => {
                                setRequestPostEdit(true);
                                dispatch(
                                  updateTraderContact({
                                    traderContactInfo: req,
                                  })
                                );
                              }}
                            />
                          )}
                        </div>
                        <Button
                          variant="lightBlue"
                          className=""
                          onClick={() => {
                            setViewContactInfo(true);
                            dispatch(
                              updateTraderContact({
                                traderContactInfo: req,
                              })
                            );
                          }}
                        >
                          View Contact Info
                        </Button>
                        {/* <Button
                      variant="primaryLight"
                      className=""
                      onClick={() => {}}
                    >
                      Chat
                    </Button> */}
                        {supplyDemandDetails?.status != "ASSIGNED" && (
                          <Button
                            variant="contained"
                            className=""
                            onClick={() => {
                              setAssignOffTakerStatus(true);
                              setAropsalAccept(true);
                              dispatch(
                                updateTraderContact({
                                  traderContactInfo: req,
                                })
                              );
                            }}
                          >
                            Accept
                          </Button>
                        )}
                      </div>
                    </div>
                  ))
              ) : (
                <div className="flex items-center justify-center py-8 mt-4 ">
                  <img src={NodataAvailable} alt="image" />
                </div>
              )}
            </>
          )}
          {tabsReq == "Notifications" && (
            <div className="py-4">
              <SendNotification
                id={supplyDemandDetails?.id}
                type={"SUPPLY"}
                traderData={supplyDemandDetails?.traderNotifns}
                selectedBills={selectedBills}
                handleSelectAll={handleSelectAll}
                handleCheckboxChange={handleCheckboxChange}
                searchText={searchText}
                selectAll={selectAll}
              />
            </div>
          )}
        </div>
      </div>
      <div className="px-4 py-1">
        <div className="pb-2">
          <span className="rounded-t-lg border-green-600 whitespace-nowrap border-b-4 pb-2 font-medium color-primary">
            {supplyDemandDetails?.status == "ASSIGNED" &&
              supplyDemandDetails?.proposals &&
              supplyDemandDetails?.proposals.length > 1 &&
              supplyDemandDetails?.proposals.filter(
                (doc) => doc.prgStatus != "ASSIGNED"
              ) &&
              tabsReq == "Requests" && (
                <>
                  Others Requests (
                  {supplyDemandDetails?.propsCount &&
                  supplyDemandDetails?.proposals.some(
                    (doc) => doc.prgStatus === "ASSIGNED"
                  )
                    ? supplyDemandDetails?.proposals.length - 1
                    : supplyDemandDetails?.propsCount
                    ? supplyDemandDetails?.proposals.length
                    : "0"}
                  )
                </>
              )}
          </span>
          {supplyDemandDetails?.proposals &&
            tabsReq == "Requests" &&
            supplyDemandDetails?.proposals
              .filter((doc) => doc.prgStatus != "ASSIGNED")
              .map((req, index) => (
                <div className="bg-white border br-10 py-4 px-4 block md:flex justify-between items-center mt-4">
                  <div
                    className="flex items-center gap-4 border-b md:border-none pb-2 md:pb-0"
                    style={{ width: isMobile ? "100%" : "30%" }}
                  >
                    <PartnerInfo
                      profilePic={req?.profilePic || ""}
                      name={req?.traderName || ""}
                      shortName={""}
                      partnerType={req?.tradeType}
                      partnerId={req?.traderId}
                      partnerMobile={""}
                      partyName={req?.addressLine}
                      distanceFrom={true}
                      distance={req?.distance ? req?.distance.toFixed(2) : ""}
                    />
                    {
                      req?.imgs && req.imgs.length > 0 && (
                        // req.imgs.map((img, i) => (
                        <div
                          className="relative cursor-pointer"
                          onClick={() => {
                            setImageGalleryOpen(true);
                            dispatch(
                              updateProposalCropImgs({
                                proposalCropImages: req.imgs,
                              })
                            );
                          }}
                        >
                          <img
                            src={req.imgs[0].url}
                            alt=""
                            className="br-10 "
                            style={{ width: "120px", height: "70px" }}
                          />
                          {req.imgs.length > 1 && (
                            <div
                              className="br-10 absolute top-0 flex justify-center items-center"
                              style={{
                                width: "70px",
                                height: "70px",
                                backgroundColor: "#000",
                                opacity: "0.5",
                                right: "-10px",
                              }}
                            >
                              <span className="text-sm text-white font-medium">
                                +{req.imgs.length - 1}
                              </span>
                            </div>
                          )}
                        </div>
                      )
                      // ))
                    }
                  </div>
                  <div
                    className="flex items-center gap-4 border-b md:border-none py-2 md:py-0"
                    style={{ width: isMobile ? "100%" : "40%" }}
                  >
                    <div
                      className="grow-[2] basis-0 flex px-2 flex-col"
                      style={{ width: isMobile ? "50%" : "20%" }}
                    >
                      <span className="text-sm">Expected Date</span>
                      <span className="text-sm font-semibold">
                        {moment(req.expDate).format("DD-MMM-YYYY") || "-"}
                      </span>
                    </div>
                    <div
                      className="grow-[2] basis-0 flex px-2 flex-col"
                      style={{ width: isMobile ? "50%" : "20%" }}
                    >
                      <span className="text-sm">Offer Price</span>
                      <span className="font-semibold text-sm ">
                        Rs.{req.rate} Per KG
                      </span>
                    </div>
                    <div
                      className="grow-[2] basis-0 px-2 flex-col hidden md:flex"
                      style={{ width: "20%" }}
                    >
                      <span className="text-sm">Offer Validity </span>
                      <span className="text-sm font-semibold">
                        {" "}
                        {req?.offerValidity || req?.validity
                          ? (req?.validity ? req?.validity + " | " : "") +
                            moment(req?.offerValidity).format("DD-MMM-YYYY")
                          : "-"}
                      </span>
                    </div>
                  </div>
                  {req?.validity && (
                    <div className="flex md:hidden py-2 border-b">
                      <div
                        className="grow-[2] basis-0 px-2 flex flex-col"
                        style={{ width: "100%" }}
                      >
                        <span className="text-sm">Offer Validity </span>
                        <span className="text-sm font-semibold">
                          {" "}
                          {req?.offerValidity || req?.validity
                            ? (req?.validity ? req?.validity + " | " : "") +
                              moment(req?.offerValidity).format("DD-MMM-YYYY")
                            : "-"}
                        </span>
                      </div>
                    </div>
                  )}

                  <div className="flex items-center gap-4 py-2 md:py-0">
                    <div>
                      {supplyDemandDetails?.status != "ASSIGNED" && (
                        <img
                          src={edit}
                          className="cursor-pointer w-8 h-8"
                          onClick={() => {
                            setRequestPostEdit(true);
                            dispatch(
                              updateTraderContact({
                                traderContactInfo: req,
                              })
                            );
                          }}
                        />
                      )}
                    </div>
                    <Button
                      variant="lightBlue"
                      className=""
                      onClick={() => {
                        setViewContactInfo(true);
                        dispatch(
                          updateTraderContact({
                            traderContactInfo: req,
                          })
                        );
                      }}
                    >
                      View Contact Info
                    </Button>
                    {/* <Button
                      variant="primaryLight"
                      className=""
                      onClick={() => {}}
                    >
                      Chat
                    </Button> */}
                    {supplyDemandDetails?.status != "ASSIGNED" && (
                      <Button
                        variant="contained"
                        className=""
                        onClick={() => {
                          setAssignOffTakerStatus(true);
                          setAropsalAccept(true);
                          dispatch(
                            updateTraderContact({
                              traderContactInfo: req,
                            })
                          );
                        }}
                      >
                        Accept
                      </Button>
                    )}
                  </div>
                </div>
              ))}
        </div>
      </div>
      {supplyPostEdit && (
        <>
          <Modal
            open={supplyPostEdit}
            onClose={() => {
              setSupplyPostEdit(false);
            }}
            width={isMobile ? "100% " : "40vw"}
          >
            <>
              <ModalHeader
                title={`Edit Supply Post ID: ${supplyDemandDetails?.id}`}
                content={<></>}
              />
              <div className="py-3 px-3 md:px-6">
                <EditSupplyPost
                  onModalClose={setSupplyPostEdit}
                  type="Supply"
                />
              </div>
            </>
          </Modal>
        </>
      )}
      {viewContactInfo && (
        <>
          <Modal
            open={viewContactInfo}
            onClose={() => {
              setViewContactInfo(false);
            }}
            width={isMobile ? "100% " : "40vw"}
          >
            <>
              <ModalHeader title={"Seller Contact Info"} content={<></>} />
              <div className="py-3 px-3 md:px-6">
                <ContactInfo onModalClose={setViewContactInfo} />
              </div>
            </>
          </Modal>
        </>
      )}
      {requestPostEdit && (
        <>
          <Modal
            open={requestPostEdit}
            onClose={() => {
              setRequestPostEdit(false);
            }}
            width={isMobile ? "100% " : "40vw"}
          >
            <>
              <ModalHeader title={"Edit Request "} content={<></>} />
              <div className="py-3 px-3 md:px-6">
                <EditPostRequest
                  onModalClose={setRequestPostEdit}
                  type={"SUPPLY"}
                />
              </div>
            </>
          </Modal>
        </>
      )}
      {assignOffTakerStatus && (
        <>
          <Modal
            open={assignOffTakerStatus}
            onClose={() => {
              setAssignOffTakerStatus(false);
              setAropsalAccept(false);
            }}
            width={isMobile ? "100% " : "50vw"}
          >
            <>
              <ModalHeader
                title={`Supply Post ID : ${supplyDemandDetails?.id}  `}
                content={<></>}
              />
              <div className="py-3 px-3 md:px-6">
                <AssignOffTaker
                  onModalClose={setAssignOffTakerStatus}
                  type={"SUPPLY"}
                  fromPropsal={propsalAccept}
                  onFalsePropsal={setAropsalAccept}
                />
              </div>
            </>
          </Modal>
        </>
      )}
      {imageGalleryOpen && (
        <>
          <Modal
            open={imageGalleryOpen}
            onClose={() => {
              setImageGalleryOpen(false);
            }}
            width={isMobile ? "100% " : "40vw"}
          >
            <>
              <ModalHeader
                title={supplyDemandDetails?.cropName}
                content={<></>}
              />
              <div className="py-3 px-3 md:px-6">
                <CropImageGallery onModalClose={setImageGalleryOpen} />
              </div>
            </>
          </Modal>
        </>
      )}
    </div>
  );
};

export default SupplyPostView;
