import { FC, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa6";
import { FaBell } from "react-icons/fa6";
import { deviceInfoContext } from "../../../context";
import logo from "../../../assets/images/mandi-logo.svg";
import DefultLogo from "../../../assets/images/single_bill.svg";
import { useSelector } from "react-redux";
import { TRootState } from "../../../store";
import getTradeStatus from "../../../helpers/getTradeStatus";
const PageHeader: FC<{
  title?: string;
  showBackButton?: boolean;
  showTitle?: boolean;
  showUserInfo?: boolean;
  topVal?: string;
  fromScreen?: string;
  subText?: string;
  imgsrc?: string;
}> = ({
  title,
  showBackButton,
  showTitle,
  showUserInfo,
  topVal,
  fromScreen,
  subText,
}) => {
  const navigate = useNavigate();
  const { width } = useContext(deviceInfoContext);
  const { name, traderId, tradeType, loggedin } = useSelector(
    (state: TRootState) => ({
      name: "",
      traderId: "",
      tradeType: "",
      loggedin: state.auth.loggedin,
    })
  );
  const { adminId } = useSelector((state: TRootState) => ({
    adminId: state.auth.adminId,
  }));
  const back = () => {
    if (showTitle === true && fromScreen == "View Trade") {
      navigate("/");
    } else {
      navigate(-1);
    }
  };
  return (
    <>
      <div
        className="bg-white md:shadow-md flex items-center pr-2 font-semibold w-full sticky z-[999] md:static md:z-auto"
        style={{ height: "3.8rem", top: topVal }}
      >
        {showBackButton === true && (
          <div
            className="pr-3 pl-2 cursor-pointer h-full flex"
            onClick={() => {
              back();
            }}
          >
            <FaArrowLeft className="mt-auto mb-auto" />
          </div>
        )}
        {showTitle === true && (
          <div className="px-2 flex flex-col">
            <span>{title}</span>
          </div>
        )}
        {showUserInfo === true && (
          <div
            className="flex gap-2 cursor-pointer items-center ml-auto md:mr-4"
            onClick={() => {
              navigate("/my-profile");
            }}
          >
            <img src={DefultLogo} className="w-8 h-8" />
            <div className="flex flex-col text-xs">
              <span>{name}</span>
              <span className="hidden md:block">{` User Id :  ${adminId}`}</span>
            </div>
          </div>
        )}
        {loggedin === false && (
          <span className="flex ml-auto gap-2">
            {/* <span className="px-2 border-r-2">
            <FaBell className="color-primary" />
          </span> */}
            <img src={logo} className="ml-auto w-16 px-1" />
          </span>
        )}
      </div>
    </>
  );
};
export default PageHeader;
