import {
  Button,
  FileUploader,
  Input,
  Popover,
  RadioButton,
  Slider,
} from "../../../../components/ui";
import EmptyProfile from "../../../../assets/images/profile.jpg";
import SelectDate from "../../../../components/ui/select-date";
import { useDispatch, useSelector } from "react-redux";
import { TRootState } from "../../../../store";
import moment from "moment";
import { setSelectedDate } from "../../../../reducers/tradeSlice";
import { useEffect, useState } from "react";
import PartnerInfo from "../../../../components/partner-info";
import Modal from "../../../../components/ui/modal";
import ModalHeader from "../../../../components/ui/modal/modal-header";
import SelectBillModal from "../Select-bill/SelectBillModal";
import useApplicationViewById from "../application-view/useApplicationViewById";
import { TApplicationViewDetails, TBillsInfo } from "../../../../types";
import Select2 from "../../../../components/ui/select2";
import { getCurrencyNumberWithSymbol } from "../../../../helpers/get-currency-number";
import ModalFooter from "../../../../components/ui/modal/modal-footer";
import { FaChevronLeft, FaCircleCheck, FaXmark } from "react-icons/fa6";
import { FaSearch } from "react-icons/fa";
import { IoMdCloseCircleOutline } from "react-icons/io";
import date_icon from "../../../assets/images/date_icon.svg";
import Calender from "../../../../components/commons/calender";
import classes from "./style.module.scss";
import useAllBills from "../Bills/useBills";
import DocumentPreview from "../../../../components/commons/document-preview";
import disbursed from '../../../../assets/images/loanDisburded.svg';
import Spinner from 'react-bootstrap/Spinner';

const AdvDisbursalModal: React.FC<{
  applicationData: TApplicationViewDetails | null;
  disbModalClose?: any;
}> = ({ applicationData, disbModalClose }) => {

  const {
    submitDisbursement,
    recordDisbursalData,
    setRecordDisbursalData,
    calenderOpen,
    setCalenderOpen,
    handleDateSelection,
    onUploadReceiptImage,
    onRemoveReceiptImage,
    applicationViewDetails,
    PaymentDoc,
    inputValue,
    setInputValue,
    loanModal,
    setLoanModal,
    selectedBills,
    setSelectedBills,
    loader,
    setLoader
  } = useApplicationViewById({
    applicationId: parseFloat(
      applicationData?.disbId ? applicationData?.disbId.toString() : "0"
    ),
    disbModalClose: disbModalClose,
  });
  const { getBills, billArray } = useAllBills({
    bills: applicationData?.bills || [],
  });

  useEffect(() => {
    getBills();
  }, []);

  const handleBillClick = (bill: TBillsInfo) => {
    setSelectedBills((prevSelected) =>
      prevSelected.some((selectedBill) => selectedBill.billId === bill.billId)
        ? prevSelected.filter((selectedBill) => selectedBill.billId !== bill.billId)
        : [...prevSelected, bill]
    );
  };
  const handleSubmit = () => {
    const selectedIds = selectedBills.map((bill) => bill.billId).join(',');
    setInputValue(selectedIds);
  };


  const onBillSelectComplete = () => {
    setStep(0);
  };
  const onBillSelectBack = () => {
    setStep(0);
  };
  const [openSelectBill, setOpenSelectBill] = useState(false);
  const [step, setStep] = useState(0);
  const [searchText, setSearchText] = useState("");

  const [filterBills, setFilterBills] = useState(billArray);

  const filterTraders = () => {
    let filtered = billArray;
    const trimmedSearchText = searchText.trim().toLowerCase();
    if (searchText.length > 0) {
      filtered = filtered.filter(
        (option) =>
          (option.billId && option.billId.toString().toLowerCase().includes(trimmedSearchText)) ||
          (option.caBSeq && option.caBSeq.toString().toLowerCase().includes(trimmedSearchText))
      );
    }
    setFilterBills(filtered);
  }
  const handleClear = () => {
    setSearchText("");
    setFilterBills([]);
  }

  useEffect(() => {
    filterTraders();
  }, [filterBills, billArray])
  return (
    <>
      <Slider step={step} fullHeight={true}>
        <div>
          <div
            className="px-8 py-4"
            style={{
              height: "calc(100vh - 16rem)",
              overflowY: "auto",
              width: "100%",
            }}
            id="scroll_style"
          >
            <div
              className="grow-[2] border br-10 basis-0 flex flex-col px-6 py-4"
              style={{ width: "100%" }}
            >
              <PartnerInfo
                profilePic={applicationData?.profilePic || EmptyProfile}
                name={applicationData?.partyName || ""}
                shortName={applicationData?.shortCode || ""}
                partnerType={applicationData?.partyType + " " + "ID"}
                partnerId={applicationData?.partyId}
                partnerMobile={applicationData?.partyMobile || ""}
                partyName={applicationData?.addressLine}
                fromLoansParty={true}
              />
            </div>

            <div
              className="grow-[2] basis-0 flex flex-col pt-4"
              style={{ width: "100%" }}
            >
              <span className="text-sm pb-1">Bank Name</span>
              <Input
                className="text-sm font-semibold"
                type="text"
                value={applicationData?.bankName}
                disabled
                inputStyle={{ width: "100%" }}
              />
            </div>

            <div className="flex" style={{ width: "100%" }}>
              <div
                className="grow-[2] basis-0 flex flex-col pt-4"
                style={{ width: "50%" }}
              >
                <span className="text-sm pb-1">Bank Account Number</span>
                <Input
                  className="text-sm font-semibold"
                  type="text"
                  value={applicationData?.accNum}
                  disabled
                />
              </div>

              <div
                className="grow-[2] basis-0 flex flex-col pt-4 pl-4"
                style={{ width: "50%" }}
              >
                <span className="text-sm pb-1">IFSC Code</span>
                <Input
                  className="text-sm font-semibold"
                  type="text"
                  value={applicationData?.ifscCode}
                  disabled
                />
              </div>
            </div>

            <div className="flex" style={{ width: "100%" }}>
              <div
                className="grow-[2] basis-0 flex flex-col pt-4"
                style={{ width: "50%" }}
              >
                <span className="text-sm pb-1">Disbursement Date</span>
                <SelectDate
                  onChange={handleDateSelection}
                  selectedDate={recordDisbursalData?.disbDate}
                  styles={{ padding: "14px" }}
                />

              </div>

              <div
                className="grow-[2] basis-0 flex flex-col pt-4 pl-4"
                style={{ width: "50%" }}
              >
                <span className="text-sm pb-1">
                  Select Bills{" "}
                  <span
                    className="text-sm color-blue cursor-pointer"
                    onClick={() => {
                      setStep(1);
                    }}
                  >
                    See All
                  </span>
                </span>
                <Input
                  className="text-sm font-semibold"
                  type="text"
                  value={inputValue}
                />
              </div>
            </div>
            <div
              className="grow-[2] basis-0 flex flex-col pt-4"
              style={{ width: "100%" }}
            >
              <span className="text-sm pb-1">Advance Disbursal Amount</span>
              <Input
                className="text-sm font-semibold"
                type="text"
                value={recordDisbursalData?.disbAmt}
                onChange={(e) => {
                  const value = e.target.value;
                  setRecordDisbursalData({
                    ...recordDisbursalData,
                    disbAmt: value === "" ? "" : parseFloat(value) || 0,
                  });
                }}
                inputStyle={{ width: "100%" }}
              />
            </div>

            <div className="flex" style={{ width: "100%" }}>
              <div
                className="grow-[2] basis-0 flex flex-col pt-4"
                style={{ width: "30%" }}
              >
                <span className="text-sm pb-1">Amount Requested</span>
                <Input
                  className="text-sm font-semibold"
                  type="text"
                  value={
                    getCurrencyNumberWithSymbol(applicationData?.tradeAdv) || "0"
                  }
                />
              </div>

              <div
                className="grow-[2] basis-0 flex flex-col pt-4 pl-4"
                style={{ width: "30%" }}
              >
                <span className="text-sm pb-1">CASH Limit</span>
                <Input
                  className="text-sm font-semibold"
                  type="text"
                  value={
                    getCurrencyNumberWithSymbol(
                      applicationData?.eligibleLimit
                    ) || "0"
                  }
                />
              </div>

              <div
                className="grow-[2] basis-0 flex flex-col pt-4 pl-4"
                style={{ width: "30%" }}
              >
                <span className="text-sm pb-1">CASH Available</span>
                <Input
                  className="text-sm font-semibold"
                  type="text"
                  value={
                    getCurrencyNumberWithSymbol(
                      applicationData?.availableLimit
                    ) || "0"
                  }
                />
              </div>
            </div>
            <div
              className="grow-[2] basis-0 flex flex-col pt-4"
              style={{ width: "100%" }}
            >
              <span className="text-sm">Payment Mode</span>
              <RadioButton
                displayType="horizontal"
                data={[
                  {
                    label: "IMPS",
                    value: "IMPS",
                  },
                  {
                    label: "NEFT",
                    value: "NEFT",
                  },
                  {
                    label: "UPI",
                    value: "UPI",
                  },
                  {
                    label: "RTGS",
                    value: "RTGS",
                  },
                  {
                    label: "CASH",
                    value: "CASH",
                  },
                  {
                    label: "CHEQUE",
                    value: "CHEQUE",
                  },
                ]}
                value={recordDisbursalData.pmtMode}
                pointer={{ label: "label", value: "value" }}
                onChange={(selectedPaymentMode) => {
                  setRecordDisbursalData({
                    ...recordDisbursalData,
                    pmtMode: selectedPaymentMode,
                  });
                }}
                styles={{ display: "flex" }}
              />
            </div>
            <div
              className="grow-[2] basis-0 flex flex-col pt-4"
              style={{ width: "100%" }}
            >
              <span className="text-sm pb-1">Comments</span>
              <Input
                className="text-sm font-semibold"
                type="text"
                value={recordDisbursalData?.comment}
                onChange={(e) => {
                  setRecordDisbursalData({
                    ...recordDisbursalData,
                    comment: e.target.value,
                  });
                }}
                inputStyle={{ width: "100%" }}
              />
            </div>

            <div
              className="grow-[2] basis-0 flex flex-col pt-4"
              style={{ width: "100%" }}
            >
              <span className="text-sm pb-1">Upload Payment Receipt</span>

              <div className="flex flex-row overflow-auto w-full hide-scroll-bar">
                <div
                  className={`${PaymentDoc.length > 0
                    ? "w-1/2"
                    : "w-full"
                    } shrink-0 px-2`}
                >
                  <FileUploader
                    onChange={(e) => {
                      if (e.target.files) {
                        onUploadReceiptImage("PAYMENT_RECEIPT", e.target.files);
                      }
                    }}
                  />
                </div>
                {applicationViewDetails?.kycDocs != null &&
                  applicationViewDetails?.kycDocs
                    .filter((doc) => doc.docType == "PAYMENT_RECEIPT" && doc.status != 0)
                    .map((image, i) => (
                      <div
                        className="shrink-0 overflow-hidden p-2 rounded relative"
                        style={{
                          width: "40%",
                          height: "10rem",
                        }}
                        key={`PAYMENT_RECEIPT-${i}`}
                      >
                        <a href={image.docUrl} target="_blank">
                          <DocumentPreview
                            url={image.docUrl}
                            docType={
                              image.docUrl.includes(".pdf") ? "pdf" : "image"
                            }
                          />
                        </a>
                        <span
                          className="absolute right-1 top-1 p-1 rounded-full cursor-pointer"
                          style={{
                            background: "#ff0000de",
                          }}
                          onClick={() => {
                            onRemoveReceiptImage(image.docUrl, true, image.id);
                          }}
                        >
                          <FaXmark style={{ color: "white" }} />
                        </span>
                      </div>
                    ))}
                {PaymentDoc
                  .filter((doc) => doc.type == "PAYMENT_RECEIPT")
                  .map((image, i) => (
                    <div
                      className="shrink-0 overflow-hidden p-2 rounded relative"
                      style={{
                        width: "40%",
                        height: "10rem",
                      }}
                      key={`PAYMENT_RECEIPT-${i}`}
                    >
                      <a href={image.url} target="_blank">
                        <DocumentPreview
                          url={image.url}
                          docType={
                            image.url.includes(".pdf") ? "pdf" : "image"
                          }
                        />
                      </a>
                      <span
                        className="absolute right-1 top-1 p-1 rounded-full cursor-pointer"
                        style={{
                          background: "#ff0000de",
                        }}
                        onClick={() => {
                          onRemoveReceiptImage(image.url);
                        }}
                      >
                        <FaXmark style={{ color: "white" }} />
                      </span>
                    </div>
                  ))}

              </div>
            </div>
          </div>
          <div className="border-b px-4"></div>

          <div className="modal-header flex justify-between items-center py-4 px-6">
            <div>
              <Button
                text="CANCEL"
                className="w-[100px] color-primary"
                variant="transparent"
                onClick={() => {
                  setLoanModal(false);
                }}
              />
            </div>
            <div>
              <Button
                className="w-[200px]"
                disabled={loader}
                onClick={() => {
                  if (!loader) submitDisbursement();
                }}
              >
                {loader && (
                  <Spinner animation="border" role="status" size="sm" className="me-2" />
                )}
                SUBMIT
              </Button>
            </div>
          </div>
        </div>
        <div className="px-4 mt-2 h-full relative">
          <div className="flex gap-6">
            <div className="grow">
              <div
                className="grow-[2] basis-0 flex flex-col pt-4"
                style={{ width: "100%" }}
              >
                <div className=" relative">
                  <FaSearch
                    className=" absolute color-primary"
                    size={15}
                    style={{ top: "16px", left: "6px" }}
                  />
                  <Input
                    type="text"
                    palceholder="Search by Bill Id"
                    value={searchText}
                    onChange={(e) => { setSearchText(e.target.value) }}
                    inputStyle={{
                      textIndent: "22px",
                      padding: "10px 7px",
                    }}
                    style={{
                      width: "100%",
                    }}
                  />
                  <div>
                    <IoMdCloseCircleOutline
                      onClick={() => { handleClear() }}
                      className="absolute color-primary cursor-pointer w-10"
                      style={{
                        top: "32%",
                        right: "1rem",
                        fontSize: "1.2rem",
                        color: "gray",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex border rounded-md mt-3 py-2">
            <div className="grow flex flex-col items-center border-r text-xs">
              <span className="font-semibold">Amount Requested</span>
              <span>
                {getCurrencyNumberWithSymbol(applicationData?.disbAmt, true)}
              </span>
            </div>
            <div className="grow flex flex-col items-center text-xs">
              <span className="font-semibold">Disbursal Amount </span>
              <span>
                {getCurrencyNumberWithSymbol(applicationData?.disbAmt, true)}
              </span>
            </div>
          </div>
          <div className="mt-2">
            <div
              className="px-2 rounded-t-lg"
              style={{ background: "var(--ono-select-background)" }}
            >
              <div className="flex text-sm font-semibold h-10 items-center">
                <span className="inline-flex px-2" style={{ width: "4rem" }}>
                  Bill Id
                </span>
                <span className="grow-[2] shrink-0 basis-0 px-2 text-center">
                  Date
                </span>
                <span className="grow-[2] shrink-0 basis-0 px-2 text-center">
                  Total Bill Amount
                </span>
                <span className="grow-[2] shrink-0 basis-0 px-2 text-center">
                  Paid Amount
                </span>
              </div>
            </div>
            <div
              className="border px-2 overflow-auto"
              style={{ maxHeight: "calc(70vh - 21rem)" }}
              id="scroll_style"
            >
              {(searchText
                ? filterBills.filter((bill) => {
                  return (
                    bill.caBSeq.toString().includes(searchText) ||
                    moment(bill.billDate)
                      .format("DD MMM YYYY")
                      .includes(searchText)
                  );
                })
                : filterBills
              ).map((bill) => {
                const selected = selectedBills.some((selectedBill) => selectedBill.billId === bill.billId);
                return (
                  <div
                    key={bill.billId}
                    className={`flex text-xs h-10 items-center border my-2 br-10 cursor-pointer ${classes.billItem}`}
                    data-selected={selected}
                    onClick={() => {
                      handleBillClick(bill);
                    }}
                  >
                    <span
                      className={`inline-flex px-2 ${selected ? 'text-green-500' : 'text-black'}
                      style={{ width: "4rem" }}`}
                    >
                      {bill.caBSeq}
                    </span>
                    <span className={`grow-[2] shrink-0 basis-0 px-2 text-center ${selected ? 'text-green-500' : 'text-black'}`}>
                      {moment(bill.billDate).format("DD MMM YYYY")}
                    </span>
                    <span className={`grow-[2] shrink-0 basis-0 px-2 text-center ${selected ? 'text-green-500' : 'text-black'}`}>
                      {getCurrencyNumberWithSymbol(bill.billAmt, true)}
                    </span>
                    <span className="grow-[2] shrink-0 basis-0 px-2 text-center relative color-red">
                      {selected === true && (
                        <FaCircleCheck
                          className={`absolute right-2 text-xl z-10 ${"color-primary"}`}
                          style={{ top: "-8px" }}
                        />
                      )}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
          <ModalFooter>
            <Button
              variant="transparent"
              onClick={() => {
                onBillSelectBack();
              }}
            >
              <FaChevronLeft />
              Back
            </Button>
            <Button onClick={() => {
              onBillSelectComplete();
              handleSubmit();
            }} className="ml-auto">
              Update
            </Button>
          </ModalFooter>
        </div>
      </Slider>
      <div>
        <Modal
          open={loanModal}
          onClose={() => {
            setLoanModal(false);
          }}
          width="30vw"
          height="40vh"
        >
          <>
            <div className="flex h-full items-center justify-center">
              <div className="flex flex-col items-center px-4 py-4">
                <img src={disbursed} alt="loan disbursed" className="mb-4" />
                <h1 className="text-primary text-lg font-bold">Loan Successfully Disbursed!</h1>
              </div>
            </div>
          </>
        </Modal>
      </div>
    </>
  );
};
export default AdvDisbursalModal;
