import { ChangeEvent, FC, useEffect, useState } from "react";
import {
  Button,
  Input,
  PanelSlider,
  Select2,
  TabsList,
} from "../../components/ui";
import {
  getCurrencyNumberWithOutSymbol,
  getCurrencyNumberWithSymbol,
} from "../../helpers/get-currency-number";
import usePaymentDetail from "./usePaymentDetail";
import { TRootState } from "../../store";
import nodata from "../../assets/images/NodataAvailable.svg";
import moment from "moment";
import classes from "./style.module.scss";
import { FaEdit } from "react-icons/fa";
import Modal from "../../components/ui/modal";
import ModalHeader from "../../components/ui/modal/modal-header";
import SelectDate from "../../components/ui/select-date";
import useArrivalsView from "./useArrivalsView";
import { useParams } from "react-router-dom";
import SelectDateDisable from "../../components/ui/date-disabled";
import { useDispatch } from "react-redux";
import cancelIcon from "../../assets/images/close.svg";
import {
  getPaymentHistory,
  updateEditAdvPayment,
  updatePaymentUpdateStatus,
} from "../../reducers/tradeSlice";
import RecordPayment from "./record-payment";
import PaymentHistory from "./payment-history";
import { FaExpand } from "react-icons/fa6";
import DocumentPreview from "../../components/commons/document-preview";
const Paymentdetail: FC<{
  tradeId: number;
  activeTab: string;
  onChangeTab: (tab: string) => void;
  roleType: string;
}> = ({ tradeId, activeTab, onChangeTab, roleType }) => {
  const [tradeType, setTradeType] = useState("");
  const dispatch = useDispatch();
  const [recordFrom, setRecordFrom] = useState("INWARD");
  const { setActiveTab, tradeDetails, inwardPayment, outWardPayment } =
    usePaymentDetail({ tradeId: tradeId });
  useEffect(() => {
    setActiveTab(activeTab);
    setTradeType(activeTab == "inward_payment" ? "DEST" : "SOURCE");
    setRecordFrom(activeTab == "inward_payment" ? "INWARD" : "OUTWARD");
  }, [activeTab]);
  const params = useParams();
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [showPaymentFormDest, setShowPaymentFormDest] = useState(false);
  const [openRecordPayment, setOpenRecordPayment] = useState(false);
  const [openPaymenHistory, setOpenPaymenHistory] = useState(false);
  const {
    onUploadReceiptImage,
    pattiDocuments,
    onRemoveReceiptImage,
    alltradeDetail,
    setArrivalIInfo,
    arrivalIInfo,
    confrirmArrival,
    handlePattiDateSelection,
    reqAdvInfo,
    setReqAdvInfo,
  } = useArrivalsView({
    traderId: parseInt(params.traderId || "0"),
    tradeId: parseInt(params.tradeId || "0"),
    mode: true,
    type: "PAYMENT",
    typeFrom: tradeType,
    closeModal:
      tradeType == "DEST" ? setShowPaymentFormDest : setShowPaymentForm,
  });
  useEffect(() => {
    if (arrivalIInfo != null) {
      setArrivalIInfo({
        ...arrivalIInfo,
        delayedChargesPer: parseFloat(
          calculatePercentagefromTotal(
            arrivalIInfo?.delayedChargesVal !== ""
              ? Number(arrivalIInfo?.delayedChargesVal)
              : 0,
            Number(arrivalIInfo?.balPayable)
          ).toFixed(2)
        ),
        delayedChargesVal:
          arrivalIInfo?.delayedChargesVal !== ""
            ? arrivalIInfo?.delayedChargesVal
            : "",
      });
    }
  }, []);
  const addDays = (date: any, days: any) => {
    var dateVal = new Date(date);
    var result = "";
    dateVal.setDate(dateVal.getDate() + parseInt(days));
    result = moment(dateVal).format("DD-MMM-YYYY");
    return result;
  };
  const onChangeDelayChargesTotal = (e: ChangeEvent<HTMLInputElement>) => {
    setArrivalIInfo({
      ...arrivalIInfo,
      delayedChargesPer: parseFloat(
        calculatePercentagefromTotal(
          e.target.value !== "" ? Number(e.target.value) : 0,
          Number(alltradeDetail?.balPayable)
        ).toFixed(2)
      ),
      delayedChargesVal:
        e.target.value !== "" ? parseFloat(e.target.value) : "",
    });
  };
  const onChangeDelayCharges = (e: ChangeEvent<HTMLInputElement>) => {
    setArrivalIInfo({
      ...arrivalIInfo,
      delayedChargesVal: parseFloat(
        calculateTotalFromPercentage(
          e.target.value !== "" ? parseFloat(e.target.value) : 0,
          Number(alltradeDetail?.balPayable)
        ).toFixed(2)
      ),
      delayedChargesPer:
        e.target.value !== "" ? parseFloat(e.target.value) : "",
    });
  };
  const calculateTotalFromPercentage = (
    _percentage: number,
    total: number
  ): number => (_percentage / 100) * total;
  const calculatePercentagefromTotal = (part: number, total: number) => {
    return (part / total) * 100;
  };
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Or use any breakpoint you prefer
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div className="relative">
      <div className="flex justify-between">
        <TabsList
          data={[
            { label: "Source Payment", value: "outward_payment" },
            { label: "Destination Payment", value: "inward_payment" },
          ]}
          activeTab={activeTab}
          onChange={(tab) => {
            onChangeTab(tab.value);
          }}
        />
        {activeTab === "inward_payment" ? (
          <>
            {tradeDetails?.tradeStatus !== "CANCEL" &&
              tradeDetails?.tradeStatus !== "CLOSED" &&
              roleType == "ADMIN" && (
                <div className="hidden md:flex gap-2 ml-auto">
                  <Button
                    onClick={() => {
                      setOpenRecordPayment(true);
                      dispatch(
                        updatePaymentUpdateStatus({
                          paymentUpdateStatus: false,
                        })
                      );
                      dispatch(
                        updateEditAdvPayment({ editAdvancePayment: false })
                      );
                      dispatch(
                        getPaymentHistory({
                          paymentHistoryObj: null,
                        })
                      );
                    }}
                  >
                    RECORD RECEIVABLE
                  </Button>
                </div>
              )}
          </>
        ) : (
          <>
            {tradeDetails?.tradeStatus !== "CANCEL" &&
              tradeDetails?.tradeStatus !== "CLOSED" &&
              roleType == "ADMIN" && (
                <div className="hidden md:flex gap-2 ml-auto">
                  <Button
                    onClick={() => {
                      setOpenRecordPayment(true);
                      dispatch(
                        updatePaymentUpdateStatus({
                          paymentUpdateStatus: false,
                        })
                      );
                      dispatch(
                        updateEditAdvPayment({ editAdvancePayment: false })
                      );
                      dispatch(
                        getPaymentHistory({
                          paymentHistoryObj: null,
                        })
                      );
                    }}
                    variant={"danger"}
                  >
                    RECORD PAYMENT
                  </Button>
                </div>
              )}
          </>
        )}
      </div>
      {activeTab === "inward_payment" ? (
        <>
          <div className="flex align-middle bg-white mt-5 ">
            <div className="pr-4 flex-1 hidden md:flex flex-col justify-center items-center p-3 ">
              <span className="text-blue-500 text-sm font-semibold">
                Trader Id : <span>{tradeDetails?.destPartyId}</span>
              </span>
              <span className="text-sm text-center">
                To:{" "}
                <span className="font-semibold">
                  {tradeDetails?.destTradeName}
                </span>
              </span>
            </div>
            {/* <div className="border-l flex-1 flex flex-col justify-center items-center p-3">
              <span className="text-sm font-light">
                {" "}
                {tradeDetails?.tradeMode
                  ? tradeDetails?.tradeMode == "ORDER"
                    ? "Invoice Amount"
                    : "Sales Amount"
                  : "Sales Amount"}
                :
              </span>
              <span className="font-semibold">
                {tradeDetails?.tradeMode
                  ? tradeDetails?.tradeMode == "ORDER"
                    ? getCurrencyNumberWithSymbol(
                        tradeDetails?.invAmt || 0,
                        true
                      )
                    : getCurrencyNumberWithSymbol(
                        tradeDetails?.pattiAmt || 0,
                        true
                      )
                  : getCurrencyNumberWithSymbol(
                      tradeDetails?.pattiAmt || 0,
                      true
                    )}
              </span>
            </div> */}
            <div className="border-l flex-1 flex flex-col justify-center items-center p-3">
              <span className="text-sm font-light">Final Payable </span>
              <span className="font-semibold">
                {getCurrencyNumberWithSymbol(
                  Number(alltradeDetail?.totalPayable) +
                    Number(alltradeDetail?.destPf) -
                    Number(alltradeDetail?.destDis) || 0,
                  true
                )}
              </span>
            </div>
            <div className="border-l flex-1 flex flex-col justify-center items-center p-3 ">
              <span className="text-sm font-light">Paid Till Date </span>
              <span className="font-semibold">
                {getCurrencyNumberWithSymbol(
                  tradeDetails?.inwardPmt || 0,
                  true
                )}
              </span>
            </div>
            <div className="p-3 flex-1 flex flex-col justify-center items-center border-l">
              <span className="text-sm font-light">Balance Payable </span>
              <span className="text-sm color-red font-semibold">
                {getCurrencyNumberWithSymbol(
                  (Number(alltradeDetail?.totalPayable) +
                    Number(alltradeDetail?.destPf) -
                    Number(alltradeDetail?.destDis) || 0) -
                    (tradeDetails?.inwardPmt || 0),
                  true
                )}
              </span>
            </div>
          </div>

          <div className="my-3 flex flex-col md:flex-row md:w-full gap-4">
            <div style={{ width: isMobile ? "100%" : "40%" }}>
              <div className="py-3 br-10 bg-white px-3">
                <div className="flex gap-2 border-b pb-3 justify-between items-center ">
                  <span className="text-sm font-medium color-primary">
                    Payment Terms:
                  </span>
                  {alltradeDetail?.tradeStatus !== "CLOSED" &&
                    roleType == "ADMIN" && (
                      <Button
                        variant="outlined"
                        className="ml-auto gap-1"
                        size="small"
                        onClick={() => {
                          // setEditDetail({
                          //   ...editDetail,
                          //   section: "arival_document",
                          //   mode: "edit",
                          // });
                          setShowPaymentFormDest(true);
                        }}
                      >
                        <FaEdit className="" />
                        Edit
                      </Button>
                    )}
                </div>
                <div className="flex pt-3 justify-between">
                  <span className="flex flex-col">
                    <span className="color-sub-text text-xs">Terms</span>
                    <span className={"font-medium text-sm"}>
                      {tradeDetails?.destPmtTerm || "-"}
                    </span>
                  </span>
                  <span className="flex flex-col">
                    <span className="color-sub-text text-xs">Due Date</span>
                    <span className={"font-medium text-sm"}>
                      {(alltradeDetail?.arlDate &&
                        alltradeDetail?.srcPmtTerm &&
                        addDays(
                          alltradeDetail?.arlDate,
                          alltradeDetail?.destPmtTerm || "7"
                        )) ||
                        "-"}
                    </span>
                  </span>
                </div>

                <div className="flex py-3 justify-between ">
                  <span className="flex flex-col">
                    <span className="color-sub-text text-xs">
                      Delayed Payment Charges
                    </span>
                    <span className={"font-medium text-sm"}>
                      {getCurrencyNumberWithSymbol(
                        tradeDetails?.destDelayCharges
                      ) || "-"}
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <div
              style={{ width: isMobile ? "100%" : "60%" }}
              className="overflow-x-auto"
            >
              <table className="table-auto w-full border-collapse border text-sm bg-white">
                <thead className="bg-light-primary text-left sticky top-0 z-10 font-medium">
                  <th className="text-center px-2 font-semibold">#</th>

                  <th
                    className="px-2 py-2 font-semibold"
                    style={{ minWidth: isMobile ? "120px" : "" }}
                  >
                    Ref ID | Date
                  </th>
                  <th
                    className="px-2 py-2 font-semibold"
                    style={{ minWidth: isMobile ? "120px" : "" }}
                  >
                    Payment Type
                  </th>
                  <th
                    className="px-2 py-2 font-semibold"
                    style={{ minWidth: isMobile ? "140px" : "" }}
                  >
                    Amount Paid(₹)
                  </th>
                </thead>
                {alltradeDetail?.destPmtHistory &&
                alltradeDetail?.destPmtHistory.length > 0 ? (
                  <tbody
                    className="overflow-auto bg-white"
                    // style={{ height: "calc(100vh - 33rem)" }}
                  >
                    <>
                      {alltradeDetail?.destPmtHistory.map(
                        (transaction: any, i) => (
                          <tr>
                            <td className="text-center px-2">{i + 1}</td>
                            <td className="px-2 text-xs">
                              <span className="flex flex-col">
                                <span
                                  className="color-blue cursor-pointer font-medium"
                                  onClick={() => {
                                    // navigate(`/payment-history/${transaction?.id}`);
                                    setOpenPaymenHistory(true);
                                    dispatch(
                                      getPaymentHistory({
                                        paymentHistoryObj: transaction,
                                      })
                                    );
                                    dispatch(
                                      updatePaymentUpdateStatus({
                                        paymentUpdateStatus: true,
                                      })
                                    );
                                  }}
                                >
                                  {transaction.id}
                                </span>
                                <span className="font-medium text-xs">
                                  {moment(transaction.date).format(
                                    "DD-MMM-YYYY"
                                  )}
                                </span>
                              </span>
                            </td>
                            <td className="px-2">
                              <div className="flex flex-col text-xs">
                                {" "}
                                <span className="font-medium text-xs">
                                  {transaction.pmtType}
                                </span>
                                <span className="color-sub-text">
                                  Mode: {transaction.pmtMode}
                                </span>
                              </div>
                            </td>
                            <td className="px-2 text-xs font-medium">
                              <span className={`${"color-red"}  `}>
                                {getCurrencyNumberWithOutSymbol(
                                  transaction.amount
                                )}
                              </span>
                            </td>
                          </tr>
                        )
                      )}
                    </>
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan={100} className="text-center py-10">
                        <div className="bg-white" style={{ width: "100%" }}>
                          <img src={nodata} alt="No Data" className="mx-auto" />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
            </div>
          </div>
          {/* <div className="bg-white rounded-lg mt-4">
            <div className="grid grid-cols-11 font-semibold bg-light-primary rounded-t-lg">
              <div className="col-span-1 p-2">#</div>
              <div className="col-span-1 p-2 border-l">Pay id</div>
              <div className="col-span-2 p-2 border-l">Date</div>
              <div className="col-span-2 p-2 border-l">Type</div>
              <div className="col-span-1 p-2 border-l">Mode</div>
              <div className="col-span-2 p-2 border-l">
                {getCurrencyNumberWithSymbol("Amount Received")}
              </div>
              <div className="col-span-2 p-2 border-l">
                {getCurrencyNumberWithSymbol("Balance Receivable")}
              </div>
            </div>
            {inwardPayment?.pmts &&
              inwardPayment.pmts.map((detail, index) => (
                <div
                  key={detail.id}
                  className="grid grid-cols-11 border-b border-white"
                >
                  <div className="col-span-1 p-2 border-l">{index + 1}</div>
                  <div className="color-blue col-span-1 p-2 border-l">
                    {detail.id}
                  </div>
                  <div className="col-span-2 p-2 border-l">
                    {" "}
                    {moment(detail.date).format("DD-MMM-YYYY")}
                  </div>
                  <div className="col-span-2 p-2 border-l">
                    {detail.pmtType}
                  </div>
                  <div className="col-span-1 p-2 border-l">
                    {detail.pmtMode}
                  </div>
                  <div className="col-span-2 p-2 border-l">{detail.amount}</div>
                  <div className="col-span-2 p-2 border-l">
                    {detail.balance}
                  </div>
                </div>
              ))}
            {inwardPayment && inwardPayment.pmts.length == 0 && (
              <div className="text-center">
                <img src={nodata} className="ml-auto mr-auto" />
              </div>
            )}
          </div> */}
        </>
      ) : activeTab === "outward_payment" ? (
        <>
          <div className="flex align-middle bg-white mt-5 ">
            <div className="pr-4 flex-1 hidden md:flex flex-col justify-center items-center p-3">
              <span className="text-blue-500 text-sm font-semibold">
                Trader Id : <span>{tradeDetails?.srcPartyId}</span>
              </span>
              <span className="text-sm text-center">
                From:{" "}
                <span className="font-semibold">
                  {tradeDetails?.srcTradeName}
                </span>
              </span>
            </div>
            {/* <div className="border-l flex-1 flex flex-col justify-center items-center p-3">
              <span className="text-sm font-light">
                {" "}
                {tradeDetails?.tradeMode
                  ? tradeDetails?.tradeMode == "ORDER"
                    ? "Invoice Amount"
                    : "Sales Amount"
                  : "Sales Amount"}
                :{" "}
              </span>
              <span className="font-semibold">
                {tradeDetails?.tradeMode
                  ? tradeDetails?.tradeMode == "ORDER"
                    ? getCurrencyNumberWithSymbol(
                        tradeDetails?.invAmt || 0,
                        true
                      )
                    : getCurrencyNumberWithSymbol(
                        tradeDetails?.pattiAmt || 0,
                        true
                      )
                  : getCurrencyNumberWithSymbol(
                      tradeDetails?.pattiAmt || 0,
                      true
                    )}
              </span>
            </div> */}
            <div className="border-l flex-1 flex flex-col justify-center items-center p-3">
              <span className="text-sm font-light">Final Receivable </span>
              <span className="font-semibold">
                {getCurrencyNumberWithSymbol(
                  Number(alltradeDetail?.totalReceivable) -
                    Number(alltradeDetail?.srcPf) +
                    Number(alltradeDetail?.srcDis),
                  true
                )}
              </span>
            </div>
            <div className="border-l flex-1 flex flex-col justify-center items-center p-3 ">
              <span className="text-sm font-light">Received Till Date </span>
              <span className="font-semibold">
                {getCurrencyNumberWithSymbol(tradeDetails?.outwardPmt, true)}
              </span>
            </div>
            <div className="p-3 flex-1 flex flex-col justify-center items-center border-l">
              <span className="text-sm font-light">Balance Receivable </span>
              <span className="text-sm color-primary font-semibold">
                {getCurrencyNumberWithSymbol(
                  (Number(alltradeDetail?.totalReceivable) -
                    Number(alltradeDetail?.srcPf) +
                    Number(alltradeDetail?.srcDis) || 0) -
                    (tradeDetails?.outwardPmt || 0),
                  true
                )}
              </span>
            </div>
          </div>
          <div className="my-3 flex flex-col md:flex-row gap-4">
            <div style={{ width: isMobile ? "100%" : "40%" }}>
              <div className="py-3 br-10 bg-white px-3">
                <div className="flex gap-2 border-b pb-3 justify-between items-center ">
                  <span className="text-sm font-semibold color-primary">
                    Payment Terms:
                  </span>
                  {alltradeDetail?.tradeStatus !== "CLOSED" &&
                    roleType == "ADMIN" && (
                      <Button
                        variant="outlined"
                        className="ml-auto gap-1"
                        size="small"
                        onClick={() => {
                          // setEditDetail({
                          //   ...editDetail,
                          //   section: "arival_document",
                          //   mode: "edit",
                          // });
                          setShowPaymentForm(true);
                        }}
                      >
                        <FaEdit className="" />
                        Edit
                      </Button>
                    )}
                </div>
                <div className="flex pt-3 justify-between">
                  <span className="flex flex-col">
                    <span className="color-sub-text text-xs">Terms</span>
                    <span className={"font-medium text-sm"}>
                      {alltradeDetail?.srcPmtTerm || "-"}
                    </span>
                  </span>
                  <span className="flex flex-col">
                    <span className="color-sub-text text-xs">Due Date</span>
                    <span className={"font-medium text-sm"}>
                      {(alltradeDetail?.arlDate &&
                        alltradeDetail?.srcPmtTerm &&
                        addDays(
                          alltradeDetail?.arlDate,
                          alltradeDetail?.srcPmtTerm || "7"
                        )) ||
                        "-"}
                    </span>
                  </span>
                </div>
              </div>
              <div className="py-3 br-10 mt-3 bg-white px-3">
                <div className="flex gap-2 border-b pb-3 justify-between items-center ">
                  <span className="text-sm font-semibold color-primary">
                    Advance:
                  </span>
                </div>
                <div className="flex pt-3 justify-between">
                  <span className="flex flex-col">
                    <span className="color-sub-text text-xs">
                      Advance Limit
                    </span>
                    <span className={"font-medium text-sm"}>
                      {getCurrencyNumberWithSymbol(
                        alltradeDetail?.reqAdv?.advLimit
                      ) || "-"}
                    </span>
                  </span>
                </div>
              </div>
              {alltradeDetail?.reqAdv != null &&
                alltradeDetail?.reqAdv.requestAdv != 0 &&
                alltradeDetail?.reqAdv.verified == 1 && (
                  <div className="py-3 br-10 mt-3 bg-white px-3">
                    <div className="flex flex-col border-b pb-3  ">
                      <span className="text-sm font-semibold color-primary">
                        Advance Requested:
                      </span>
                      <span className={`font-medium text-sm flex items-center`}>
                        {alltradeDetail?.reqAdv?.advDisb != 0 ? (
                          <span data-status="" className="color-sub-text">
                            Disbursed on{" "}
                            {alltradeDetail?.reqAdv?.disbDate
                              ? moment(alltradeDetail?.reqAdv?.disbDate).format(
                                  "DD-MMM-YYYY"
                                ) +
                                moment(
                                  alltradeDetail?.reqAdv?.timeStamp
                                ).format(" | hh:mm A")
                              : "-"}{" "}
                            {/* 20-Mar-24 | 05:00 PM */}
                          </span>
                        ) : (
                          <span
                            className={`${classes.orderStatus}`}
                            data-status={"PENDING"}
                          >
                            Pending
                          </span>
                        )}
                      </span>
                    </div>
                    <div className="flex py-3 gap-2 border-b justify-between">
                      <span className="flex flex-col">
                        <span className="color-sub-text text-xs">
                          Advance Limit
                        </span>
                        <span className={"font-medium text-sm"}>
                          {getCurrencyNumberWithSymbol(
                            alltradeDetail?.reqAdv?.advLimit
                          ) || "-"}
                        </span>
                      </span>
                    </div>
                    <div className="flex py-3 gap-2 border-b justify-between">
                      <span className="flex flex-col">
                        <span className="color-sub-text text-xs">
                          Date Requested
                        </span>
                        <span className={"font-medium text-sm"}>
                          {alltradeDetail?.reqAdv.advDate
                            ? moment(alltradeDetail?.reqAdv.advDate).format(
                                "DD-MMM-YYYY"
                              )
                            : ""}
                        </span>
                      </span>
                      <span className="flex flex-col">
                        <span className="color-sub-text text-xs">
                          Advance Requested
                        </span>
                        <span className={"font-medium text-sm"}>
                          {getCurrencyNumberWithSymbol(
                            alltradeDetail?.reqAdv?.requestAdv
                          ) || 0}
                        </span>
                      </span>
                    </div>
                    <div className="flex py-3 gap-2 border-b justify-between">
                      <span className="flex flex-col">
                        <span className="color-sub-text text-xs">
                          Advance Disbursed
                        </span>
                        <span className={"font-medium text-sm"}>
                          {getCurrencyNumberWithSymbol(
                            alltradeDetail?.reqAdv?.advDisb
                          ) || 0}
                        </span>
                      </span>
                    </div>
                    <div className="flex py-3 gap-2 border-b justify-between">
                      <span className="flex flex-col">
                        <span className="color-sub-text text-xs">Comments</span>
                        <span className={"font-medium text-sm"}>
                          {alltradeDetail?.reqAdv?.comments || "-"}
                        </span>
                      </span>
                    </div>
                    <>
                      {" "}
                      <div className="py-3 text-sm color-primary font-semibold">
                        Borrower Picture
                      </div>
                      <div className="flex flex-row overflow-auto hide-scroll-bar">
                        {alltradeDetail.reqAdvanceDocuments.map((item, i) => (
                          <div
                            key={`receipt-document-${i}`}
                            className="p-1 w-1/2 shrink-0"
                          >
                            <div
                              className="border-2 p-2 rounded overflow-hidden cursor-pointer relative"
                              style={{ height: "9rem" }}
                              // onClick={() => {
                              //   openInFullScreen(
                              //     item.url,
                              //     "Patti Documents",
                              //     item.docType
                              //   );
                              // }}
                            >
                              <a href={item.url} target="_blank">
                                <DocumentPreview
                                  docType={item.docType}
                                  url={item.url}
                                />
                                {item.docType === "pdf" && (
                                  <FaExpand
                                    className="absolute"
                                    style={{
                                      top: "4rem",
                                      left: "calc(50% - 1rem)",
                                      fontSize: "2.2rem",
                                    }}
                                  />
                                )}
                              </a>
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  </div>
                )}
            </div>
            <div
              style={{ width: isMobile ? "100%" : "60%" }}
              className="overflow-x-auto"
            >
              <table className="table-auto w-full border-collapse border text-sm bg-white">
                <thead className="bg-light-primary text-left sticky top-0 z-10 font-medium">
                  <th className="text-center px-2 font-semibold">#</th>

                  <th
                    className="px-2 py-2 font-semibold"
                    style={{ minWidth: isMobile ? "120px" : "" }}
                  >
                    Ref ID | Date
                  </th>
                  <th
                    className="px-2 py-2 font-semibold"
                    style={{ minWidth: isMobile ? "120px" : "" }}
                  >
                    Payment Type
                  </th>
                  <th
                    className="px-2 py-2 font-semibold"
                    style={{ minWidth: isMobile ? "140px" : "" }}
                  >
                    Amount Received(₹)
                  </th>
                </thead>
                {alltradeDetail?.srcPmtHistory &&
                alltradeDetail?.srcPmtHistory.length > 0 ? (
                  <tbody
                    className="overflow-auto bg-white"
                    // style={{ height: "calc(100vh - 33rem)" }}
                  >
                    <>
                      {alltradeDetail?.srcPmtHistory.map(
                        (transaction: any, i) => (
                          <tr>
                            <td className="text-center px-2">{i + 1}</td>
                            <td className="px-2 text-xs">
                              <span className="flex flex-col">
                                <span
                                  className="color-blue cursor-pointer font-medium"
                                  onClick={() => {
                                    // navigate(`/payment-history/${transaction?.id}`);
                                    setOpenPaymenHistory(true);
                                    dispatch(
                                      getPaymentHistory({
                                        paymentHistoryObj: transaction,
                                      })
                                    );
                                    dispatch(
                                      updatePaymentUpdateStatus({
                                        paymentUpdateStatus: true,
                                      })
                                    );
                                  }}
                                >
                                  {transaction.id}
                                </span>
                                <span className="font-medium text-xs">
                                  {moment(transaction.date).format(
                                    "DD-MMM-YYYY"
                                  )}
                                </span>
                              </span>
                            </td>
                            <td className="px-2">
                              <div className="flex flex-col text-xs">
                                {" "}
                                <span className="font-medium text-xs">
                                  {transaction.pmtType}
                                </span>
                                <span className="color-sub-text">
                                  Mode: {transaction.pmtMode}
                                </span>
                              </div>
                            </td>
                            <td className="px-2 text-xs font-medium">
                              <span className={`${"color-primary"}  `}>
                                {getCurrencyNumberWithOutSymbol(
                                  transaction.amount
                                )}
                              </span>
                            </td>
                          </tr>
                        )
                      )}
                    </>
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan={100} className="text-center py-10">
                        <div className="bg-white" style={{ width: "100%" }}>
                          <img src={nodata} alt="No Data" className="mx-auto" />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
            </div>
          </div>
          {/* <div className="bg-white rounded-lg mt-4">
            <div className="grid grid-cols-11 font-semibold bg-light-primary rounded-t-lg">
              <div className="col-span-1 p-2">#</div>
              <div className="col-span-1 p-2 border-l">Payid</div>
              <div className="col-span-2 p-2 border-l">Date</div>
              <div className="col-span-2 p-2 border-l">Type</div>
              <div className="col-span-1 p-2 border-l">Mode</div>
              <div className="col-span-2 p-2 border-l">
                {getCurrencyNumberWithSymbol("Amount Received")}
              </div>
              <div className="col-span-2 p-2 border-l">
                {getCurrencyNumberWithSymbol("Balance Payable")}
              </div>
            </div>
            {outWardPayment?.pmts &&
              outWardPayment.pmts.map((detail, index) => (
                <div
                  key={detail.id}
                  className="grid grid-cols-11 border-b border-white"
                >
                  <div className="col-span-1 p-2 border-l">{index + 1}</div>
                  <div className="color-blue col-span-1 p-2 border-l">
                    {detail.id}
                  </div>
                  <div className="col-span-2 p-2 border-l">
                    {moment(detail.date).format("DD-MMM-YYYY")}
                  </div>
                  <div className="col-span-2 p-2 border-l">
                    {detail.pmtType}
                  </div>
                  <div className="col-span-1 p-2 border-l">
                    {detail.pmtMode}
                  </div>
                  <div className="col-span-2 p-2 border-l">{detail.amount}</div>
                  <div className="col-span-2 p-2 border-l">
                    {detail.balance}
                  </div>
                </div>
              ))}
            {outWardPayment && outWardPayment.pmts.length == 0 && (
              <div className="text-center">
                <img src={nodata} className="ml-auto mr-auto" />
              </div>
            )}
          </div> */}
        </>
      ) : (
        <></>
      )}
      {activeTab === "inward_payment" ? (
        <>
          {tradeDetails?.tradeStatus !== "CANCEL" &&
            tradeDetails?.tradeStatus !== "CLOSED" &&
            roleType == "ADMIN" && (
              <div
                className="flex md:hidden gap-2 ml-auto absolute bottom-0 w-full mt-4"
                style={{ bottom: "-50px" }}
              >
                <Button
                  onClick={() => {
                    setOpenRecordPayment(true);
                    dispatch(
                      updatePaymentUpdateStatus({
                        paymentUpdateStatus: false,
                      })
                    );
                    dispatch(
                      updateEditAdvPayment({ editAdvancePayment: false })
                    );
                    dispatch(
                      getPaymentHistory({
                        paymentHistoryObj: null,
                      })
                    );
                  }}
                  className="w-full"
                >
                  RECORD RECEIVABLE
                </Button>
              </div>
            )}
        </>
      ) : (
        <>
          {tradeDetails?.tradeStatus !== "CANCEL" &&
            tradeDetails?.tradeStatus !== "CLOSED" &&
            roleType == "ADMIN" && (
              <div
                className="flex md:hidden gap-2 ml-auto absolute w-full pt-4"
                style={{ bottom: "-50px" }}
              >
                <Button
                  onClick={() => {
                    setOpenRecordPayment(true);
                    dispatch(
                      updatePaymentUpdateStatus({
                        paymentUpdateStatus: false,
                      })
                    );
                    dispatch(
                      updateEditAdvPayment({ editAdvancePayment: false })
                    );
                    dispatch(
                      getPaymentHistory({
                        paymentHistoryObj: null,
                      })
                    );
                  }}
                  variant={"danger"}
                  className="w-full"
                >
                  RECORD PAYMENT
                </Button>
              </div>
            )}
        </>
      )}
      {showPaymentForm && (
        <>
          <Modal
            open={showPaymentForm}
            onClose={() => {
              setShowPaymentForm(false);
            }}
            width={isMobile ? "100% " : "40vw"}
          >
            <>
              <ModalHeader title={"Payment Details (Source)"} content={<></>} />
              <div className="py-3 px-6">
                <div className="flex gap-3 flex-col items-center w-full">
                  <div className="flex flex-col w-full flex-1">
                    <span className="text-sm">Arrival Date</span>
                    <div className=" relative">
                      <SelectDateDisable
                        onChange={() => {}}
                        selectedDate={alltradeDetail?.arlDate!}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col w-full flex-1">
                    <span className="text-sm">Payment Terms</span>
                    <div className="mt-2 relative">
                      <Select2
                        value={alltradeDetail?.srcPmtTerm}
                        lable=" "
                        options={[
                          {
                            label: "Next 7 Days",
                            value: "7",
                          },
                          {
                            label: "Next 10 Days",
                            value: "10",
                          },
                          {
                            label: "Next 15 Days",
                            value: "15",
                          },
                          {
                            label: "Next 30 Days",
                            value: "30",
                          },
                          {
                            label: "Next 60 Days",
                            value: "60",
                          },
                          {
                            label: "Next 90 Days",
                            value: "90",
                          },
                        ]}
                        onChange={(selectedDays) => {
                          setArrivalIInfo({
                            ...arrivalIInfo,
                            srcPmtTerm: selectedDays.value || "",
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col w-full flex-1">
                    <span className="text-sm">Due Date</span>
                    <div className=" relative">
                      <SelectDateDisable
                        onChange={() => {}}
                        selectedDate={alltradeDetail?.dueDate!}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col w-full flex-1">
                    <span className="text-sm">Advance Limit</span>
                    <div className="mt-2 relative">
                      <Input
                        label=""
                        type="number"
                        required={true}
                        value={reqAdvInfo?.advLimit}
                        disabled={
                          alltradeDetail?.advanceRcvd != 0 ? true : false
                        }
                        onChange={(e) => {
                          setReqAdvInfo({
                            ...reqAdvInfo,
                            advLimit:
                              e.target.value != ""
                                ? parseFloat(e.target.value)
                                : "",
                          });
                        }}
                        onFocus={(e) => {
                          e.target.value === "0" &&
                            setReqAdvInfo({
                              ...reqAdvInfo,
                              advLimit: "",
                            });
                        }}
                      />
                    </div>
                  </div>
                  {alltradeDetail?.reqAdv?.verified == 1 && (
                    <>
                      <div className="flex flex-col w-full flex-1">
                        <span className="text-sm color-primary">
                          Advance Request
                        </span>
                      </div>
                      <div className="flex flex-col w-full flex-1">
                        <span className="text-sm">Date</span>
                        <div className=" relative">
                          <SelectDateDisable
                            onChange={() => {}}
                            selectedDate={alltradeDetail?.reqAdv?.advDate}
                          />
                        </div>
                      </div>
                      <div
                        className="flex items-center gap-4 justify-between"
                        style={{ width: "100%" }}
                      >
                        <div className="grow-[3] flex flex-col w-full flex-1">
                          <span className="text-sm">Advance*</span>
                          <div className="mt-2 relative">
                            <Input
                              label=""
                              type="number"
                              required={true}
                              value={reqAdvInfo?.requestAdv}
                              disabled={
                                alltradeDetail?.advanceRcvd != 0 ? true : false
                              }
                              onChange={(e) => {
                                setReqAdvInfo({
                                  ...reqAdvInfo,
                                  requestAdv:
                                    e.target.value != ""
                                      ? parseFloat(e.target.value)
                                      : "",
                                });
                              }}
                              onFocus={(e) => {
                                e.target.value === "0" &&
                                  setReqAdvInfo({
                                    ...reqAdvInfo,
                                    requestAdv: "",
                                  });
                              }}
                            />
                          </div>
                        </div>
                        <div className="grow-[3] flex flex-col w-full flex-1">
                          <span className="text-sm">Comments</span>
                          <div className="mt-2 relative">
                            <Input
                              label=""
                              type="text"
                              required={true}
                              value={reqAdvInfo?.comments}
                              disabled={
                                alltradeDetail?.advanceRcvd != 0 ? true : false
                              }
                              onChange={(e) => {
                                setReqAdvInfo({
                                  ...reqAdvInfo,
                                  comments: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="flex items-center bottom-0 right-0 px-4 py-4 border-top gap-2">
                <Button
                  variant="primaryLight"
                  className="w-full"
                  text="CANCEL"
                  onClick={() => {
                    setShowPaymentForm(false);
                  }}
                />
                <Button
                  variant="contained"
                  className="w-full"
                  onClick={() => {
                    confrirmArrival();
                  }}
                >
                  SAVE
                </Button>
              </div>
            </>
          </Modal>
        </>
      )}
      {showPaymentFormDest && (
        <>
          <Modal
            open={showPaymentFormDest}
            onClose={() => {
              setShowPaymentFormDest(false);
            }}
            width={isMobile ? "100% " : "20vw"}
          >
            <>
              <ModalHeader title={"Payment Details (Dest)"} content={<></>} />
              <div className="py-3 px-6">
                <div className="flex gap-3 flex-col items-center w-full">
                  <div className="flex flex-col w-full flex-1">
                    <span className="text-sm">Arrival Date</span>
                    <div className="mt-2 relative">
                      <SelectDateDisable
                        onChange={() => {}}
                        selectedDate={alltradeDetail?.arlDate!}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col w-full flex-1">
                    <span className="text-sm">Payment Terms</span>
                    <div className="mt-2 relative">
                      <Select2
                        value={alltradeDetail?.destPmtTerm}
                        lable=" "
                        options={[
                          {
                            label: "Next 7 Days",
                            value: "7",
                          },
                          {
                            label: "Next 10 Days",
                            value: "10",
                          },
                          {
                            label: "Next 15 Days",
                            value: "15",
                          },
                          {
                            label: "Next 30 Days",
                            value: "30",
                          },
                          {
                            label: "Next 60 Days",
                            value: "60",
                          },
                          {
                            label: "Next 90 Days",
                            value: "90",
                          },
                        ]}
                        onChange={(selectedDays) => {
                          setArrivalIInfo({
                            ...arrivalIInfo,
                            destPmtTerm: selectedDays.value || "",
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col w-full flex-1">
                    <span className="text-sm">Due Date</span>
                    <div className="mt-2 relative">
                      <SelectDateDisable
                        onChange={() => {}}
                        selectedDate={alltradeDetail?.dueDate!}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col w-full flex-1">
                    <span className="text-sm">Balance Payable (Till Date)</span>
                    <div className="mt-2 relative">
                      <Input
                        label=""
                        type="number"
                        required={true}
                        value={
                          alltradeDetail?.balPayable
                          // Number(alltradeDetail?.totalPayable) -
                          // Number(alltradeDetail?.inwardPmt)
                        }
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col w-full flex-1">
                    <span className="text-sm">Days Delayed (Till Date)</span>
                    <div className="mt-2 relative">
                      <Input
                        label=""
                        type="number"
                        required={true}
                        value={tradeDetails?.delayedDays}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col w-full flex-1">
                    <span className="text-sm">Delayed Payment Charges (%)</span>
                    <div className="mt-2 relative">
                      <Input
                        label=""
                        type="number"
                        required={true}
                        value={alltradeDetail?.delayedChargesPer}
                        onChange={(e) => {
                          onChangeDelayCharges(e);
                        }}
                        onFocus={(e) => {
                          e.target.value === "0" &&
                            setArrivalIInfo({
                              ...arrivalIInfo,
                              delayedChargesVal: "",
                            });
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col w-full flex-1">
                    <span className="text-sm">Delayed Payment Amount (₹)</span>
                    <div className="mt-2 relative">
                      <Input
                        label=""
                        type="number"
                        required={true}
                        value={alltradeDetail?.delayedChargesVal}
                        onChange={(e) => {
                          onChangeDelayChargesTotal(e);
                        }}
                        onFocus={(e) => {
                          e.target.value === "0" &&
                            setArrivalIInfo({
                              ...arrivalIInfo,
                              delayedChargesVal: "",
                            });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex items-center bottom-0 right-0 px-4 py-4 border-top gap-2">
                <Button
                  variant="primaryLight"
                  className="w-full"
                  text="CANCEL"
                  onClick={() => {
                    setShowPaymentFormDest(false);
                  }}
                />
                <Button
                  variant="contained"
                  className="w-full"
                  onClick={() => {
                    confrirmArrival();
                  }}
                >
                  SAVE
                </Button>
              </div>
            </>
          </Modal>
        </>
      )}
      <PanelSlider
        direction={isMobile ? "bottom_to_top" : "right_to_left"}
        open={openRecordPayment}
        onClose={() => {
          setOpenRecordPayment(false);
        }}
        style={{ width: isMobile ? "100%" : "40vw" }}
      >
        {roleType == "ADMIN" && (
          <div className="py-6 px-4 border-b flex items-center justify-between ">
            <div>
              <h2>Record Payment</h2>
            </div>
            <div>
              <img
                src={cancelIcon}
                className="cursor-pointer"
                onClick={() => {
                  setOpenRecordPayment(false);
                }}
              />
            </div>
          </div>
        )}
        {openRecordPayment === true && (
          <RecordPayment
            traderId={parseInt(params.traderId || "0")}
            tradeId={parseInt(params.tradeId || "0")}
            recordType={recordFrom}
            onClose={(paymentDone: boolean) => {
              setOpenRecordPayment(false);
              if (paymentDone) {
                setActiveTab("payment_details");
                // setActivePaymentTab("outward_payment");
              }
            }}
          />
        )}
      </PanelSlider>
      <PanelSlider
        direction={isMobile ? "bottom_to_top" : "right_to_left"}
        open={openPaymenHistory}
        onClose={() => {
          setOpenPaymenHistory(false);
        }}
        style={{ width: isMobile ? "100%" : "40vw" }}
      >
        <div className="py-6 px-4 border-b flex items-center justify-between">
          <div>
            <h2> Payment History</h2>
          </div>
          <div>
            <img
              src={cancelIcon}
              className="cursor-pointer"
              onClick={() => {
                setOpenPaymenHistory(false);
              }}
            />
          </div>
        </div>

        {openPaymenHistory === true && (
          <PaymentHistory
            traderId={parseInt(params.traderId || "0")}
            tradeId={parseInt(params.tradeId || "0")}
            recordType={recordFrom}
            onClose={(paymentDone: boolean) => {
              setOpenPaymenHistory(false);
              if (paymentDone) {
                setActiveTab("payment_details");
                // setActivePaymentTab("outward_payment");
              }
            }}
            onEdit={() => {
              setOpenPaymenHistory(false);
              setOpenRecordPayment(true);
            }}
            role={roleType}
          />
        )}
      </PanelSlider>
    </div>
  );
};
export default Paymentdetail;
