import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useAxiosClient from "./useAxiosClient";
import { TRootState } from "../store";
import moment from "moment";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Compressor from "compressorjs";
import { TCrop, TSupplyDemandReq } from "../types";
import {
  updateActiveTradeTab,
  updateAllSupplyDetails,
  updateCropData,
  updateSupplyDetails,
} from "../reducers/tradeSlice";
const useSupplyDetailsEdit = ({
  id,
  modalClose,
  fromSupplyTab,
}: {
  id?: number;
  modalClose?: any;
  fromSupplyTab?: boolean;
}) => {
  const { handleGetCall, handlePutCall, handlePostCall } = useAxiosClient();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [kycDocs, setKycDocs] = useState<
    Array<{ docType: "image" | "pdf"; url: string; type: string }>
  >([]);
  const { supplyDEtailsObj, adminId, selectedCrop } = useSelector(
    (state: TRootState) => ({
      supplyDEtailsObj: state.trades.supplyDetailsById,
      adminId: state.auth.adminId,
      selectedCrop: state.trades.selectedCrop,
    })
  );
  const [supplyDetails, setSupplyDetails] = useState<TSupplyDemandReq | null>(
    supplyDEtailsObj
  );

  const [selectedDate, setSelectedDate] = useState(
    supplyDetails?.avlDate ? supplyDetails?.avlDate : ""
  );
  let traderId = supplyDetails?.traderId || 0;
  const handleDateSelection = (_date: string) => {
    let date = moment(_date).format("YYYY-MM-DD");
    //  setCalenderOpen(false);
    setSelectedDate(date);
    setSupplyDetails({
      ...supplyDetails!,
      avlDate: date,
    });
  };
  const [latitude, setLatitude] = useState<number | null>(null);
  const [longitude, setLongitude] = useState<number | null>(null);
  const [cropImages, setCropImages] = useState<
    Array<{ docType: "image" | "pdf"; url: string }>
  >([]);
  const onUploadReceiptImage = async (type: "supply", file: any) => {
    let fileType = "";
    if (file.type === "application/pdf") {
      fileType = "pdf";
    } else if (file.type.includes("image/")) {
      fileType = "image";
    }
    let formdata = new FormData();
    formdata.append("id", traderId.toString());
    formdata.append("type", "SUPPLY");
    if (fileType === "pdf") {
      formdata.append("file", file, file.name.replace(" ", "-"));
      let imageUploadResponse = await handlePostCall<string[]>({
        URL: "/mandi/files/seller-buyer/imgs/upload",
        apiParams: formdata,
        config: {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      });
      let url = imageUploadResponse.data[0];
      setCropImages([{ docType: "pdf", url: url }, ...cropImages]);
    } else {
      new Compressor(file, {
        quality: 0.8,
        success: async (compressedResult) => {
          formdata.append(
            "file",
            compressedResult,
            file.name.replace(" ", "-")
          );
          let imageUploadResponse = await handlePostCall<string[]>({
            URL: "/mandi/files/seller-buyer/imgs/upload",
            apiParams: formdata,
            config: {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            },
          });
          let url = imageUploadResponse.data[0];

          setCropImages([...cropImages, { docType: "image", url: url }]);
        },
      });
    }
  };

  const onRemoveReceiptImage = (
    type: "supply",
    index: number,
    isExist: boolean = false
  ) => {
    if (type == "supply") {
      if (isExist === true && supplyDetails !== null) {
        let invoices = [...supplyDetails.imgs];
        let i = invoices.findIndex((img) => img.id === index);
        invoices[i] = { ...invoices[i], status: 0 };
        setSupplyDetails({ ...supplyDetails, imgs: invoices });
      } else {
        let newtapalInvoice = [...cropImages];
        newtapalInvoice.splice(index, 1);
        setCropImages(newtapalInvoice);
      }
    }
  };
  const submit = async () => {
    let imgs: Array<{
      id: number;
      status: number;
      addedBy: number;
      type: string;
      url: string;
    }> = [];
    let images = imgs;
    if (supplyDetails?.cropName == "" || selectedCrop == null) {
      toast.error("Please select Crop ");
      return;
    }
    if (supplyDetails?.quality == "") {
      toast.error("Please select Quality ");
      return;
    }
    if (supplyDetails?.unitType == "") {
      toast.error("Please select Unit Type ");
      return;
    }
    if (supplyDetails?.qty == 0) {
      toast.error("Please enter Quantity");
      return;
    }
    if (supplyDetails?.expRate == 0) {
      toast.error("Please enter Rate");
      return;
    }
    if (supplyDetails?.avlDate == "") {
      toast.error("Please select Availability Date");
      return;
    }
    // if (cropImages.length === 0 && supplyDetails?.imgs == null) {
    //   toast.error("Please upload atleast one image ");
    //   return;
    // }
    // if (cropImages.length === 0 && supplyDetails?.imgs == null) {
    //   toast.error("Please upload atleast one image");
    //   return;
    // }
    // if (removeImageStatus) {
    if (supplyDetails != null) {
      if (supplyDetails?.imgs && supplyDetails?.imgs.length > 0) {
        for (let file of supplyDetails?.imgs) {
          if (file.status == 0) {
            images.push({
              ...file,
              addedBy: adminId,
              id: file.id,
            });
          }
        }
      }
    }
    // }
    if (cropImages.length > 0) {
      for (let file of cropImages) {
        images.push({
          id: 0,
          status: 1,
          addedBy: adminId || 0,
          type: "supply",
          url: file.url,
        });
      }
    }
    // const allStatusZero =
    //   images.length > 0 &&
    //   supplyDetails?.imgs.every((item) => item.status === 0);
    // const allStatusZeroEDitImages =
    //   images.length > 0 && images.every((item) => item.status === 0);
    // if (
    //   allStatusZero &&
    //   allStatusZeroEDitImages &&
    //   supplyDetails?.imgs != null
    // ) {
    //   toast.error("Please upload atleast one image");
    //   return;
    // }

    try {
      let postBody = {
        avlDate: supplyDetails?.avlDate,
        cropId: selectedCrop?.cropId || supplyDetails?.cropId,
        expRate: supplyDetails?.expRate,
        expTotal: supplyDetails?.expTotal,
        id: supplyDetails?.id,
        imgs: images,
        qty: supplyDetails?.qty,
        quality: supplyDetails?.quality,
        traderId: supplyDetails?.traderId,
        unitType: supplyDetails?.unitType,
        latitude: latitude || 0,
        location: supplyDetails?.location || "",
        longitude: longitude || 0,
      };

      await handlePutCall<any>({
        URL: `admin/demand-supply/supply/adminId/${adminId}`,
        apiParams: postBody,
      })
        .then(({ data, status }) => {
          toast.success(status.message);
          if (fromSupplyTab) {
            navigate(`/trades`);
            dispatch(updateActiveTradeTab("SUPPLY"));
            handleGetCall<TSupplyDemandReq[]>(
              `admin/demand-supply/supply/adminId/${adminId}`
            ).then(({ data }) => {
              if (data) {
                dispatch(updateAllSupplyDetails({ allSupplys: data }));
              }
            });
          } else {
            navigate(`/supply-details/${id}`);
          }
          modalClose(false);
        })
        .catch((err: any) => {
          toast.error(err.response.data.status.message);
        });
    } catch (ex) {}
  };
  useEffect(() => {
    if (supplyDEtailsObj == null || fromSupplyTab) {
      handleGetCall<TSupplyDemandReq>(
        `admin/demand-supply/supply/supplyId/${id}`
      ).then(({ data }) => {
        if (data) {
          let cropsData: TCrop;

          if (data.cropName) {
            cropsData = {
              baseName: "",
              cropId: data?.cropId || 0,
              cropName: data?.cropName || "",
              imageUrl: data?.cropUrl || "",
              type: "",
              units: "",
              varietyName: "",
              qtyUnit: "",
              rateType: "",
              qty: data?.qty || "",
              weight: "",
              freightAmt: "",
              status: 1,
              tradeId: 0,
              weightUnit: "",
              id: 0,
              addWastage: "",
              destWeight: "",
              destRate: "",
              rate: data?.expRate || "",
              unitType: "",
            };
            dispatch(
              updateCropData({
                selectedCrop: cropsData,
              })
            );
          } else {
            dispatch(
              updateCropData({
                selectedCrop: null,
              })
            );
          }
          setSelectedDate(data?.avlDate);
          dispatch(
            updateSupplyDetails({
              supplyDetailsById: {
                ...data,
                quality: data?.quality
                  ? data?.quality.toString().toUpperCase()
                  : "",
              },
            })
          );
          setSupplyDetails(data);
        } else {
          setSupplyDetails(null);
        }
      });
    }
  }, []);

  return {
    supplyDetails,
    handleDateSelection,
    setSupplyDetails,
    submit,
    onUploadReceiptImage,
    kycDocs,
    selectedDate,
    cropImages,
    setCropImages,
    onRemoveReceiptImage,
    setLatitude,
    setLongitude,
  };
};
export default useSupplyDetailsEdit;
