import React, { useState } from "react";
import LoansSmartboard from "./smartboard";
import LoansAlerts from "./alerts";
import LoansApplications from "./Applications";
const Loans = () => {
  return (
    <div className="container mx-auto py-4">
      <div>
        <LoansSmartboard />
        <LoansAlerts />
        <LoansApplications />
      </div>
    </div>
  );
};

export default Loans;
