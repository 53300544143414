
import { useEffect, useState } from "react"; 

import {useDispatch, useSelector } from "react-redux";
import useAxiosClient from "../../../../hooks/useAxiosClient";
import { TRootState } from "../../../../store";
import {TBillView } from "../../../../types";
import { updateBillsView } from "../../../../reducers/loansSlice";

const useBillview = () => {
  const { handleGetCall } = useAxiosClient();
  const dispatch = useDispatch()

  const { adminId,InitApplicationDetail,billsInfo, billsView } = useSelector(
    (state: TRootState) => ({
      adminId: state.auth.adminId,
      InitApplicationDetail: state.loans.applicationViewDetails,
      billsInfo: state.loans.billsInfo,
      billsView: state.loans.billsView
    })
  );

 const getBillView =()=>{
    handleGetCall<TBillView>(
      `admin/cash/borrowers/business/adminId/${adminId}?cpId=${InitApplicationDetail?.cpId}`
    ).then(({ data }) => {
      if (data) {
        dispatch(updateBillsView({billsView:data}))
      } else {
        dispatch(updateBillsView({billsView:null}))
      }
    });
  }
  
  return { 
    // billView,
    useBillview,
    getBillView
  };
};
export default useBillview;
