import React, { useEffect, useState, memo } from "react";
import moment from "moment/moment";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { FaCalendarAlt } from "react-icons/fa";
import classes from "./style.module.scss";
import { getCurrentDateTime } from "../../../helpers/datetime";
const todayDate = getCurrentDateTime();
const MonthChanger = ({
  fromDate = "",
  toDate = "",
  maxDate = "",
  onChange,
  onClick,
}) => {
  const [navigationFlag, setNavigationFlag] = useState({
    right: true,
    left: true,
  });
  const [currentMonth, setCurrentMonth] = useState({
    fromDate: "",
    toDate: "",
    action: "",
    animation: "",
  });
  const onNext = () => {
    if (navigationFlag.right === true) {
      let maxD = new Date(maxDate);
      let maxYear = maxD.getFullYear();
      let maxMonth = maxD.getMonth();
      let currentMonthLastD = new Date(currentMonth.toDate);
      let currentYear = currentMonthLastD.getFullYear();
      let currentM = currentMonthLastD.getMonth();
      if (currentYear >= maxYear && currentM >= maxMonth) {
        setNavigationFlag({ ...navigationFlag, right: false });
        return;
      }
    }
    onChange(
      moment(currentMonth.fromDate)
        .add(1, "month")
        .startOf("month")
        .format("YYYY-MM-01"),
      moment(currentMonth.fromDate)
        .add(1, "month")
        .endOf("month")
        .format("YYYY-MM-DD")
    );
    setCurrentMonth({
      ...currentMonth,
      action: "forward",
      animation: "",
    });
  };
  const onBack = () => {
    onChange(
      moment(currentMonth.fromDate).date(0).format("YYYY-MM-01"),
      moment(currentMonth.fromDate).date(0).format("YYYY-MM-DD")
    );
    setCurrentMonth({
      ...currentMonth,
      action: "backward",
      animation: "",
    });
  };
  useEffect(() => {
    if (maxDate) {
      let maxD = new Date(maxDate);
      let currentMonthLastD = new Date(
        toDate
          ? moment(toDate).format("YYYY-MM-DD")
          : currentMonth.toDate,
      );
      if (
        navigationFlag.right === true &&
        currentMonthLastD.getFullYear() >= maxD.getFullYear() &&
        currentMonthLastD.getMonth() >= maxD.getMonth()
      ) {
        setNavigationFlag({ ...navigationFlag, right: false });
      } else {
        setNavigationFlag({ ...navigationFlag, right: true });
      }
    }
    setCurrentMonth({
      ...currentMonth,
      fromDate: fromDate
        ? moment(fromDate).format("YYYY-MM-DD")
        : moment(todayDate).format("YYYY-MM-01"),
      toDate: toDate
        ? moment(toDate).format("YYYY-MM-DD")
        : moment(todayDate).format("YYYY-MM-DD"),
      animation:
        currentMonth.action === "forward"
          ? "slide-right-to-left"
          : currentMonth.action === "backward"
          ? "slide-left-to-right"
          : "",
    });
  }, [fromDate, toDate]);
  let displayMonth = moment(currentMonth.fromDate).format("MMM YYYY");
  return (
    <>
      <div className="inline-flex items-center">
        <BsChevronLeft
          className={"br-50 cursor-pointer bg-white " + classes.icon}
          onClick={onBack}
        />
        <span
          className="flex items-center grow text-center mx-4 color-blue cursor-pointer"
          onClick={() => {
            typeof onClick === "function" && onClick();
          }}
        >
          <FaCalendarAlt />
          <span className="w-20" style={{ overflow: "hidden" }}>
            <span className={`${currentMonth.animation}`} key={displayMonth}>
              {displayMonth}
            </span>
          </span>
        </span>
        <BsChevronRight
          className={
            `ml-auto br-50 ${
              navigationFlag.right ? "cursor-pointer" : "cursor-disabled"
            } bg-white ` + classes.icon
          }
          onClick={() => {
            navigationFlag.right && onNext();
          }}
        />
      </div>
    </>
  );
};
export default memo(MonthChanger);
