import React, { useEffect, useState } from "react";
import ONOCalendar from "../../../components/commons/ono-calendar";
import moment from "moment";
import { FaChevronRight, FaSearch } from "react-icons/fa";
import Input from "../../../components/ui/input";
import PartnerInfo from "../../../components/partner-info";
import { useNavigate } from "react-router-dom";
import useAllLoanApplications from "./useAllApplications";
import {
  getCurrencyNumberWithOutSymbol,
  getCurrencyNumberWithSymbol,
} from "../../../helpers/get-currency-number";
import NodataAvailable from "../../../assets/images/NodataAvailable.svg";
import GaugeChart from "react-gauge-chart";
import { useDispatch, useSelector } from "react-redux";
import { TRootState } from "../../../store";
import { clearComments } from "../../../reducers/loansSlice";
import UserAccountStatusString from "../../customer/CustomerDetails/status-string";
import { getAccountStatusDisplayIcon } from "../../../helpers/getText";
// import PartnerInfo from "../../../components/home/partner";
const LoansApplications = () => {
  const navigate = useNavigate();
  const {
    loanApplicationArray,
    activeTab,
    setActiveTab,
    searchText,
    setSearchText,
    onChangeDateRange,
    dateRange,
    filterTraders,
    filteredTraders,
  } = useAllLoanApplications({});


const dispatch = useDispatch();

const handleClearComments = () => {
  dispatch(clearComments());
};

  const ApplicationTabs = [
    {
      id: 1,
      name: "All",
      to: "all",
    },
    {
      id: 2,
      name: "Pending",
      to: "pending",
    },
    {
      id: 3,
      name: "Approved",
      to: "approved",
    },
    {
      id: 4,
      name: "Disbursed",
      to: "disbursed",
    },
    {
      id: 5,
      name: "Rejected",
      to: "rejected",
    },
    {
      id: 6,
      name: "Closed",
      to: "closed",
    },
  ];

  useEffect(() => {
    filterTraders();
  }, [searchText, loanApplicationArray]);

  return (
    <div className="py-4">
      <ONOCalendar
        mode="monthly"
        onDateChange={onChangeDateRange}
        fromLoans={true}
      />
      <div className="text-lg font-medium">Applications</div>
      <div className="py-2 pb-4 flex justify-between">
        <ul className="flex gap-2" id="myTab" role="tablist">
          {ApplicationTabs.map((tab) => {
            return (
              <li
                key={tab.id}
                className={
                  "text-sm br-10 px-4 py-2 font-medium cursor-pointer " +
                  (activeTab == tab.to
                    ? "bg-primary text-white"
                    : "bg-white border color-text-primary")
                }
                onClick={() => setActiveTab(tab.to)}
              >
                {tab.name}
              </li>
            );
          })}
        </ul>
        <div className="flex gap-4 ml-auto relative">
          <FaSearch
            className="absolute color-primary"
            size={15}
            style={{ top: "14px", left: "6px" }}
          />
          <Input
            type="text"
            palceholder="Search"
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            inputStyle={{
              textIndent: "22px",
              padding: "8px 5px",
              borderRadius: "10px",
            }}
          />
        </div>
      </div>
      <div>
        {filteredTraders?.length > 0 ? (
          <>
            {filteredTraders?.map((application, i) => {
              return (
                <div className="border overflow-hidden bg-white br-10 mb-4">
                  <div className="flex border-b text-sm py-4 cursor-pointer items-center">
                    <div
                      className="grow-[2.5] basis-0 flex flex-col px-4"
                      style={{ flexBasis: "4rem" }}
                    >
                      <PartnerInfo
                        profilePic=""
                        name={application?.bwrName}
                        shortName={""}
                        partnerType={"CASH ID "}
                        partnerId={application?.cpId}
                        partnerMobile={application?.bwrMobile}
                        partyName={application?.addressLine}
                        fromLoansParty={true}
                      />
                    </div>
                    <div className="grow-[2] basis-0 px-2 flex flex-col flex-col items-center">
                      <GaugeChart
                        id="gauge-chart"
                        nrOfLevels={10}
                        percent={
                          application?.onoScore ? application.onoScore / 10 : 0
                        }
                        textColor="#00b300"
                        colors={["#FF5F6D", "#FFC371", "#00b300"]}
                        arcPadding={0.01}
                        style={{ width: "100px", height: "60px" }}
                        formatTextValue={() => ""}
                      />
                      <span
                        className={`font-semibold text-sm ${
                          application?.onoScore <= 3
                            ? "color-red"
                            : application?.onoScore <= 7
                            ? "color-orange"
                            : "color-primary"
                        }`}
                      >
                        {application?.onoScore} / 10{" "}
                        {application?.onoScore <= 3
                          ? "Poor"
                          : application?.onoScore <= 7
                          ? "Average"
                          : "Good"}
                      </span>
                    </div>
                    <span className="grow-[2] basis-0 px-2 flex flex-col">
                      {" "}
                      <span className="text-sm">Cash Limit</span>
                      <span className="font-semibold text-sm">
                        {getCurrencyNumberWithSymbol(
                          application?.eligibleLimit
                        ) || "-"}
                      </span>
                    </span>
                    <div className="grow-[2] basis-0 px-2 flex flex-col">
                      <span className="text-sm">Cash Utilized</span>
                      <span className="font-semibold text-sm">
                        {" "}
                        {getCurrencyNumberWithSymbol(
                          application?.utilisedLimit
                        ) || "-"}
                      </span>
                    </div>
                    <span className="grow-[2] basis-0 px-2 flex flex-col">
                      {" "}
                      <span className="text-sm">Cash Available</span>
                      <span className="font-semibold text-sm">
                        {" "}
                        {getCurrencyNumberWithSymbol(
                          application?.availableLimit
                        ) || "-"}
                      </span>
                    </span>
                    <div className="grow-[2] basis-0 flex px-2 flex-col">
                      <span className="text-sm">Overdue Amount</span>
                      <span className="font-semibold text-sm color-red">
                        {getCurrencyNumberWithSymbol(application?.overDueAmt) ||
                          0}
                      </span>
                    </div>
                  </div>

                  <div className="flex bg-light-primary text-sm font-medium py-2">
                    <div
                      className="text-center px-2"
                      style={{ flexBasis: "4rem" }}
                    >
                      #
                    </div>
                    <div className="grow-[1.5] basis-0 px-2">Loan ID</div>
                    <div className="grow-[3] basis-0 px-2 flex">
                      Beneficiary Name
                    </div>
                    <div className="grow-[2] basis-0 px-2">
                      Cash Requested(₹)
                    </div>
                    <div className="grow-[2] basis-0 px-2">
                      Cash Disbursed(₹)
                    </div>
                    <div className="grow-[2] basis-0 px-2">Due Date</div>
                    <span className="grow-[2] basis-0 px-2">Status</span>
                  </div>

                  {application?.parties.length > 0 ? (
                    <>
                      {application?.parties
                        .filter(
                          (option) =>
                            (option.partyName &&
                              option.partyName
                                .toLowerCase()
                                .includes(searchText)) ||
                            (option.disbId &&
                              option.disbId
                                .toString()
                                .toLowerCase()
                                .includes(searchText))
                        )
                        .map((item, indx) => (
                          <div>
                            <div
                              className="flex border-b text-sm py-2 cursor-pointer items-center"
                              onClick={() => {
                                navigate(`/application-view/${item.disbId}`);
                                handleClearComments();
                              }}
                            >
                              <span
                                className="text-center px-2"
                                style={{ flexBasis: "4rem" }}
                              >
                                {indx + 1}
                              </span>
                              <span className="grow-[1.5] basis-0 flex flex-col px-2 text-sm">
                                <span className="text-sm color-blue">
                                  {item?.disbId}
                                </span>
                                <span className="font-semibold text-sm">
                                  {moment(item?.disbDate).format("DD-MMM-YYYY")}
                                </span>
                              </span>
                              <div className="grow-[3] basis-0 flex flex-col px-2">
                                <PartnerInfo
                                  profilePic={item.profilePic}
                                  name={item?.partyName}
                                  shortName={item?.shortCode}
                                  partnerType={"Farmer ID "}
                                  partnerId={item?.partyId}
                                  partnerMobile={
                                    item?.mobile ? item?.mobile : ""
                                  }
                                  partyName={item?.addressLine}
                                  fromLoansParty={true}
                                />
                              </div>
                              <div className="grow-[2] basis-0 px-2">
                                <span className="font-semibold text-sm">
                                  {getCurrencyNumberWithOutSymbol(
                                    item.tradeAdv
                                  ) || "-"}
                                </span>
                              </div>
                              <span className="grow-[2] basis-0 px-2">
                                {" "}
                                <span className="font-semibold text-sm">
                                  {getCurrencyNumberWithOutSymbol(
                                    item.disbAmt
                                  ) || "-"}
                                </span>
                              </span>
                              <div className="grow-[2] basis-0 px-2">
                                <span className="font-semibold text-sm">
                                  {item.dueDate ? moment(item.dueDate).format("DD-MMM-YYYY") :
                                    "-"}
                                </span>
                              </div>
                              <div className="grow-[2] basis-0 flex px-2">
                                <span className="grow-[2]">
                                  <UserAccountStatusString
                                    statusIcon={getAccountStatusDisplayIcon({
                                      status: item.disbStatus,
                                    })}
                                  />
                                </span>
                                <FaChevronRight />
                              </div>
                            </div>
                          </div>
                        ))}
                    </>
                  ) : (
                    "No data"
                  )}
                </div>
              );
            })}
          </>
        ) : (
          <div className="flex items-center justify-center py-8">
            <img src={NodataAvailable} alt="image" />
          </div>
        )}
      </div>
    </div>
  );
};

export default LoansApplications;
