
import { useParams } from 'react-router-dom';
import closedLoan from '../../../../assets/images/closeLoan.svg'
import { Button } from '../../../../components/ui';
import useApplicationViewById from '../application-view/useApplicationViewById';
const CloseLoanModal: React.FC<{
    onClose: () => void;
}> = ({ onClose }) => {

    const params = useParams();
    const {
        closeLoan
    } = useApplicationViewById({
        applicationId: parseInt(params.applicationId || "0"),
    });
    return (
        <>
            <div className="flex items-center justify-center">
                <div className="flex flex-col items-center px-4 py-4">
                    <img src={closedLoan} alt="loan closed" className="mb-4" />
                    <span className="text-lg font-bold text-center">
                        You are about to close the loan. Do you want to proceed?
                    </span>
                    <div className="flex mt-4">
                        <Button
                            text="NO"
                            className="w-[100px] mx-[10px]"
                            variant="primaryLight"
                            onClick={() => onClose()}
                        />
                        <Button
                            text="YES"
                            className="w-[100px] mx-[10px]"
                            onClick={() => {
                                onClose()
                                closeLoan()
                            }}
                        />
                    </div>
                </div>
            </div>

        </>
    )
}
export default CloseLoanModal;