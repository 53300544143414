import { CSSProperties, FC, ReactNode } from "react";

const CardTitleBg: FC<{
  className?: string;
  titile?: string | ReactNode;
  titileClassName?: string;
  children: ReactNode;
  style?: CSSProperties;
}> = ({
  className = "",
  titile,
  titileClassName = "",
  children,
  style = {},
}) => {
  return (
    <>
      <div
        className={`border rounded overflow-hidden bg-white br-10 ${className}`}
        style={style}
      >
        {titile && (
          <div
            className={`py-2 px-2 bg-light-primary border-b  font-semibold  ${titileClassName}`}
          >
            {titile}
          </div>
        )}
        {children}
      </div>
    </>
  );
};
export default CardTitleBg;
