import useArrivalsView from "./useArrivalsView";
import { ChangeEvent, FC } from "react";
import { useEffect } from "react";
import { Button, FileUploader, Input } from "../../components/ui";
import { getCurrencyNumberWithSymbol } from "../../helpers/get-currency-number";
import { useParams } from "react-router";
const InvoiceEdit: FC<{
  editStatus: string;
  onPattiClosed: any;
  tyoeFrom: any;
}> = ({ editStatus, onPattiClosed, tyoeFrom }) => {
  const params = useParams();

  const { alltradeDetail, setArrivalIInfo, arrivalIInfo, confrirmArrival } =
    useArrivalsView({
      traderId: parseInt(params.traderId || "0"),
      tradeId: parseInt(params.tradeId || "0"),
      mode: editStatus == "edit" ? true : false,
      type: "INVOICE",
      typeFrom: tyoeFrom,
      closeModal: onPattiClosed,
    });
  useEffect(() => {
    if (arrivalIInfo != null) {
      setArrivalIInfo({
        ...arrivalIInfo,

        destPfPer: parseFloat(
          calculatePercentagefromTotal(
            arrivalIInfo?.destPf !== "" ? arrivalIInfo?.destPf : 0,
            Number(arrivalIInfo?.invAmt)
          ).toFixed(2)
        ),
        srcPfPer: parseFloat(
          calculatePercentagefromTotal(
            arrivalIInfo?.srcPf !== "" ? arrivalIInfo?.srcPf : 0,
            Number(arrivalIInfo?.invAmt)
          ).toFixed(2)
        ),
      });
    }
  }, [editStatus]);

  const onChangePfTotal = (e: ChangeEvent<HTMLInputElement>, type: any) => {
    if (type == "dest") {
      setArrivalIInfo({
        ...arrivalIInfo,
        destPfPer: parseFloat(
          calculatePercentagefromTotal(
            e.target.value !== "" ? Number(e.target.value) : 0,
            Number(alltradeDetail?.invAmt)
          ).toFixed(2)
        ),
        destPf: e.target.value !== "" ? parseFloat(e.target.value) : "",
      });
    } else if (type == "src") {
      setArrivalIInfo({
        ...arrivalIInfo,
        srcPfPer: parseFloat(
          calculatePercentagefromTotal(
            e.target.value !== "" ? Number(e.target.value) : 0,
            Number(alltradeDetail?.invAmt)
          ).toFixed(2)
        ),
        srcPf: e.target.value !== "" ? parseFloat(e.target.value) : "",
      });
    }
  };
  const onChangePf = (e: ChangeEvent<HTMLInputElement>, type: any) => {
    if (type == "dest") {
      setArrivalIInfo({
        ...arrivalIInfo,
        destPf: parseFloat(
          calculateTotalFromPercentage(
            e.target.value !== "" ? parseFloat(e.target.value) : 0,
            Number(alltradeDetail?.invAmt)
          ).toFixed(2)
        ),
        destPfPer: e.target.value !== "" ? parseFloat(e.target.value) : "",
      });
    } else if (type == "src") {
      console.log(
        calculateTotalFromPercentage(
          e.target.value !== "" ? parseFloat(e.target.value) : 0,
          Number(alltradeDetail?.invAmt)
        ),
        "source"
      );
      setArrivalIInfo({
        ...arrivalIInfo,
        srcPf: parseFloat(
          calculateTotalFromPercentage(
            e.target.value !== "" ? parseFloat(e.target.value) : 0,
            Number(alltradeDetail?.invAmt)
          ).toFixed(2)
        ),
        srcPfPer: e.target.value !== "" ? parseFloat(e.target.value) : "",
      });
    }
  };
  const calculateTotalFromPercentage = (
    _percentage: number,
    total: number
  ): number => (_percentage / 100) * total;
  const calculatePercentagefromTotal = (part: number, total: number) => {
    return (part / total) * 100;
  };
  return (
    <>
      <div
        className="pb-3 overflow-y-auto"
        style={{ height: "calc(100vh - 22rem)" }}
      >
        <div className="bg-white px-3 py-4 mb-3">
          <div className="flex gap-4 pt-3">
            <div className="grow-[3]">
              <span className="text-sm">
                Invoice Amount<span className="color-red">*</span>
              </span>
              <div className="mt-2 relative">
                <Input
                  label=""
                  type="number"
                  required={true}
                  value={alltradeDetail?.invAmt}
                  disabled={true}
                />
              </div>
              {alltradeDetail?.invAmt! > 0 &&
                Number(alltradeDetail?.netWeight) > 0 && (
                  <span className="text-sm color-sub-text">
                    Average Rate:
                    {alltradeDetail?.invAmt
                      ? getCurrencyNumberWithSymbol(
                          Number(arrivalIInfo?.invAmt) /
                            Number(alltradeDetail?.netWeight)
                        )
                      : 0}
                  </span>
                )}
            </div>
            {/* <div className="grow-[3]">
              <span className="text-sm">
                Freight Balance Paid<span className="color-red">*</span>
              </span>
              <div className="mt-2 relative">
                <Input
                  label=""
                  type="number"
                  required={true}
                  value={alltradeDetail?.freightPaid}
                  disabled={true}
                />
              </div>
            </div> */}
          </div>

          <div className="flex gap-4 pt-3">
            <div className="grow-[3]">
              <span className="text-sm">Discount</span>
              <div className="mt-2 relative">
                {tyoeFrom == "DEST" ? (
                  <Input
                    label=""
                    type="number"
                    required={false}
                    value={alltradeDetail?.destDisc}
                    onChange={(e) => {
                      setArrivalIInfo({
                        ...arrivalIInfo!,
                        destDisc: parseFloat(e.target.value) || "",
                      });
                    }}
                    onFocus={(e) => {
                      e.target.value === "0" &&
                        setArrivalIInfo({
                          ...arrivalIInfo,
                          destDisc: "",
                        });
                    }}
                  />
                ) : (
                  <Input
                    label=""
                    type="number"
                    required={false}
                    value={alltradeDetail?.srcDisc}
                    onChange={(e) => {
                      setArrivalIInfo({
                        ...arrivalIInfo!,
                        srcDisc: parseFloat(e.target.value) || "",
                      });
                    }}
                    onFocus={(e) => {
                      e.target.value === "0" &&
                        setArrivalIInfo({
                          ...arrivalIInfo,
                          srcDisc: "",
                        });
                    }}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="block md:flex gap-4 pt-3">
            <div className="grow-[3]">
              <span className="text-sm">
                Advance {tyoeFrom == "DEST" ? "Paid" : "Received"}
              </span>
              <div className="mt-2 relative">
                <Input
                  label=""
                  type="number"
                  required={true}
                  value={
                    tyoeFrom == "DEST"
                      ? alltradeDetail?.advancePaid
                      : alltradeDetail?.advanceRcvd
                  }
                  disabled={true}
                />
              </div>
            </div>
            <div className="grow-[3] pt-3 md:pt-0">
              <span className="text-sm">
                {" "}
                Total {tyoeFrom == "DEST" ? "Payable" : "Receivable"}
              </span>
              <div className="mt-2 relative">
                <Input
                  label=""
                  type="number"
                  required={true}
                  value={
                    tyoeFrom == "DEST"
                      ? alltradeDetail?.totalPayable
                      : alltradeDetail?.totalReceivable
                  }
                  disabled={true}
                />
              </div>
            </div>
          </div>
          <div className="py-3">
            <span className="color-primary">Platform Fees</span>
          </div>
          <div className="block md:flex gap-4 pt-3">
            <div className="grow-[3]">
              <span className="text-sm">Platform Fees (%)</span>
              <div className="mt-2 relative">
                {tyoeFrom == "DEST" ? (
                  <Input
                    label=""
                    type="number"
                    required={true}
                    value={alltradeDetail?.destPfPer}
                    onChange={(e) => {
                      onChangePf(e, "dest");
                    }}
                    onFocus={(e) => {
                      e.target.value === "0" &&
                        setArrivalIInfo({
                          ...arrivalIInfo,
                          destPfPer: "",
                        });
                    }}
                  />
                ) : (
                  <Input
                    label=""
                    type="number"
                    required={true}
                    value={alltradeDetail?.srcPfPer}
                    onChange={(e) => {
                      onChangePf(e, "src");
                    }}
                    onFocus={(e) => {
                      e.target.value === "0" &&
                        setArrivalIInfo({
                          ...arrivalIInfo,
                          srcPfPer: "",
                        });
                    }}
                  />
                )}
              </div>
            </div>
            <div className="grow-[3] pt-3 md:pt-0">
              <span className="text-sm"> Platform Fees Amount (₹)</span>
              <div className="mt-2 relative">
                {tyoeFrom == "DEST" ? (
                  <Input
                    label=""
                    type="number"
                    required={true}
                    value={alltradeDetail?.destPf}
                    onChange={(e) => {
                      onChangePfTotal(e, "dest");
                    }}
                    onFocus={(e) => {
                      e.target.value === "0" &&
                        setArrivalIInfo({
                          ...arrivalIInfo,
                          destPf: "",
                        });
                    }}
                  />
                ) : (
                  <Input
                    label=""
                    type="number"
                    required={true}
                    value={alltradeDetail?.srcPf}
                    onChange={(e) => {
                      onChangePfTotal(e, "src");
                    }}
                    onFocus={(e) => {
                      e.target.value === "0" &&
                        setArrivalIInfo({
                          ...arrivalIInfo,
                          srcPf: "",
                        });
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="block md:flex gap-4 pt-3">
            <div className="grow-[3]">
              <span className="text-sm">Platform Fees Discount (₹)</span>
              <div className="mt-2 relative">
                {tyoeFrom == "DEST" ? (
                  <Input
                    label=""
                    type="number"
                    required={true}
                    value={alltradeDetail?.destDis}
                    onChange={(e) => {
                      setArrivalIInfo({
                        ...arrivalIInfo,
                        destDis: parseFloat(e.target.value) || "",
                      });
                    }}
                    onFocus={(e) => {
                      e.target.value === "0" &&
                        setArrivalIInfo({
                          ...arrivalIInfo,
                          destDis: "",
                        });
                    }}
                  />
                ) : (
                  <Input
                    label=""
                    type="number"
                    required={true}
                    value={alltradeDetail?.srcDis}
                    onChange={(e) => {
                      setArrivalIInfo({
                        ...arrivalIInfo,
                        srcDis: parseFloat(e.target.value) || "",
                      });
                    }}
                    onFocus={(e) => {
                      e.target.value === "0" &&
                        setArrivalIInfo({
                          ...arrivalIInfo,
                          srcDis: "",
                        });
                    }}
                  />
                )}
              </div>
            </div>
            <div className="grow-[3] pt-3 md:pt-0">
              <span className="text-sm">
                {" "}
                {tyoeFrom == "DEST" ? "Final Payable" : "Final Receivable"}
              </span>
              <div className="mt-2 relative">
                <Input
                  label=""
                  type="number"
                  required={true}
                  value={
                    tyoeFrom == "DEST"
                      ? alltradeDetail?.finalPayable
                      : alltradeDetail?.finalReceivable
                  }
                  disabled={true}
                  inputStyle={{
                    color: tyoeFrom == "DEST" ? "color-red" : "color-primary",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center bottom-0 right-0 w-full bg-white px-4 py-4 border-top gap-2">
        <Button
          variant="primaryLight"
          className="w-full"
          text="CANCEL"
          onClick={() => {
            onPattiClosed(false);
          }}
        />
        <Button
          variant="contained"
          className="w-full"
          onClick={() => {
            confrirmArrival();
          }}
        >
          SAVE
        </Button>
      </div>
    </>
  );
};
export default InvoiceEdit;
